import React from 'react'
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction"
import ListItemText from "@mui/material/ListItemText"
import Avatar from "@mui/material/Avatar"
import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import DeleteIcon from "@mui/icons-material/Delete"
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add';

export default function UploadLogo({ setPhoto, title, darkState }) {
    const [files, setFiles] = React.useState([]);
    const [fileUrl, setFileUrl] = React.useState(null);


    const handleFileChanged = e => {
      const inputLogo = document.querySelector("#inputLogo");
      const objectFiles = inputLogo.files;
      const files = Object.keys(objectFiles).map(index => {
          return objectFiles[index]
      });
      setFiles(files)
      setPhoto(files)
      const reader = new FileReader()
      reader.onload = e => {
          setFileUrl(e.target.result)
      }
      reader.readAsDataURL(e.target.files[0])
    };
    
    const handleDeleteFile = lastModified => _ => {
      const updatedFiles = files.filter(f => f.lastModified !== lastModified)
      setFiles(updatedFiles)
      setPhoto(updatedFiles)
    };

    return (
        <Grid container spacing={2} style={{marginLeft: '-7px', marginBottom: 30}}>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    onClick={() => document.querySelector("#inputLogo").click()}
                    disabled={files.length > 0}
                    size="small"
                    color={darkState ? 'secondary' : 'primary'}
                >
                    <AddIcon/>
                    {title || 'Changer d\'avatar'}
                </Button>
                <input
                    type="file"
                    id="inputLogo"
                    style={{ visibility: "hidden" }}
                    multiple
                    onChange={handleFileChanged}
                    accept="image/*"
                />
            </Grid>
            <Grid item xs={3} md={5}>
                <div>
                    {files.length > 0 && <List>
                        {files.map(f => (
                            <ListItem key={f.lastModified}>
                                <ListItemAvatar>
                                    <Avatar alt="Avatar" src={fileUrl}/>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={f.name}
                                    secondary={f.type}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        color='inherit'
                                        edge="end"
                                        aria-label="delete"
                                        onClick={handleDeleteFile(f.lastModified)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>}
                </div>
            </Grid>
        </Grid>
    )
}