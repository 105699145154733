import React, { useEffect, useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { TransitionLeft } from '../../../helpers/utils'
import { apiFetch } from '../../../service/apiFetch'
import {
    Zoom,
    Grid,
    CircularProgress,
} from '@mui/material';
import SpecialistHeader from './InformationItem/Header'
import AboutSpecialist from './InformationItem/AboutSpecialist'
import Reference from './InformationItem/Reference'
import SpecialistAddress from './InformationItem/SpecialistAddress'
import Interventions from './InformationItem/Interventions'
import Specialities from './InformationItem/Specialities'
import Experiences from './InformationItem/Experiences'
import Expertise from './InformationItem/Expertise'
import Activities from './InformationItem/Activities'
import MapContainer from './InformationItem/AdressesMap'
import Vacation from './InformationItem/Vacation'
import Comment from './InformationItem/Comment'
import Certifications from './InformationItem/Certifications'

export default function Information({
    setOpenSnackbar,
    setText,
    setTransition,
    setSeverity,
    darkState,
    zoom,
    phoneResponsive,
    setSpecialist,
    specialist,
}) {
    const { id } = useParams()
    let navigate = useNavigate()
    const mounted = useRef(false);

    useEffect(() => {
        (async () => {
            mounted.current = true
            const specialistFetch = await apiFetch(`/specialists/${id}`)
            if (specialistFetch && specialistFetch.uuid && mounted.current) {
                setSpecialist(specialistFetch)
            } else {
                setText('Error lors de la récupération des données')
                setSeverity('error')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                navigate('/specialists')
            }
            return () => mounted.current = false
        })()
    }, [setSpecialist, setTransition, setText, setSeverity, setOpenSnackbar, id, navigate])

    return (
        <>
            {specialist ? <Zoom in={zoom}>
                <div style={phoneResponsive ? {maxWidth: 215} : {}}>
                    <Grid container spacing={3} style={{marginTop: -38}}>
                        <Grid item xs={12} sm={12}>
                            <SpecialistHeader
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                                phoneResponsive={phoneResponsive}
                                id={id}
                                specialist={specialist}
                                darkState={darkState}
                                zoom={zoom}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <AboutSpecialist specialist={specialist} darkState={darkState} zoom={zoom} />
                            <Activities specialist={specialist} darkState={darkState} zoom={zoom} />
                            <SpecialistAddress specialist={specialist} darkState={darkState} zoom={zoom} />
                            <Comment specialist={specialist} darkState={darkState} zoom={zoom} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Specialities specialist={specialist} darkState={darkState} zoom={zoom} />
                            <Expertise specialist={specialist} darkState={darkState} zoom={zoom} />
                            <Certifications specialist={specialist} darkState={darkState} zoom={zoom} />
                            <Experiences specialist={specialist} darkState={darkState} zoom={zoom} />
                            <Vacation
                                setSpecialist={setSpecialist}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                                specialist={specialist}
                                darkState={darkState}
                                zoom={zoom}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Reference specialist={specialist} darkState={darkState} zoom={zoom} />
                            <Interventions specialist={specialist} darkState={darkState} zoom={zoom} />
                            {specialist.address && <MapContainer
                                phoneResponsive={phoneResponsive}
                                specialist={specialist}
                                darkState={darkState}
                                zoom={zoom}
                            />}
                        </Grid>
                    </Grid>
                </div>
            </Zoom> : 
            <div style={{textAlign: 'center'}}>
                <CircularProgress color={darkState ? 'secondary' : 'primary'} />
            </div>}
        </>
    )
}
