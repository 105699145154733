import React, { useEffect, useState } from 'react'
import { Grid, Paper, TextField, Zoom, Typography, Divider, Button, ButtonGroup, LinearProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { TransitionLeft } from '../../helpers/utils';
import { apiFetch } from '../../service/apiFetch';
import { handleFormError } from '../../service/security/formError';
import { useCompanies } from '../../hooks/company';
import UploadLogo from '../../components/UploadLogo'

export default function ManageCompany({ darkState, zoom, setOpenSnackbar, setText, setTransition, setSeverity }) {
    const [company, setCompany] = useState(false)
    const [error, setError] = useState({});
    const [photo, setPhoto] = useState(false);
    let navigate = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(true)

    const {
        createCompany,
        updateCompany
    } = useCompanies()
    
    useEffect(() => {
        (async () => {
            if (id) {
                const companyFetch = await apiFetch(`/companies/${id}`)
                if (companyFetch && companyFetch.uuid) {
                    setCompany(companyFetch)
                } else {
                    setSeverity('error')
                    setText('Error lors de la récupération des données')
                    setTransition(() => TransitionLeft)
                    setOpenSnackbar(true)
                    navigate('/companies')
                }
            }
        })()
    }, [id, navigate, setOpenSnackbar, setSeverity, setTransition, setText, setCompany])

    const handleBack = () => {
        navigate('/companies')
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        if (!id) {
            await handleCreate(e)
        } else {
            handleEdit(e)
        }
        setLoading(false)
    }

    const handleCreate = async (e) => {
        setError({})
        let data = new FormData(e.target)
        const name = data.get('name')
        const nbEmployee = parseInt(data.get('nbEmployee'))
        
        let createPhoto = null
        if (photo) {
            let dataPhoto = new FormData() 
            dataPhoto.append('file', photo && photo[0])
            createPhoto = await apiFetch('/logo_objects', {
                method: 'POST',
                body: dataPhoto,
            }, true)
        }
        data = { name: name, nbEmployee: nbEmployee, logo: createPhoto && createPhoto['@id'] }

        const createdCompany = await createCompany(data, true)

        if (createdCompany && createdCompany.uuid) {
            setText('L\'entreprise a été crée')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setTimeout(() => {
                navigate('/companies')
            }, 2000)
        } else if (createdCompany.violations) {
            const errorField = handleFormError(createdCompany, ['name', 'nbEmployee'])
            setError(errorField)
            setText('Erreur lors de la création')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        } else {
            setText((createdCompany && createdCompany['hydra:description']) || 'Erreur lors de la création')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
    }

    const handleEdit = async (e) => {
        setError({})
        let data = new FormData(e.target)
        const name = data.get('name')
        const nbEmployee = parseInt(data.get('nbEmployee'))

        let createPhoto = null
        if (photo) {
            let dataPhoto = new FormData() 
            dataPhoto.append('file', photo && photo[0])
            createPhoto = await apiFetch('/logo_objects', {
                method: 'POST',
                body: dataPhoto,
            }, true)
        }
        data = { name: name, nbEmployee: nbEmployee, logo: createPhoto && createPhoto['@id'] }

        const updatedCompany = await updateCompany(id, data)

        if (updatedCompany && updatedCompany.uuid) {
            setText('L\'entreprise a été modifié')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setTimeout(() => {
                navigate('/companies')
            }, 2000)
        } else if (updatedCompany.violations) {
            const errorField = handleFormError(updatedCompany, ['name', 'nbEmployee'])
            setError(errorField)
            setText('Erreur lors de la modification')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        } else {
            setText((updatedCompany && updatedCompany['hydra:description']) || 'Erreur lors de la modification')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
    }

    const handleChange = () => {
        setLoading(false)
    }

    return (
        <Zoom in={zoom}>
            <Paper
                sx={{
                    pt: 1
                }}
            >
                <Typography
                    variant='h5'
                    color={darkState ? 'secondary' : 'primary'}
                    sx={{
                        ml: 3,
                        mb: 1
                    }}
                >
                    {id ? 'Modifier' : 'Ajouter'} une entreprise
                </Typography>
                <Divider />
                {(id && company) || !id ? <form noValidate autoComplete="off" onSubmit={handleSubmit} onChange={handleChange}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                color={darkState ? 'secondary' : 'primary'}
                                error={!!error.name}
                                helperText={error.name}
                                id="name"
                                name='name'
                                label="Nom de l'entreprise"
                                defaultValue={company.name}
                                sx={{
                                    width: 300,
                                    mt: 2,
                                    mb: 3,
                                    ml: 3,
                                }}
                            />
                            <div style={{marginLeft: 16}}>
                                <UploadLogo setPhoto={setPhoto} title={'Ajouter le logo de l\'entreprise'} id='logo' darkState={darkState} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                color={darkState ? 'secondary' : 'primary'}
                                error={!!error.nbEmployee}
                                helperText={error.nbEmployee}
                                id="nbEmployee"
                                name='nbEmployee'
                                label="Nombre d'employés"
                                defaultValue={company.nbEmployee}
                                sx={{
                                    width: 300,
                                    mt: 2,
                                    mb: 3,
                                    ml: 3,
                                }}
                            />
                        </Grid>
                    </Grid><br />
                    <ButtonGroup
                        color={darkState ? 'secondary' : "primary"}
                        variant="contained"
                        aria-label="contained primary button group"
                        sx={{m: 3,}}
                    >
                        <Button
                            disabled={loading}
                            type="submit"
                        >
                            Enregister
                        </Button>
                        <Button onClick={handleBack}>
                            Retour
                        </Button>
                    </ButtonGroup>
                </form> : 
                <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
            </Paper>
        </Zoom>
    )
}