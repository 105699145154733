import React from 'react'
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import PeopleIcon from '@mui/icons-material/People';
import ListIcon from '@mui/icons-material/List';
import logo from '../../img/logo-PCG.png'
import { 
    List,
    Typography,
    Divider,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Collapse 
} from '@mui/material/';
import { useNavigate } from 'react-router-dom';
import {hasRoles} from '../../service/security/hasRoles';
import BusinessIcon from '@mui/icons-material/Business';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import LanguageIcon from '@mui/icons-material/Language';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import WorkIcon from '@mui/icons-material/Work';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import BallotIcon from '@mui/icons-material/Ballot';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import RoomIcon from '@mui/icons-material/Room';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';

let temporaryRoute = null
export default function Navigation({ darkState, setZoom, phoneResponsive, setOpen }) {
    let navigate = useNavigate()
    const [expanded, setExpanded] = React.useState(false);

    const adminPrimary = [
        {path: 'dashboard', item: 'Tableau de bord'},
        {path: 'specialists', item: 'Liste des psychologues'},
        {path: 'interventions',item: 'Demande d\'intervention'},
        {path: 'map',item: 'Carte des psychologues'},
        {path: 'restore',item: 'Réactiver des psychologues'},
    ]

    const adminSecondary = [
        {path: 'companies', item: 'Les entreprises'},
        {path: 'specialities', item: 'Les Spécialités cliniques'},
        {path: 'experiences',item: 'Les expériences'},
        {path: 'expertises',item: 'Les expertises'},
        {path: 'languages',item: 'Les langues'},
        {path: 'administratif-status',item: 'Les statuts'},
        {path: 'certifications',item: 'Les certifications'},
    ]

    const userPrimary = [
        {path: 'specialists', item: 'Liste des psychologues'},
        {path: 'map',item: 'Carte des psychologues'},
    ]

    const specialistManagerPrimary = [
        {path: 'dashboard', item: 'Tableau de bord'},
        {path: 'specialists', item: 'Liste des psychologues'},
        {path: 'map',item: 'Carte des psychologues'},
    ]

    const interventionManagerPrimary = [
        {path: 'dashboard', item: 'Tableau de bord'},
        {path: 'specialists', item: 'Liste des psychologues'},
        {path: 'interventions',item: 'Demande d\'intervention'},
        {path: 'map',item: 'Carte des psychologues'},
    ]

    const handleNavigation = (e) => {
        setZoom(false)
        temporaryRoute = e.currentTarget.title
        if (phoneResponsive) {
            setExpanded(false)
            setOpen(false)
        }

        setTimeout(() => {
            setZoom(true)
            navigate(`/${temporaryRoute}`)
            temporaryRoute = null
        }, 50)
    }

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    overflowX: 'none',
                    padding: 5,
                }}
            >
                <div style={{display: '-webkit-box'}}>
                    <img src={logo} alt='logo' style={{width: phoneResponsive ? 50 : 75}} />
                    <Typography variant='h6' style={{margin: 11, marginRight: 50}} color={darkState ? 'secondary' : 'primary'}>
                        Codex
                    </Typography>
                </div>
            </div>
            <Divider />
            <List style={{overflowX: 'hidden'}}>
                <Typography variant='overline' style={{marginLeft: 18}}>Partie Psychologue</Typography>
                {findRightMenu(adminPrimary, userPrimary, interventionManagerPrimary, specialistManagerPrimary).map((text, index) => (
                    <ListItem id='tab' key={text.path} title={text.path} button onClick={handleNavigation}>
                        <ListItemIcon>
                            {[
                                <DashboardIcon color='inherit'/>,
                                <AssignmentIndIcon color='inherit'/>,
                                <ListIcon color='inherit'/>,
                                <RoomIcon color='inherit'/>,
                                <RestoreFromTrashIcon color='inherit'/>
                            ][index]}
                        </ListItemIcon>
                        <ListItemText primary={text.item} />
                    </ListItem>
                ))}
                {hasRoles() === 'admin' && <>
                <Divider />
                <Typography variant='overline' style={{marginLeft: 18}}>Partie Utilisateur</Typography>
                {[{path: 'users', item: 'Liste des utilisateurs'}].map((text, index) => (
                    <ListItem id='tab' key={text.path} title={text.path} button onClick={handleNavigation}>
                        <ListItemIcon><PeopleIcon color='inherit' /></ListItemIcon>
                        <ListItemText primary={text.item} />
                    </ListItem>
                ))}
                <div style={{display: 'table'}}>
                    <AccountTreeIcon
                        onClick={handleExpandClick}
                        style={
                            {
                                verticalAlign: 'middle',
                                cursor: 'pointer',
                                marginLeft: 16,
                                marginRight: 32,
                                color: darkState ? 'white' : '#707070'
                            }
                        }
                    />
                    <Typography style={{display: 'table-cell', verticalAlign: 'middle'}}>Gérer les options</Typography>
                    <IconButton
                        onClick={handleExpandClick}
                        color='inherit'
                        aria-expanded={expanded}
                        style={{marginLeft: 10}}
                        aria-label="show more"
                    >
                        {expanded ? <ExpandMoreIcon color='inherit' /> : <ExpandLessIcon color='inherit' />}
                    </IconButton>
                </div>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {findRightMenu(adminSecondary).map((text, index) => (
                        <ListItem id='tab' key={text.path} title={text.path} button onClick={handleNavigation} style={{marginLeft: 25, maxWidth: 250}}>
                            <ListItemIcon>
                                {[
                                    <BusinessIcon color='inherit' />, 
                                    <LocalActivityIcon color='inherit' />, 
                                    <WorkIcon color='inherit' />, 
                                    <SupervisedUserCircleIcon color='inherit' />, 
                                    <LanguageIcon color='inherit' />, 
                                    <BallotIcon color='inherit' />, 
                                    <HowToRegIcon color='inherit' />
                                ][index]}
                            </ListItemIcon>
                            <ListItemText primary={text.item} />
                        </ListItem>
                    ))}
                </Collapse>
                </>}
            </List>
        </>
    )
}

function findRightMenu(adminTab = [], userTab = [], interventionManagerTab = [], specialistManagerTab = []) {
    if (hasRoles() === 'admin' || hasRoles() === 'super_admin') {
        return adminTab
    } else if (hasRoles() === 'intervention_manager') {
        return interventionManagerTab
    } else if (hasRoles() === 'specialist_manager') {
        return specialistManagerTab
    }
    return userTab
}