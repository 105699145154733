import React from 'react';
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    Checkbox,
    TextField,
    AppBar,
    Tabs,
    Tab,
    Box,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Autocomplete from '@mui/material/Autocomplete';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ExportCvPopup({
    setAdministrativeType,
    loading,
    open,
    handleClose,
    darkState,
    handleAccept,
    specialists,
    specialist,
    setSpecialist,
    handleExportAllCv,
    specialities,
    setCvSpeciality,
    administrativeTypes 
}) {
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="export-popup-title"
            aria-describedby="export-popup-description"
        >
            <DialogTitle id="export-popup-title">{"Exporter des Cvs"}</DialogTitle>
            <DialogContent>
                <div>
                    <AppBar position="static" color={darkState ? 'secondary' : "primary"}>
                        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                            <Tab label="Filtre par spécialistes" {...a11yProps(0)} />
                            <Tab label="Filtre par spécialités" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <Autocomplete
                            multiple
                            id="multiple-checkbox"
                            value={specialist}
                            options={specialists}
                            disableCloseOnSelect
                            isOptionEqualToValue={(option, value) => {
                                return option.firstName === value.firstName
                            }}
                            getOptionLabel={(option) => option.firstName}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                        color={darkState ? 'secondary' : 'primary'}
                                    />
                                    {option.firstName}
                                </li>
                            )}
                            onChange={(event, newValue) => {
                                setSpecialist(newValue);
                            }}
                            style={{ width: 500 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    color={darkState ? 'secondary' : 'primary'}
                                    label="Liste des spécialistes"
                                    placeholder="Spécialiste(s)"
                                />
                            )}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Autocomplete
                        multiple
                        id="multiple-checkbox"
                        options={specialities}
                        disableCloseOnSelect
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => {
                            return option.name === value.name
                        }}
                        renderOption={(props, option, { selected }) => (
                            <li {...props}>
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                    color={darkState ? 'secondary' : 'primary'}
                                />
                                {option.name}
                            </li>
                        )}
                        onChange={(event, newValue) => {
                            setCvSpeciality(newValue);
                        }}
                        style={{ width: 500 }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                color={darkState ? 'secondary' : 'primary'}
                                label="Liste des spécialités"
                                placeholder="Spécialité(s)"
                            />
                        )}
                        />
                        <Autocomplete
                            multiple
                            id="multiple-checkbox"
                            options={administrativeTypes}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) => {
                                return option.name === value.name
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                        color={darkState ? 'secondary' : 'primary'}
                                    />
                                    {option.name}
                                </li>
                            )}
                            onChange={(event, newValue) => {
                                setAdministrativeType(newValue);
                            }}
                            style={{ width: 500, marginTop: 20 }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    color={darkState ? 'secondary' : 'primary'}
                                    label="Liste des types"
                                    placeholder="Type(s)"
                                />
                            )}
                        />
                    </TabPanel>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleExportAllCv} disabled={loading} color={darkState ? 'secondary' : 'primary'}>
                    Télécharger tous les Cvs
                </Button>
                <Button onClick={handleClose} disabled={loading} color={darkState ? 'secondary' : 'primary'}>
                    Annuler
                </Button>
                <Button onClick={handleAccept} disabled={loading} color={darkState ? 'secondary' : 'primary'}>
                    Télécharger
                </Button>
            </DialogActions>
        </Dialog>
  );
}