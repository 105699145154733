import React from 'react';
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    TextField,
    FormControl,
    Select,
    InputLabel,
    MenuItem
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShareCvPopup({ error, open, handleClose, darkState, handleAccept, setEmail, setMessage, selectedCv, setSelectedCv, cvs }) {

  return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="share-popup-title"
            aria-describedby="share-popup-description"
        >
            <DialogTitle id="share-popup-title">{"Envoyer un CV par mail"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="share-popup-description">
                    
                </DialogContentText>
                <TextField color={darkState ? 'secondary' : 'primary'} error={!!error.email} helperText={error.email} style={{marginRight: 50}} id="email" name="email" label="Email" onChange={(e) => setEmail(e.target.value)} />
                <TextField color={darkState ? 'secondary' : 'primary'} error={!!error.message} helperText={error.message} id="message" name="message" label="Message" onChange={(e) => setMessage(e.target.value)} /><br />
                {cvs && !!cvs.length && <FormControl color={darkState ? 'secondary' : 'primary'} style={{marginTop: 20, width: 200}}>
                    <InputLabel id="select-label">CVs du spécialiste</InputLabel>
                    <Select
                        labelId="select-label"
                        id="select"
                        value={selectedCv}
                        onChange={(e) => setSelectedCv(e.target.value)}
                    >
                        <MenuItem value="">
                            <em>Selectionnez un Cv</em>
                        </MenuItem>
                        {cvs.map((cv) => (
                            <MenuItem key={cv.id} value={cv['@id']}>{cv.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={darkState ? 'secondary' : 'primary'}>
                    Annuler
                </Button>
                <Button onClick={handleAccept} color={darkState ? 'secondary' : 'primary'}>
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
  );
}