import React, { useState, useMemo } from 'react'
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { routes } from './service/Routes';
import { Routes, Route, useLocation } from 'react-router-dom'
import Login from './scenes/Login';
import MainMenu from './scenes/MainMenu';
import { NotRequireAuth } from './service/security/NotRequireAuth';

import Responses from './scenes/Responses';

export default function App() {
  const [darkState, setDarkState] = useState(JSON.parse(localStorage.getItem('THEME')));
  const location = useLocation()

  const themeLight = useMemo(
    () => createTheme({
      palette: {
          primary: {
              light: '#4c31ac',
              main: '#12047D',
              dark: '#00004f',
              contrastText: '#fff',
          },
          secondary: {
              light: '#adffff',
              main: '#7fe1dd',
              dark: '#43aea9',
              contrastText: '#000',
          },
          background: {
            default: "#F8F8F8"
          }
      },
      typography: {
        fontFamily: [
          'Quicksand',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(',')
      }
    }),
    [],
  )

  const themeDark = useMemo(
    () => createTheme({
      palette: {
          mode: 'dark',
          primary: {
              light: '#4c31ac',
              main: '#12047D',
              dark: '#00004f',
              contrastText: '#fff',
          },
          secondary: {
              light: '#adffff',
              main: '#7fe1dd',
              dark: '#43aea9',
              contrastText: '#000',
          },
          background: {
            default: "#161d31",
            paper: "#283046",
          },
          text: {
            primary: '#fff',
            secondary: 'rgba(255, 255, 255, 0.7)',
            disabled: 'rgba(255, 255, 255, 0.5)'
          },
      },
      typography: {
        fontFamily: [
          'Quicksand',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(',')
      }
    }),
    [],
  )

  const handleThemeChange = () => {
      setDarkState(!darkState);
      localStorage.setItem('THEME', !darkState)
  };
  
  window.location.pathname !== '/' &&
  window.location.pathname !== '/login' &&
  window.location.pathname !== '/dashboard' &&
  localStorage.setItem('LAST_URL', window.location.pathname)

  return (
      <ThemeProvider theme={darkState && location.pathname !== '/login' ? themeDark : themeLight}>
          <Routes>
              <Route path={'*'} element={
                  <MainMenu handleThemeChange={handleThemeChange} darkState={darkState} />
                }
              />
              <Route path={routes.login} element={
                  <NotRequireAuth>
                    <Login handleThemeChange={handleThemeChange} darkState={darkState} />
                  </NotRequireAuth>
                }
              />
               <Route path={routes.ReponseSMS} element={<NotRequireAuth><Responses /></NotRequireAuth>} />
          </Routes>
      </ThemeProvider>
  );
}
