import { useReducer } from "react"
import { apiFetch } from "../service/apiFetch"

const initialState = []

const FETCHING_SPECIALIST = 'FETCHING_SPECIALIST'
const SET_SPECIALIST = 'SET_SPECIALIST'
const ADD_SPECIALIST = 'ADD_SPECIALIST'
const UPDATE_SPECIALIST = 'UPDATE_SPECIALIST'
const DELETE_SPECIALIST = 'DELETE_SPECIALIST'

export function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCHING_SPECIALIST:
            return { ...state, loading: true }
        case SET_SPECIALIST:
            return {...state, specialists: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_SPECIALIST:
            return {...state, specialists: [action.payload, ...state.specialists]}
        case UPDATE_SPECIALIST:
            return {...state, specialists: action.payload }
        case DELETE_SPECIALIST:
            return {...state, specialists: state.specialists.filter((i) => {
                return i.uuid !== action.payload
            })}
        default:
            return state
    }
}

export function useSpecialists() {
    const [state, dispatch] = useReducer(reducer, {    
        specialists: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        specialists: state.specialists,
        totalItems: state.totalItems,
        view: state.view,
        fetchSpecialists: async function (route, isSubscribed = () => ({current: true})) {
            if ((state.specialists || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_SPECIALIST' })
            const specialists = await apiFetch(route || '/specialists', {method: 'GET'})

            if (isSubscribed().current && specialists && specialists['hydra:member']) {
                dispatch({type: 'SET_SPECIALIST', payload: specialists})
            }
            return specialists && specialists['hydra:member']
        },
        createSpecialist: async function(data, notIterate) {
            const newSpecialist = await apiFetch('/specialists', {
                method: 'POST',
                body: JSON.stringify(data),
            })

            if (newSpecialist.uuid && !notIterate) {
                dispatch({type: 'ADD_SPECIALIST', payload: newSpecialist })
            }
            return newSpecialist
        },
        updateSpecialist: async function(companyId, data, forceResponse) {
            const updatedSpecialist = await apiFetch('/specialists/' + companyId, {
                method: 'PUT',
                body: JSON.stringify(data),
            }, forceResponse)

            if (!updatedSpecialist.violations && !updatedSpecialist['hydra:description']) {
                dispatch({type: 'UPDATE_SPECIALIST', payload: updatedSpecialist })
            }
            return updatedSpecialist
        },
        deleteSpecialist: async function (companyId) {
            const deletedSpecialist = await apiFetch('/specialists/' + companyId, {
                method: 'DELETE'
            }, true)
            if (deletedSpecialist && deletedSpecialist.ok) {
                dispatch({type: 'DELETE_SPECIALIST', payload: companyId})
            }
            return deletedSpecialist
        },
    }
}