import React from 'react'
import {
    Paper,
    Zoom,
    Typography, 
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';


export default function AboutSpecialist({ specialist, zoom, darkState }) {
    return (
        <Zoom in={zoom} style={{ transitionDelay: '200ms' }}>
            <Paper
                sx={{
                    padding: 1.25,
                    boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)',
                    mt: 3.75
                }}
            >
                <Typography variant='h6' color={darkState ? 'secondary' : 'primary'} style={{fontWeight: 'bold', marginBottom: 10}}>
                    <span>À propos</span>
                </Typography>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Ajouter le : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {moment(specialist.createdAt).format('DD MMMM YYYY') || 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Adeli : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {specialist.adeli || 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>RPPS : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {specialist.rpps || 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Siret : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {specialist.siret || 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Date de naissance : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {specialist.birthdate ? moment(specialist.birthdate).format('DD-MM-YYYY') : 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Intervention à domicile : </span>
                    </Typography>
                    {specialist.activity.homeIntervention ?
                        <CheckIcon style={{color: 'green', marginLeft: 10}}/> : <CloseIcon style={{color: 'red', marginLeft: 10}} />}
                </div>
                <div style={{display: 'flex'}}>
                <Typography color={darkState ? 'secondary' : 'primary'} style={{display: 'flex'}}>
                    <span style={{fontWeight: 'bold'}}>Langues : </span>
                </Typography>
                <Typography sx={{ml: 1.25}}>
                    {specialist.languages.length ? specialist.languages.map((language) => {
                        return <React.Fragment key={language.id}>
                            {language.name} {specialist.languages[specialist.languages.length - 1].name !== language.name && `/`}
                        </React.Fragment>
                    })
                :   'Non renseigné'}
                </Typography>
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>N° mobile pro : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {specialist.phoneRegionCode}{specialist.mobilePro || 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>N° mobile perso : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {specialist.mobilePerso || 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>N° fixe : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {specialist.phone || 'Non renseigné'}
                    </Typography>
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Véhiculé : </span>
                    </Typography>
                    {specialist.motorized ? <CheckIcon style={{color: 'green', marginLeft: 10}}/> : <CloseIcon style={{color: 'red', marginLeft: 10}} />}
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>IPRP : </span>
                    </Typography>
                    {specialist.iprp ? <CheckIcon style={{color: 'green', marginLeft: 10}}/> : <CloseIcon style={{color: 'red', marginLeft: 10}} />}
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Coach Wellmonday : </span>
                    </Typography>
                    {specialist.coachWellmonday ? <CheckIcon style={{color: 'green', marginLeft: 10}}/> : <CloseIcon style={{color: 'red', marginLeft: 10}} />}
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>PSSM : </span>
                    </Typography>
                    {specialist.pssm ? <CheckIcon style={{color: 'green', marginLeft: 10}}/> : <CloseIcon style={{color: 'red', marginLeft: 10}} />}
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Visible mobile : </span>
                    </Typography>
                    {specialist.allowSurroundingAreaVisibility ? <CheckIcon style={{color: 'green', marginLeft: 10}}/> : <CloseIcon style={{color: 'red', marginLeft: 10}} />}
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Statut fiscal : </span>
                    </Typography>
                    <Typography sx={{ml: 1.25}}>
                        {(specialist.taxStatus && specialist.taxStatus.name) || 'Non renseigné'}
                    </Typography>
                </div>
            </Paper>
        </Zoom>
    )
}
