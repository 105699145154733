import React, { useState } from 'react';
import { Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Grid,
    TextField,
    LinearProgress,
} from '@mui/material';
import { DatePickerComponent } from '../../../../components/DateTimePickerComponent'
import { apiFetch } from '../../../../service/apiFetch';
import { TransitionLeft } from '../../../../helpers/utils';
import { handleFormError } from '../../../../service/security/formError';
import { useNavigate, useParams } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddVacation({
    loading,
    setLoading,
    setSpecialist,
    handleAddVacationPopup,
    setText,
    setTransition,
    setSeverity,
    setOpenSnackbar,
    open,
    darkState,
}) {
    const [error, setError] = useState(false)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const { id } = useParams()
    let navigate = useNavigate()

    const handleCreate = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError({})

        let data = new FormData(e.target)
        const comment = data.get('comment')
        data = { comment: comment, startDate: startDate, endDate: endDate, specialist: `/specialists/${id}` }

        const createdVacation = await apiFetch('/holidays', {
            method: 'POST',
            body: JSON.stringify(data)
        })

        if (createdVacation && createdVacation.uuid) {
            setText('Les vacances ont été ajouté')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            document.querySelector("#comment").value = ''
            const specialistFetch = await apiFetch(`/specialists/${id}`)
            if (specialistFetch && specialistFetch.uuid) {
                setSpecialist(specialistFetch)
                handleAddVacationPopup()
            } else {
                navigate('/specialists')
            }
        } else if (createdVacation.violations) {
            const errorField = handleFormError(createdVacation, ['startDate', 'endDate', 'comment'])
            setError(errorField)
            setText('Erreur lors de la création')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        } else {
            setText((createdVacation && createdVacation['hydra:description']) || 'Erreur lors de la création')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setLoading(false)
    }

    const handleStartDateChange = (e) => {
        setStartDate(e)
    }

    const handleChangeEndDate = (e) => {
        setEndDate(e)
    }

    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleAddVacationPopup}
                aria-labelledby="title"
                aria-describedby="description"
            >
                <form noValidate onSubmit={handleCreate} autoComplete="off">
                    <DialogTitle id="title">{"Ajouter des vacances"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="description" component='div'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <DatePickerComponent
                                        darkState={darkState}
                                        color={!darkState && 'primary' }
                                        error={error.startDate}
                                        handleDateChange={handleStartDateChange}
                                        selectedDate={startDate}
                                        text="Date de début *"
                                    /><br /><br />
                                    <DatePickerComponent
                                        darkState={darkState}
                                        color={!darkState && 'primary' }
                                        error={error.endDate}
                                        handleDateChange={handleChangeEndDate}
                                        selectedDate={endDate}
                                        text="Date de fin *"
                                    /><br />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        multiline
                                        rows={5}
                                        color={darkState ? 'secondary' : "primary"}
                                        error={!!error.comment}
                                        helperText={error.comment}
                                        id="comment"
                                        name="comment"
                                        label="Commentaire"
                                    />
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleAddVacationPopup} color={darkState ? 'secondary' : "primary"}>
                            Annuler
                        </Button>
                        <Button disabled={loading} color={darkState ? 'secondary' : "primary"} type='submit'>
                            Enregistrer
                        </Button>
                    </DialogActions>
                </form>
                {loading && <LinearProgress color={darkState ? 'secondary' : "primary"} />}
            </Dialog>
        </div>
    );
}