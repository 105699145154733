import { Navigate } from 'react-router-dom'
import { isAuth } from './isAuth'
import { isSpecialistOrInterventionManager } from './hasRoles'

export const NotRequireAuth = ({ children }) => {

    if (isAuth()) {
        return isSpecialistOrInterventionManager() ? <Navigate to='/dashboard' /> : <Navigate to={'/specialists'} />
    }
    return children
}