import { useReducer } from "react"
import { apiFetch } from "../service/apiFetch"

const initialState = []

const FETCHING_LANGUAGES = 'FETCHING_LANGUAGES'
const SET_LANGUAGES = 'SET_LANGUAGES'
const ADD_LANGUAGES = 'ADD_LANGUAGES'
const UPDATE_LANGUAGES = 'UPDATE_LANGUAGES'
const DELETE_LANGUAGES = 'DELETE_LANGUAGES'

export function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCHING_LANGUAGES:
            return { ...state, loading: true }
        case SET_LANGUAGES:
            return {...state, languages: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_LANGUAGES:
            return {...state, languages: [action.payload, ...state.languages]}
        case UPDATE_LANGUAGES:
            return {...state, languages: action.payload }
        case DELETE_LANGUAGES:
            return {...state, languages: state.languages.filter((i) => {
                return i.uuid !== action.payload
            })}
        default:
            return state
    }
}

export function useLanguages() {
    const [state, dispatch] = useReducer(reducer, {    
        languages: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        languages: state.languages,
        totalItems: state.totalItems,
        view: state.view,
        fetchLanguages: async function (route, isSubscribed = () => ({current: true})) {
            if ((state.languages || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_LANGUAGES' })
            const languages = await apiFetch(route || '/languages', {method: 'GET'})

            if (isSubscribed().current && languages && languages['hydra:member']) {
                dispatch({type: 'SET_LANGUAGES', payload: languages})
            }
        },
        createLanguage: async function(data, notIterate) {
            const newLanguage = await apiFetch('/languages', {
                method: 'POST',
                body: JSON.stringify(data),
            })

            if (newLanguage.uuid && !notIterate) {
                dispatch({type: 'ADD_LANGUAGES', payload: newLanguage })
            }
            return newLanguage
        },
        updateLanguage: async function(interventionId, data, forceResponse) {
            const updatedLanguage = await apiFetch('/languages/' + interventionId, {
                method: 'PUT',
                body: JSON.stringify(data),
            }, forceResponse)

            if (!updatedLanguage.violations && !updatedLanguage['hydra:description']) {
                dispatch({type: 'UPDATE_LANGUAGES', payload: updatedLanguage })
            }
            return updatedLanguage
        },
        deleteLanguage: async function (interventionId) {
            const deletedLanguage = await apiFetch('/languages/' + interventionId, {
                method: 'DELETE'
            }, true)
            if (deletedLanguage && deletedLanguage.ok) {
                dispatch({type: 'DELETE_LANGUAGES', payload: interventionId})
            }
            return deletedLanguage
        },
    }
}