import {Map, Marker, GoogleApiWrapper, InfoWindow} from 'google-maps-react';
import React from 'react'
import { Component } from 'react';
import {
    Paper,
    Zoom,
} from '@mui/material';

export class MapContainer extends Component {
    constructor(props) {
      super(props)
      this.state = {
        showingInfoWindow: false,
        activeMarker: {},
        selectedPlace: {},
        specialist: props.specialist,
        zoom: props.zoom,
        mapZoom: 6,
      };
      this.onMarkerClick = this.onMarkerClick.bind(this)
      this.onMapClicked = this.onMapClicked.bind(this)
    }

    onMarkerClick(props, marker, e) {
      this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true,
      });
    }

    getLatLngOffset = (zoom, specialOffset) => {
      switch(zoom) {
          case 0:
              return {lat: 32.32, lng: 34}
          case 1:
              return {lat: 16.16, lng: 16.92}
          case 2:
              return {lat: 8.8, lng: 7.2}
          case 3:
              return {lat: 4.4, lng: 3.6}
          case 4:
              return {lat: 2.2, lng: 1.8}
          case 5:
              return {lat: 1.1, lng: 0.9}
          case 6:
              return {lat: 0.55, lng: 0.45}
          case 7:
              return {lat: 0.275, lng: 0.225}
          case 8:
              return {lat: 0.1375, lng: 0.1125}
          case 9:
              return {lat: 0.06875, lng: 0.05625}
          case 10:
              return {lat: 0.034375, lng: 0.028125}
          case 11:
              return {lat: 0.00859375, lng: 0.0140625}
          case 12:
              return {lat: 0.004296875, lng: 0.00703125}
          case 13:
              return {lat: 0.0021484375, lng: 0.003515625}
          case 14:
              return {lat: 0.00107421875, lng: 0.001757813}
          case 15:
              return {lat: 0.000537109375, lng: 0.000878906}
          case 16:
              return {lat: 0.0002688546875, lng: 0.000439453}
          case 17:
              return {lat: 0.00013427734375, lng: 0.0002197}
          default: 
              return {lat: 0, lng: 0}
      }
    }

    handleZoomChanged = (props, map) => {
      this.setState({mapZoom: map.zoom})
    }
  

    onMapClicked() {
      if (this.state.showingInfoWindow) {
        this.setState({
          showingInfoWindow: false,
          activeMarker: null,
        })
      }
    };

    render() {
      
      var officeIcon = {
        path: "M17.283,5.549h-5.26V4.335c0-0.222-0.183-0.404-0.404-0.404H8.381c-0.222,0-0.404,0.182-0.404,0.404v1.214h-5.26c-0.223,0-0.405,0.182-0.405,0.405v9.71c0,0.223,0.182,0.405,0.405,0.405h14.566c0.223,0,0.404-0.183,0.404-0.405v-9.71C17.688,5.731,17.506,5.549,17.283,5.549 M8.786,4.74h2.428v0.809H8.786V4.74z M16.879,15.26H3.122v-4.046h5.665v1.201c0,0.223,0.182,0.404,0.405,0.404h1.618c0.222,0,0.405-0.182,0.405-0.404v-1.201h5.665V15.26z M9.595,9.583h0.81v2.428h-0.81V9.583zM16.879,10.405h-5.665V9.19c0-0.222-0.183-0.405-0.405-0.405H9.191c-0.223,0-0.405,0.183-0.405,0.405v1.215H3.122V6.358h13.757V10.405z",
        fillColor: "blue",
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 2,
      };

      var homeIcon = {
        path: "M18.121,9.88l-7.832-7.836c-0.155-0.158-0.428-0.155-0.584,0L1.842,9.913c-0.262,0.263-0.073,0.705,0.292,0.705h2.069v7.042c0,0.227,0.187,0.414,0.414,0.414h3.725c0.228,0,0.414-0.188,0.414-0.414v-3.313h2.483v3.313c0,0.227,0.187,0.414,0.413,0.414h3.726c0.229,0,0.414-0.188,0.414-0.414v-7.042h2.068h0.004C18.331,10.617,18.389,10.146,18.121,9.88 M14.963,17.245h-2.896v-3.313c0-0.229-0.186-0.415-0.414-0.415H8.342c-0.228,0-0.414,0.187-0.414,0.415v3.313H5.032v-6.628h9.931V17.245z M3.133,9.79l6.864-6.868l6.867,6.868H3.133z",
        fillColor: "red",
        fillOpacity: 1,
        strokeWeight: 0,
        rotation: 0,
        scale: 2,
      };

      return (
        <Zoom in={this.state.zoom}>
            <Paper
              id='mapBox'
              style={this.props.phoneResponsive ? {
                  width: 215,
                  height: 215,
                  padding: 10,
                  marginTop: 30
                } : {
                  width: '511',
                  height: '420px',
                  padding: 10,
                  marginTop: 30
                }
              }
            >
                <Map 
                    google={this.props.google} 
                    zoom={6}
                    style={this.props.phoneResponsive ? {width: "195px", height: 195} : {width: '500px', height: '400px'}}
                    initialCenter={{
                      lat: parseFloat(this.state.specialist.address.latitude),
                      lng: parseFloat(this.state.specialist.address.longitude)
                    }}
                    onClick={this.onMapClicked}
                    onZoomChanged={this.handleZoomChanged}
                >
                    {this.state.specialist &&
                        <Marker
                          icon={homeIcon}
                          onClick={this.onMarkerClick}
                          name={this.state.specialist.firstName}
                          title={`${this.state.specialist.firstName} ${this.state.specialist.lastName}`}
                          position={
                            {
                              lat: this.state.specialist.address.latitude + this.getLatLngOffset(this.state.mapZoom).lat,
                              lng: this.state.specialist.address.longitude - this.getLatLngOffset(this.state.mapZoom).lng
                            }
                          }
                        />
                    }
                    {this.state.specialist && this.state.specialist.offices.length && this.state.specialist.offices.map((office) => (
                        <Marker
                          icon={officeIcon}
                          key={office.uuid}
                          onClick={this.onMarkerClick}
                          name={this.state.specialist.firstName}
                          title={`${this.state.specialist.firstName} ${this.state.specialist.lastName}`}
                          position={
                            {
                              lat: office.address.latitude + this.getLatLngOffset(this.state.mapZoom).lat,
                              lng: office.address.longitude - this.getLatLngOffset(this.state.mapZoom).lng
                            }
                          }
                        />
                      ))
                    }
                    <InfoWindow
                      onClose={this.onMapClicked}
                      marker={this.state.activeMarker}
                      visible={this.state.showingInfoWindow}
                    >
                        <div style={{maxHeight: 40, maxWidth: 50, fontSize: 12, color: 'black'}}>
                          <p>{this.state.selectedPlace.title}</p>
                        </div>
                    </InfoWindow>
                </Map>
            </Paper>
        </Zoom>
      );
    }
  }
   
  export default GoogleApiWrapper({
    apiKey: (process.env.REACT_APP_GOOGLE_MAPS_API_KEY)
  })(MapContainer)