import React, { useState } from 'react'
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { 
    Avatar,
    Toolbar,
    Typography,
    IconButton,
    Tooltip,
    Menu,
    MenuItem,
    Button,
    Zoom 
} from '@mui/material/';
import { useNavigate } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import { isLoginAsUser } from '../../service/security/isAuth';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';

export default function ToolBar({
    handleRestoreUser,
    phoneResponsive,
    darkState,
    handleThemeChange,
    handleDisconnect,
    user 
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    let navigate = useNavigate()

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangeProfil = () => {
        navigate('/')
        navigate(`/profil/${user.uuid}`)
    }

    return (
        <Toolbar style={isLoginAsUser() ? {backgroundColor: '#7fe1dd'} : {}}>
            {!phoneResponsive && isLoginAsUser() && <Button variant="contained" color="primary" onClick={handleRestoreUser}>
                Reconnection en tant qu'Admin
            </Button>}
            <div
                style={{
                    position: 'fixed',
                    right: 20,
                    top: 20,
                    display: 'flex',
                }}
            >
                <div style={{display: 'flex', cursor: 'pointer'}} onClick={handleClick}>
                    {!phoneResponsive && <div style={{display: 'block', marginRight: 10, marginLeft: 10}}>
                        <Typography variant='body2'>
                            {user.firstName}
                        </Typography>
                        <Typography variant='caption'>
                            {user.lastName}
                        </Typography>
                    </div>}
                    <Avatar alt="Menu" src="/static/images/avatar/1.jpg" />
                </div>
            </div>
            <SimpleMenu
                handleRestoreUser={handleRestoreUser}
                phoneResponsive={phoneResponsive}
                user={user}
                anchorEl={anchorEl}
                handleClose={handleClose}
                handleChangeProfil={handleChangeProfil}
                handleDisconnect={handleDisconnect}
                handleThemeChange={handleThemeChange}
                darkState={darkState}
            />
        </Toolbar>
    )
}

function SimpleMenu({
    handleRestoreUser,
    phoneResponsive,
    anchorEl,
    handleClose,
    handleThemeChange,
    darkState,
    handleDisconnect,
    handleChangeProfil,
    user
}) {
  
    return (
        <Menu
          id="menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          onClick={handleClose}
        >
            {phoneResponsive && <>
                <MenuItem>
                    {user.firstName} {user.lastName}
                </MenuItem>
                <br />
            </>}
            <MenuItem onClick={handleThemeChange} style={{marginRight: 15}}>
                <IconButton
                    color='inherit'
                    aria-label="switch dark"
                >
                    {darkState ? 
                    <Tooltip TransitionComponent={Zoom} title={darkState && 'Thème Clair'}>
                        <Brightness7Icon />
                    </Tooltip> : 
                    <Tooltip TransitionComponent={Zoom} title={!darkState && 'Thème Sombre'}>
                        <Brightness4Icon />
                    </Tooltip>
                    }
                </IconButton>
                Changer de thème
            </MenuItem>
            <MenuItem onClick={handleChangeProfil}>
                <IconButton
                    aria-label="profil"
                    color='inherit'
                >
                    <PersonIcon />
                </IconButton>
                Profil
            </MenuItem>
            {isLoginAsUser() && phoneResponsive && <>
                <MenuItem onClick={handleRestoreUser}>
                    <IconButton
                        aria-label="exit"
                        color='inherit'
                    >
                        <TransferWithinAStationIcon />
                    </IconButton>
                    Se reconnecter en tant que
                </MenuItem>
                
            </>}
            <MenuItem onClick={handleDisconnect}>
                <IconButton
                    aria-label="disconnect"
                    color='inherit'
                >
                    <Tooltip TransitionComponent={Zoom} title={'Se déconnecter'}>
                        <ExitToAppIcon />
                    </Tooltip>
                </IconButton>
                Se déconnecter
            </MenuItem>
        </Menu>
    );
}