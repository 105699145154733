import React, { useState, createRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { 
    Drawer,
    AppBar,
    CssBaseline,
    CircularProgress,
    Backdrop,
    Box,
    useMediaQuery
} from '@mui/material/';
import ToolBar from './MainMenuItem/ToolBar';
import Navigation from './MainMenuItem/Navigation';
import { Route, useNavigate, Routes } from 'react-router-dom'
import { routes } from '../service/Routes';
import SpecialistList from './SpecialistList';
import { ReadingProgress } from '../components/ReadingProgress';
import AuthService from '../service/security/handleTokenProvider'
import { apiFetch } from '../service/apiFetch';
import { AdminRoute } from '../service/routes/AdminRoute';
import UserAccount from './UserAccount';
import AlertSnackbar from '../components/AlertSnackbar';
import EditUserAccount from './UserAccountItem/EditUserAccount';
import EditAccount from './UserAccountItem/EditAccount';
import Interventions from './Interventions';
import NewIntervention from './InterventionsItem/NewIntervention';
import ManageCompany from './CompanyItem/ManageCompany';
import Company from './Company';
import ManageSpecialist from './SpecialistListItem/ManageSpecialist';
import ShowSpecialist from './SpecialistListItem/ShowSpecialist';
import Specialities from './Specialities';
import Experiences from './Experiences';
import Expertises from './Expertises';
import Languages from './Languagues';
import AdministratifStatus from './AdministratifStatus';
import Certifications from './Certifications';
import ManageSpecialities from './SpecialitiesItem/ManageSpecialities';
import ManageExperiences from './ExperienceItem/ManageExperiences';
import ManageExpertise from './ExpertiseItem/ManageExpertise';
import ManageLanguage from './LanguageItem/ManageLanguage';
import ManageAdministratifStatus from './AdministratifStatusItem/ManageAdministratifStatus';
import ManageCertification from './CertificationItem/ManageCertification';
import ManageIntervention from './InterventionsItem/ManageIntervention';
import SpecialistsMapFilter from './SpecialistsMapFilter';
import { InterventionManagerRoute } from '../service/routes/InterventionManagerRoute';
import { SpecialistManagerRoute } from '../service/routes/SpecialistManagerRoute';
import Dashboard from './Dashboard';
import { DashboardRoute } from '../service/routes/DashboardRoute';
import ManageCv from './SpecialistListItem/ShowSpecialistListItem/CVsItem/ManageCv';
import RestoreSpecialist from './RestoreSpecialist';
import { TransitionLeft } from '../helpers/utils';

export default function MainMenu({ darkState, handleThemeChange }) {
    const drawerWidth = 280;
    const theme = useTheme();
    const target = createRef();
    const [zoom, setZoom] = useState(true)
    const [user, setUser] = useState(true)
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [transition, setTransition] = useState(false)
    const [severity, setSeverity] = useState(false)
    const [text, setText] = useState(false)
    const [openBackdrop, setOpenBackdrop] = React.useState(false);
    const [languages, setLanguages] = useState([])
    const [language, setLanguage] = useState([])
    const [firstName, setFirstName] = useState(false)
    const [lastName, setLastName] = useState(false)
    const [newCoordonnate, setNewCoordonnate] = useState(false);
    const [specialities, setSpecialities] = useState([])
    const [speciality, setSpeciality] = useState([])
    const [administrativeTypes, setAdministrativeTypes] = useState([])
    const [administrativeType, setAdministrativeType] = useState([])
    const [certifications, setCertifications] = useState([])
    const [certification, setCertification] = useState([])
    const [activities, setActivities] = useState([])
    const [activity, setActivity] = useState([])
    const [specialistTypes, setSpecialistTypes] = useState([])
    const [specialistType, setSpecialistType] = useState([])
    const [filterList, setFilterList] = useState(false)
    const [state, setState] = useState({
        office: false,
        iprp: false,
        pssm: false,
        gpAudio: false,
        gpVisio: false,
    });

    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    const phoneResponsive = useMediaQuery(theme.breakpoints.down('xs'));

    let navigate = useNavigate()

    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    };
    const handleToggle = () => {
        setOpenBackdrop(!openBackdrop);
    };
    useEffect(() => {
        (async () => {
            const fetchUser = await apiFetch('/users/me')
            if (fetchUser && fetchUser['@id']) {
                setUser(fetchUser)
            } else {
                navigate('/login')
            }

            const administrativeTypesFetch = await apiFetch('/administrative_types')
            if (administrativeTypesFetch && administrativeTypesFetch['hydra:member']) {
                setAdministrativeTypes(administrativeTypesFetch['hydra:member'])
            } else {
                setText((administrativeTypesFetch && administrativeTypesFetch['hydra:description']) || 'Erreur lors de la récupération des types')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
            }
            
            setActivities([
                {id: 'homeIntervention', name: 'Intervention à domicile', '@type': 'Activity'},
                {id: 'platform', name: 'Ligne d\'écoute', '@type': 'Activity'},
                {id: 'crisisUnit', name: 'Cellules de crise', '@type': 'Activity'},
                {id: 'conflictResolutionSupport', name: 'Appui à la résolution de conflits, médiation', '@type': 'Activity'},
                {id: 'pse', name: 'Permanence sur site', '@type': 'Activity'},
                {id: 'app', name: 'Analyse de la Pratique Professionnelle', '@type': 'Activity'},
                {id: 'coaching', name: 'Coaching individuel et collectif', '@type': 'Activity'},
                {id: 'certifiedCoaching', name: 'Coaching certifié', '@type': 'Activity'},
                {id: 'projectManager', name: 'Chef de projet/audit', '@type': 'Activity'},
                {id: 'harassmentInvestigation', name: 'Enquête harcèlement', '@type': 'Activity'},
                {id: 'rpsCoach', name: 'Formateur dans la prévention des RPS auprès de salariés et de managers', '@type': 'Activity'},
                {id: 'call', name: 'Appel', '@type': 'Activity'},
                {id: 'visio', name: 'Visio', '@type': 'Activity'},
            ])
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleDisconnect = () => {
        AuthService.logout()
        navigate('/login')
    }

    const handleRestoreUser = () => {
        localStorage.setItem('REACT_TOKEN_AUTH', localStorage.getItem('REACT_TOKEN_AUTH_ORIGIN'))
        localStorage.setItem('REACT_ROLE', localStorage.getItem('REACT_ROLE_ORIGIN'))
        localStorage.removeItem('REACT_TOKEN_AUTH_ORIGIN')
        localStorage.removeItem('REACT_ROLE_ORIGIN')
        setTimeout(() => {
            window.location.reload()
        }, 200)
      }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                }}
                color="inherit"
                style={
                    darkState ? 
                    {
                        backgroundColor: '#283046',
                        borderRadius: '7px',
                        boxShadow: '0 4px 24px 0 rgba(34,41,47,.1)',
                        margin: '10px 10px 0px 0px',
                        width: window.innerWidth - drawerWidth - 30,
                    } : 
                    { 
                        borderRadius: '7px',
                        boxShadow: '0 4px 24px 0 rgba(34,41,47,.1)',
                        margin: '10px 10px 0px 0px',
                        width: window.innerWidth - drawerWidth - 30,
                    }
                }
            >
                <ToolBar
                    darkState={darkState}
                    handleThemeChange={handleThemeChange}
                    handleDisconnect={handleDisconnect}
                    user={user}
                    phoneResponsive={phoneResponsive}
                    handleRestoreUser={handleRestoreUser}
                />
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                aria-label="nav folders"
            >
                <Drawer
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true,
                  }}
                  sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                  }}
                >
                    <Navigation 
                        theme={theme} 
                        darkState={darkState}
                        setZoom={setZoom}
                        phoneResponsive={phoneResponsive}
                    />
                </Drawer>
                <Drawer
                  variant="permanent"
                  sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                  }}
                  open
                >
                    <Navigation 
                        theme={theme} 
                        darkState={darkState}
                        setZoom={setZoom}
                        phoneResponsive={phoneResponsive}
                    />
                </Drawer>
            </Box>
            <main style={{flexGrow: 1, padding: 11}} ref={target}>
                <AlertSnackbar open={openSnackbar} setOpen={setOpenSnackbar} transition={transition} message={text} severity={severity} />
                <ReadingProgress target={target} darkState={darkState} />
                <Backdrop style={{zIndex: 999999}} open={openBackdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        padding: theme.spacing(0, 1),
                        paddingTop: 60,
                        overflowX: 'none',
                        marginBottom: 20,
                    }}
                />
                { <Routes>
                    <Route path={routes.specialistList} exact element={
                        <SpecialistList
                            newCoordonnate={newCoordonnate}
                            setNewCoordonnate={setNewCoordonnate}
                            setFilterList={setFilterList}
                            filterList={filterList}
                            state={state}
                            setState={setState}
                            language={language}
                            setLanguage={setLanguage}
                            languages={languages}
                            setLanguages={setLanguages}
                            activities={activities}
                            setActivities={setActivities}
                            activity={activity}
                            setActivity={setActivity}
                            speciality={speciality}
                            setSpeciality={setSpeciality}
                            specialities={specialities}
                            setSpecialities={setSpecialities}
                            administrativeType={administrativeType}
                            setAdministrativeType={setAdministrativeType}
                            administrativeTypes={administrativeTypes}
                            setAdministrativeTypes={setAdministrativeTypes}
                            certification={certification}
                            setCertification={setCertification}
                            certifications={certifications}
                            setCertifications={setCertifications}
                            specialistType={specialistType}
                            setSpecialistType={setSpecialistType}
                            specialistTypes={specialistTypes}
                            setSpecialistTypes={setSpecialistTypes}
                            firstName={firstName}
                            setFirstName={setFirstName}
                            lastName={lastName}
                            setLastName={setLastName}
                            phoneResponsive={phoneResponsive}
                            handleCloseBackdrop={handleCloseBackdrop}
                            handleToggle={handleToggle}
                            darkState={darkState}
                            zoom={zoom}
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            setTransition={setTransition}
                            setSeverity={setSeverity}
                        />}
                    />
                    <Route path={routes.interventions} exact element={
                        <InterventionManagerRoute>
                            <Interventions 
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </InterventionManagerRoute>}
                    />
                    <Route path={routes.newIntervention} exact element={
                        <InterventionManagerRoute>
                            <NewIntervention
                                phoneResponsive={phoneResponsive}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </InterventionManagerRoute>}
                    />
                    <Route path={routes.dashboard} exact element={
                        <DashboardRoute>
                            <Dashboard 
                                phoneResponsive={phoneResponsive}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </DashboardRoute>}
                    />
                    {administrativeTypes && administrativeTypes.length && <Route path={routes.editIntervention} exact element={
                        <InterventionManagerRoute>
                            <ManageIntervention 
                                newCoordonnate={newCoordonnate}
                                setNewCoordonnate={setNewCoordonnate}
                                setFilterList={setFilterList}
                                filterList={filterList}
                                state={state}
                                setState={setState}
                                language={language}
                                setLanguage={setLanguage}
                                languages={languages}
                                setLanguages={setLanguages}
                                activities={activities}
                                setActivities={setActivities}
                                activity={activity}
                                setActivity={setActivity}
                                speciality={speciality}
                                setSpeciality={setSpeciality}
                                specialities={specialities}
                                setSpecialities={setSpecialities}
                                administrativeType={administrativeType}
                                setAdministrativeType={setAdministrativeType}
                                administrativeTypes={administrativeTypes}
                                setAdministrativeTypes={setAdministrativeTypes}
                                certification={certification}
                                setCertification={setCertification}
                                certifications={certifications}
                                setCertifications={setCertifications}
                                specialistType={specialistType}
                                setSpecialistType={setSpecialistType}
                                specialistTypes={specialistTypes}
                                setSpecialistTypes={setSpecialistTypes}
                                firstName={firstName}
                                setFirstName={setFirstName}
                                lastName={lastName}
                                setLastName={setLastName}
                                phoneResponsive={phoneResponsive}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </InterventionManagerRoute>}
                    />}
                    <Route path={routes.addSpecialistList} exact element={
                        <SpecialistManagerRoute>
                            <ManageSpecialist 
                                handleCloseBackdrop={handleCloseBackdrop}
                                handleToggle={handleToggle}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </SpecialistManagerRoute>}
                    />
                    <Route path={routes.editSpecialistList} exact element={
                        <SpecialistManagerRoute>
                            <ManageSpecialist 
                                handleCloseBackdrop={handleCloseBackdrop}
                                handleToggle={handleToggle}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </SpecialistManagerRoute>}
                    />
                    <Route path={routes.showSpecialistList} exact element={
                        <ShowSpecialist
                            phoneResponsive={phoneResponsive}
                            handleCloseBackdrop={handleCloseBackdrop}
                            handleToggle={handleToggle}
                            darkState={darkState}
                            zoom={zoom}
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            setTransition={setTransition}
                            setSeverity={setSeverity}
                        />}
                    />
                    {administrativeTypes && administrativeTypes.length && <Route path={routes.specialistsMap} exact element={
                        <SpecialistsMapFilter
                            phoneResponsive={phoneResponsive}
                            newCoordonnate={newCoordonnate}
                            setNewCoordonnate={setNewCoordonnate}
                            setFilterList={setFilterList}
                            filterList={filterList}
                            state={state}
                            setState={setState}
                            language={language}
                            setLanguage={setLanguage}
                            languages={languages}
                            setLanguages={setLanguages}
                            activities={activities}
                            setActivities={setActivities}
                            activity={activity}
                            setActivity={setActivity}
                            speciality={speciality}
                            setSpeciality={setSpeciality}
                            specialities={specialities}
                            setSpecialities={setSpecialities}
                            administrativeType={administrativeType}
                            setAdministrativeType={setAdministrativeType}
                            administrativeTypes={administrativeTypes}
                            setAdministrativeTypes={setAdministrativeTypes}
                            certification={certification}
                            setCertification={setCertification}
                            certifications={certifications}
                            setCertifications={setCertifications}
                            specialistType={specialistType}
                            setSpecialistType={setSpecialistType}
                            specialistTypes={specialistTypes}
                            setSpecialistTypes={setSpecialistTypes}
                            firstName={firstName}
                            setFirstName={setFirstName}
                            lastName={lastName}
                            setLastName={setLastName}
                            handleCloseBackdrop={handleCloseBackdrop}
                            handleToggle={handleToggle}
                            darkState={darkState}
                            zoom={zoom}
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            setTransition={setTransition}
                            setSeverity={setSeverity}
                        />}
                    />}
                    <Route path={routes.userAccount} exact element={
                        <AdminRoute>
                            <UserAccount 
                                phoneResponsive={phoneResponsive}
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.editUserAccount} exact element={
                        <AdminRoute>
                            <EditUserAccount
                                user={user}
                                phoneResponsive={phoneResponsive}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.company} exact element={
                        <AdminRoute>
                            <Company
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.specialities} exact element={
                        <AdminRoute>
                            <Specialities
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.experiences} exact element={
                        <AdminRoute>
                            <Experiences
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.expertises} exact element={
                        <AdminRoute>
                            <Expertises
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.languages} exact element={
                        <AdminRoute>
                            <Languages
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.administratifStatus} exact element={
                        <AdminRoute>
                            <AdministratifStatus
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.certifications} exact element={
                        <AdminRoute>
                            <Certifications
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.newCompany} exact element={
                        <AdminRoute>
                            <ManageCompany
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.editCompany} exact element={
                        <AdminRoute>
                            <ManageCompany
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.newSpecialities} exact element={
                        <AdminRoute>
                            <ManageSpecialities
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.editSpecialities} exact element={
                        <AdminRoute>
                            <ManageSpecialities
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.newExperiences} exact element={
                        <AdminRoute>
                            <ManageExperiences
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.editExperiences} exact element={
                        <AdminRoute>
                            <ManageExperiences
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.newExpertises} exact element={
                        <AdminRoute>
                            <ManageExpertise
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.editExpertises} exact element={
                        <AdminRoute>
                            <ManageExpertise
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.newLanguages} exact element={
                        <AdminRoute>
                            <ManageLanguage
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.editLanguages} exact element={
                        <AdminRoute>
                            <ManageLanguage
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.newAdministratifStatus} exact element={
                        <AdminRoute>
                            <ManageAdministratifStatus
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.editAdministratifStatus} exact element={
                        <AdminRoute>
                            <ManageAdministratifStatus
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.newCertifications} exact element={
                        <AdminRoute>
                            <ManageCertification
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.editCertifications} exact element={
                        <AdminRoute>
                            <ManageCertification
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.manageCv} exact element={
                        <SpecialistManagerRoute>
                            <ManageCv
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </SpecialistManagerRoute>}
                    />
                    <Route path={routes.restore} exact element={
                        <AdminRoute>
                            <RestoreSpecialist
                                user={user}
                                darkState={darkState}
                                zoom={zoom}
                                setOpenSnackbar={setOpenSnackbar}
                                setText={setText}
                                setTransition={setTransition}
                                setSeverity={setSeverity}
                            />
                        </AdminRoute>}
                    />
                    <Route path={routes.editAdminAccount} exact element={
                        <EditAccount
                            phoneResponsive={phoneResponsive}
                            darkState={darkState}
                            zoom={zoom}
                            setOpenSnackbar={setOpenSnackbar}
                            setText={setText}
                            setTransition={setTransition}
                            setSeverity={setSeverity}
                        />}
                    />
                </Routes>}
            </main>
        </Box>
    )
}