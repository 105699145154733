import React from 'react';
import { Dialog, DialogContent, Slide, DialogActions, Button, TextField } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CommentPopup({ open, handleClose, text, setText, handleAccept, darkState}) {
    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="change-date-popup-title"
            aria-describedby="change-date-popup-description"
        >
            {open.remark ? <DialogContent style={{paddingBottom: 20}}>
                {open.remark}
            </DialogContent> : 
            <><DialogContent>
                <TextField multiline rows={4} label='Commentaire' color={darkState ? 'secondary' : 'primary'} value={text} onChange={(e) => setText(e.target.value)} />
            </DialogContent>
            <DialogActions>
                <Button disabled={typeof text === 'string' && !text.length} variant={'contained'} color={darkState ? 'secondary' : 'primary'} onClick={handleAccept}>Enregistrer</Button>
            </DialogActions></>}
        </Dialog>
    );
}   