import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Typography } from '@mui/material';
import moment from 'moment';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PlanningEventPopup({ open, handleClose, darkState }) {

  return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="planning-event-popup-title"
            aria-describedby="planning-event-popup-description"
            style={{zIndex: 1301}}
        >
            <DialogTitle id="planning-event-popup-title">{`Ce psychologue semble occupé pour la période demandée`}</DialogTitle>
            <DialogContent>
                <DialogContentText id="planning-event-popup-description" component='div'>
                    {open && open.planning.events.map(event => {
                        return <Typography key={event['@id']} variant='body2'>• Ce psychologue est en {event.type === 'plateforme' ? 'disponibilité plateforme' : event.type} du {moment(event.from).format('DD MMMM YYYY HH:mm')} au {moment(event.to).format('DD MMMM YYYY HH:mm')}</Typography>
                    })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={darkState ? 'secondary' : 'primary'}>
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
  );
}