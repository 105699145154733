import { useReducer } from "react"
import { apiFetch } from "../service/apiFetch"

const initialState = []

const FETCHING_SPECIALITIES = 'FETCHING_SPECIALITIES'
const SET_SPECIALITIES = 'SET_SPECIALITIES'
const ADD_SPECIALITIES = 'ADD_SPECIALITIES'
const UPDATE_SPECIALITIES = 'UPDATE_SPECIALITIES'
const DELETE_SPECIALITIES = 'DELETE_SPECIALITIES'

export function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCHING_SPECIALITIES:
            return { ...state, loading: true }
        case SET_SPECIALITIES:
            return {...state, specialities: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_SPECIALITIES:
            return {...state, specialities: [action.payload, ...state.specialities]}
        case UPDATE_SPECIALITIES:
            return {...state, specialities: action.payload }
        case DELETE_SPECIALITIES:
            return {...state, specialities: state.specialities.filter((i) => {
                return i.uuid !== action.payload
            })}
        default:
            return state
    }
}

export function useSpecialities() {
    const [state, dispatch] = useReducer(reducer, {    
        specialities: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        specialities: state.specialities,
        totalItems: state.totalItems,
        view: state.view,
        fetchSpecialities: async function (route, isSubscribed = () => ({current: true})) {
            if ((state.specialities || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_SPECIALITIES' })
            const specialities = await apiFetch(route || '/specialities', {method: 'GET'})
            if (isSubscribed().current && specialities && specialities['hydra:member']) {
                dispatch({type: 'SET_SPECIALITIES', payload: specialities})
            }
        },
        createSpeciality: async function(data, notIterate) {
            const newSpeciality = await apiFetch('/specialities', {
                method: 'POST',
                body: JSON.stringify(data),
            })

            if (newSpeciality.uuid && !notIterate) {
                dispatch({type: 'ADD_SPECIALITIES', payload: newSpeciality })
            }
            return newSpeciality
        },
        updateSpeciality: async function(interventionId, data, forceResponse) {
            const updatedSpeciality = await apiFetch('/specialities/' + interventionId, {
                method: 'PUT',
                body: JSON.stringify(data),
            }, forceResponse)

            if (!updatedSpeciality.violations && !updatedSpeciality['hydra:description']) {
                dispatch({type: 'UPDATE_SPECIALITIES', payload: updatedSpeciality })
            }
            return updatedSpeciality
        },
        deleteSpeciality: async function (interventionId) {
            const deletedSpeciality = await apiFetch('/specialities/' + interventionId, {
                method: 'DELETE'
            }, true)
            if (deletedSpeciality && deletedSpeciality.ok) {
                dispatch({type: 'DELETE_SPECIALITIES', payload: interventionId})
            }
            return deletedSpeciality
        },
    }
}