import React, { useState } from 'react'
import { Card, CardHeader, CardContent, CardActions, Avatar, IconButton, Typography, Menu, MenuItem, Tooltip, Zoom} from '@mui/material/';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeletedConfirmationPopup from '../../../components/DeletedConfirmationPopup';
import { useNavigate } from 'react-router-dom';
import {isSpecialistManagerOrAdmin} from '../../../service/security/hasRoles';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import DescriptionPopup from './Helpers/DescriptionPopup';
import { apiFetch } from '../../../service/apiFetch';
import { handleFormError } from '../../../service/security/formError';
import { TransitionLeft } from '../../../helpers/utils';
import ImportantDevicesIcon from '@mui/icons-material/ImportantDevices';
import moment from 'moment'

export default function UserCard({
    setAllSpecialists,
    fetchSpecialists,
    specialist,
    handleDelete,
    darkState,
    handleFavorite,
    handleSharePopup,
    setText,
    setSeverity,
    setTransition,
    setOpenSnackbar
}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [deletePopupOpen, setDeletePopupOpen] = useState(false);
    const [openDescriptionPopup, setOpenDescriptionPopup] = useState(false);
    const [error, setError] = useState({});
    const [descriptionData, setDescriptionData] = useState({
        whyDescription: '',
        whoDescription: '',
        howDescription: '',
    });
    let navigate = useNavigate()

    const handleMoreClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteSpecialist = () => {
        handleDelete(specialist)
        setAnchorEl(null)
        setDeletePopupOpen(false)
    }
    
    const handleCloseConfirmationPopup = () => {
        setDeletePopupOpen(false)
    }

    const handleEditSpecialist = () => {
        navigate(`/specialists/${specialist.uuid}/edit`)
    }

    const handleOpenDescriptionPopup = () => {
        handleClose()
        setOpenDescriptionPopup(true)
    }

    const handleChooseReferringPsy = async () => {
        const referringPsy = await apiFetch(`/referring_specialists`, {
            method: 'POST',
            body: JSON.stringify({
                specialist: specialist['@id'],
                whyDescription : descriptionData.whyDescription,
                whoDescription : descriptionData.whoDescription,
                howDescription : descriptionData.howDescription
            })
        })
        if (referringPsy && referringPsy.createdAt) {
            const refreshSpecialists = await fetchSpecialists(`/specialists?administrativeTypes.name[]=Pros-Consulte&`)
            setAllSpecialists(refreshSpecialists)
            setText(`Le psy référent a été ajouté !`)
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
        } else {
            const errorField = handleFormError(referringPsy, ['whyDescription', 'whoDescription', 'howDescription'])
            setError(errorField)
            setText(`Erreur lors de l'ajout du psy référent`)
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setOpenDescriptionPopup(false)
    }

    return (
        <Card>
            <CardHeader
                avatar={
                    <Avatar src={`${specialist.photo && specialist.photo.contentUrl}`} aria-label="specialist">
                        {specialist.firstName.charAt(0)}
                    </Avatar>
                }
                action={
                    <>
                        {isSpecialistManagerOrAdmin() && <><IconButton color='inherit' aria-label="settings" onClick={handleMoreClick}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleEditSpecialist}>Modifier</MenuItem>
                            <MenuItem onClick={() => setDeletePopupOpen(true)}>Supprimer</MenuItem>
                            <MenuItem onClick={handleOpenDescriptionPopup}>Choisir ce Psy comme référent</MenuItem>
                        </Menu></>}
                    </>
                }
                title={
                    <a href={`/specialists/${specialist.uuid}`}  style={{textDecoration: 'none', color: darkState ? 'white' : "black"}}>
                        {specialist.title} {specialist.lastName} {specialist.firstName}
                    </a>
                }
                subheader={
                    specialist.department ? 
                        `${specialist.department && (specialist.department.name)} (${specialist.department && specialist.department.code})` : 
                        ''
                }
            />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    <span>Ajouté le : </span>{`${moment(specialist.createdAt).format('DD.MM.YYYY')}`}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    <span>Ville : </span>{`${specialist.address.fullAddress}`}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    <span>Langue(s) : </span>{specialist.languages.length ?
                        specialist.languages.map((language) => `${language.name}, `) : 'Non renseigné'}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    <span>Type de réseau : </span>{specialist.administrativeTypes.length ?
                        specialist.administrativeTypes.map((type) => `${type.name}, `) : 'Non renseigné'}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    <span>Spécialités cliniques : </span>{specialist.specialities.length ?
                        specialist.specialities.map((speciality) => `${speciality.name}, `) : 'Non renseigné'}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    <span>N° mobile pro : </span>{specialist.mobilePro || 'Non renseigné'}
                </Typography>
            </CardContent>
            <CardActions disableSpacing>
                <IconButton color='inherit' aria-label="add to favorites" onClick={() => handleFavorite(specialist.uuid)}>
                    {specialist.isFavorite ? <FavoriteIcon /> : <FavoriteBorderIcon />}
                </IconButton>
                <Tooltip TransitionComponent={Zoom} title={'Partager un CV via email'}>
                    <IconButton color='inherit' aria-label="share" onClick={() => handleSharePopup(specialist.uuid)}>
                        <ShareIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip TransitionComponent={Zoom} title={specialist.isReferring ? 'Ce psy est référent' : 'Choisir ce psy comme référent'}>
                    <IconButton
                        aria-label="share"
                        onClick={handleOpenDescriptionPopup}
                        color={specialist.isReferring ? darkState ? 'secondary' : 'primary' : 'inherit'}
                    >
                        <ImportantDevicesIcon />
                    </IconButton>
                </Tooltip>
            </CardActions>
            <DeletedConfirmationPopup
                handleAccept={handleDeleteSpecialist}
                open={deletePopupOpen}
                handleClose={handleCloseConfirmationPopup}
                darkState={darkState}
            />
            <DescriptionPopup
                setDescriptionData={setDescriptionData}
                descriptionData={descriptionData}
                handleAccept={handleChooseReferringPsy}
                open={openDescriptionPopup}
                handleClose={() => setOpenDescriptionPopup(false)}
                darkState={darkState}
                error={error}
            />
        </Card>
    )
}