import {
    Grid,
    Typography,
    TextField,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    FormHelperText,
    FormControlLabel,
    Switch,
    Paper,
} from '@mui/material';
import { DatePickerComponent } from '../../components/DateTimePickerComponent';
import AutoCompleteAddress from '../../components/AutoCompleteAddress';
import UploadFile from '../../components/UploadItem';
import React from 'react'
import DrapFrance from '../../img/drapeauFrance.png'
import DrapGuad from '../../img/Logo_region-guadeloupe.png'
import DrapMart from '../../img/Flag-of-Martinique.png'
import DrapMay from '../../img/Logo_de_Mayotte.png'
import DrapReun from '../../img/Proposed_flag_of_Réunion.png'
import DrapGuy from '../../img/Flag_of_French_Guyane.png'
import DrapTahiti from '../../img/Flag_of_French_Polynesia.svg.png'
import DrapNewCal from '../../img/Nouvelle-caledonie_Drapeau_Flag_Bandiera.jpg'

export default function Information({
    error,
    title,
    handleChangeTitle,
    lastName,
    setLastName,
    firstName,
    setFirstName,
    email,
    setEmail,
    state,
    handleChange,
    mobilePro,
    setMobilePro,
    phoneRegionCode,
    handlephoneRegionCodeChange,
    mobilePerso,
    setMobilePerso,
    phone,
    setPhone,
    setBirthdate,
    birthdate,
    darkState,
    id,
    specialistEdit,
    setNewAddress,
    setNewCoordonnate,
    setPhoto,
}) {
    const flagImages = {
        FR: DrapFrance,
        GP: DrapGuad,
        MQ: DrapMart,
        RE: DrapReun,
        YT: DrapMay,
        GF: DrapGuy,
        NC: DrapNewCal,
        PF: DrapTahiti,
    }
    const renderValue = (value) => {
        if (!value) return <em>Sélectionner</em>;
        return <img src={flagImages[value]} alt="Drapeau National" style={{ width: '20px', height: 'auto', marginRight: '5px' }}/>;
      };

    return (
        <Paper
            sx={{mt: 3, pl: 3}}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormControl
                        error={!!error.title}
                        required
                        color={darkState ? 'secondary' : "primary"}
                        sx={{
                            width: 300,
                            mb: 3
                        }}
                    >
                        <InputLabel id="select-label">Titre</InputLabel>
                        <Select
                            labelId="select-label"
                            id="select"
                            value={title}
                            label="Age"
                            onChange={handleChangeTitle}
                        >
                            <MenuItem value="">
                                <em>Sélectionner</em>
                            </MenuItem>
                            <MenuItem value={'Mme'}>Mme</MenuItem>
                            <MenuItem value={'Mr'}>Mr</MenuItem>
                        </Select>
                        <FormHelperText>{error.title}</FormHelperText>
                    </FormControl><br />
                    <TextField
                        value={lastName}
                        onChange={(event) => {
                            setLastName(event.target.value);
                        }} 
                        color={darkState ? 'secondary' : "primary"}
                        required
                        error={!!error.lastName}
                        helperText={error.lastName}
                        id="lastName"
                        name="lastName"
                        label="Nom"
                        sx={{
                            width: 300,
                            mb: 3
                        }}
                    /><br />
                    <TextField
                        value={firstName}
                        onChange={(event) => {
                            setFirstName(event.target.value);
                        }}
                        color={darkState ? 'secondary' : "primary"}
                        required 
                        error={!!error.firstName}
                        helperText={error.firstName}
                        id="firstName"
                        name="firstName"
                        label="Prénom" 
                        sx={{
                            width: 300,
                            mb: 3
                        }}
                    /><br />
                    <TextField
                        value={email}
                        onChange={(event) => {
                          setEmail(event.target.value);
                        }}
                        color={darkState ? 'secondary' : "primary"}
                        required 
                        error={!!error.email}
                        helperText={error.email}
                        id="email"
                        name="email"
                        label="Email"
                        sx={{
                            width: 300,
                            mb: 3
                        }}
                    /><br />
                    <Grid container spacing={3} sx={{maxWidth: 400}}>
                        <Grid item xs={4} >
                            <Typography style={{margin: 0, color: darkState ? '#bbb' : '#676'}}>Véhiculé : </Typography>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop: 15}}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={state.motorized}
                                        onChange={handleChange}
                                        name="motorized"
                                        id="motorized"
                                        color={ darkState ? 'secondary' :"primary"}
                                    />
                                }
                                style={{margin: 0, color: darkState ? '#bbb' : '#676', marginBottom: 20}}
                            /><br />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        <FormControl variant="outlined" sx={{ minWidth: 75, marginRight: '8px' }} color={darkState ? 'secondary' : "primary"}>
                            <InputLabel id="select-label-phoneRegionCode">Indicatif de lieu</InputLabel>
                            <Select
                                labelId="select-label-phoneRegionCode"
                                id="select"
                                value={phoneRegionCode}
                                onChange={handlephoneRegionCodeChange}
                                label="Indicatif de lieu"
                                renderValue={renderValue}
                            >
                                <MenuItem value="">
                                    <em>Sélectionner</em>
                                </MenuItem>
                                <MenuItem value="FR"><img src={flagImages.FR} alt="drapeau France" style={{ width: '20px', height: 'auto', marginRight: '5px' }} /> France Métropolitaine (+33)</MenuItem>
                                <MenuItem value={"GP"}><img src={flagImages.GP} alt="drapeau Guadeloupe" style={{ width: '20px', height: 'auto', marginRight: '5px' }}/>Guadeloupe(+590)</MenuItem>
                                <MenuItem value={"MQ"}><img src={flagImages.MQ} alt="drapeau Martinique" style={{ width: '20px', height: 'auto', marginRight: '5px' }}/>Martinique (+596)</MenuItem>
                                <MenuItem value={"RE"}><img src={flagImages.RE} alt="drapeau Réunion" style={{ width: '20px', height: 'auto', marginRight: '5px' }}/>Réunion (+262)</MenuItem>
                                <MenuItem value={"YT"}><img src={flagImages.YT} alt="drapeau Mayotte" style={{ width: '20px', height: 'auto', marginRight: '5px' }}/>Mayotte (+262)</MenuItem>
                                <MenuItem value={"GF"}><img src={flagImages.GF} alt="drapeau Guyane" style={{ width: '20px', height: 'auto', marginRight: '5px' }}/>Guyane (+594)</MenuItem>
                                <MenuItem value={"NC"}><img src={flagImages.NC} alt="drapeau Nouvelle Caledonie" style={{ width: '20px', height: 'auto', marginRight: '5px' }}/>Nouvelle Calédonie (+687)</MenuItem>
                                <MenuItem value={"PF"}><img src={flagImages.PF} alt="drapeau Tahiti" style={{ width: '20px', height: 'auto', marginRight: '5px' }}/>Polynésie française (+689)</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField
                            value={mobilePro}
                            onChange={(event) => {
                                setMobilePro(event.target.value);
                            }}
                            color={darkState ? 'secondary' : "primary"}
                            required
                            error={!!error.mobilePro}
                            helperText={error.mobilePro}
                            id="mobilePro"
                            name="mobilePro"
                            label="N° mobile pro"
                            sx={{
                                width: 300,
                                mb: 3
                            }}
                        />
                    </div>
                    <TextField
                        value={mobilePerso}
                        onChange={(event) => {
                          setMobilePerso(event.target.value);
                        }}
                        color={darkState ? 'secondary' : "primary"}
                        error={!!error.mobilePerso}
                        helperText={error.mobilePerso}
                        id="mobilePerso"
                        name="mobilePerso"
                        label="N° mobile perso"
                        sx={{
                            width: 300,
                            mb: 3
                        }}
                    /><br />
                    <TextField
                        value={phone}
                        onChange={(event) => {
                          setPhone(event.target.value);
                        }}
                        color={darkState ? 'secondary' : "primary"}
                        required
                        error={!!error.phone}
                        helperText={error.phone}
                        id="phone"
                        name="phone"
                        label="N° fixe"
                        sx={{
                            width: 300,
                            mb: 3
                        }}
                    /><br />
                    <DatePickerComponent
                        darkState={darkState}
                        color={darkState ? 'secondary' : 'primary'}
                        handleDateChange={setBirthdate}
                        selectedDate={birthdate}
                        text="Date de naissance"
                    />
                    <AutoCompleteAddress
                        isCv={true}
                        darkState={darkState}
                        required
                        error={!!error.address}
                        text={error.address}
                        defaultValue={id && specialistEdit.address ? specialistEdit.address.address : ''}
                        id="address"
                        name="address"
                        label="Adresse personnelle"
                        setNewAddress={setNewAddress}
                        setNewCoordonnate={setNewCoordonnate}
                        style={{width: 300, marginTop: 24}}
                    />
                    <div style={{marginTop: 24}}>
                        <UploadFile setPhoto={setPhoto} />
                    </div>
                </Grid>
            </Grid>
        </Paper>
    )
}