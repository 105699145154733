import { useReducer } from "react"
import { apiFetch } from "../service/apiFetch"

const initialState = []

const FETCHING_DOCUMENTS = 'FETCHING_DOCUMENTS'
const SET_DOCUMENTS = 'SET_DOCUMENTS'
const ADD_DOCUMENTS = 'ADD_DOCUMENTS'
const UPDATE_DOCUMENTS = 'UPDATE_DOCUMENTS'
const DELETE_DOCUMENTS = 'DELETE_DOCUMENTS'

export function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCHING_DOCUMENTS:
            return { ...state, loading: true }
        case SET_DOCUMENTS:
            return {...state, documents: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_DOCUMENTS:
            return {...state, documents: [action.payload, ...state.documents]}
        case UPDATE_DOCUMENTS:
            return {...state, documents: action.payload }
        case DELETE_DOCUMENTS:
            return {...state, documents: state.documents.filter((i) => {
                return i.uuid !== action.payload
            })}
        default:
            return state
    }
}

export function useDocuments() {
    const [state, dispatch] = useReducer(reducer, {    
        documents: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        documents: state.documents,
        totalItems: state.totalItems,
        view: state.view,
        fetchDocuments: async function (id, isSubscribed = () => ({current: true}), force, route) {
            if ((state.documents || state.loading || state.totalItems) && !force && !route) {
                return
            }
            dispatch({ type: 'FETCHING_DOCUMENTS' })
            const documents = await apiFetch(route || `/documents?specialist.uuid=${id}`, {method: 'GET'})

            if (isSubscribed().current && documents && documents['hydra:member']) {
                dispatch({type: 'SET_DOCUMENTS', payload: documents})
            }
        },
        createDocument: async function(data, notIterate) {
            const newDocument = await apiFetch('/documents', {
                method: 'POST',
                body: JSON.stringify(data),
            })

            if (newDocument.uuid && !notIterate) {
                dispatch({type: 'ADD_DOCUMENTS', payload: newDocument })
            }
            return newDocument
        },
        deleteDocument: async function (documentId) {
            const deletedDocument = await apiFetch('/documents/' + documentId, {
                method: 'DELETE'
            }, true)
            if (deletedDocument && deletedDocument.ok) {
                dispatch({type: 'DELETE_DOCUMENTS', payload: documentId})
            }
            return deletedDocument
        },
    }
}