import React, { useEffect, useState } from 'react'
import { Grid, Paper, TextField, Zoom, Typography, Divider, Button, ButtonGroup, LinearProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { TransitionLeft } from '../../helpers/utils';
import { apiFetch } from '../../service/apiFetch';
import { handleFormError } from '../../service/security/formError';
import { useLanguages } from '../../hooks/languages';

export default function ManageLanguage({ darkState, zoom, setOpenSnackbar, setText, setTransition, setSeverity }) {
    const [language, setLanguage] = useState(false)
    const [error, setError] = useState({});
    let navigate = useNavigate()
    const { id } = useParams()
    const [loading, setLoading] = useState(true)

    const {
        createLanguage,
        updateLanguage
    } = useLanguages()
    
    useEffect(() => {
        (async () => {
            if (id) {
                const languageFetch = await apiFetch(`/languages/${id}`)
                if (languageFetch && languageFetch.uuid) {
                    setLanguage(languageFetch)
                } else {
                    setSeverity('error')
                    setText('Error lors de la récupération des données')
                    setTransition(() => TransitionLeft)
                    setOpenSnackbar(true)
                    navigate('/languages')
                }
            }
        })()
    }, [id, navigate, setOpenSnackbar, setSeverity, setTransition, setText, setLanguage])

    const handleBack = () => {
        navigate('/languages')
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        if (!id) {
            await handleCreate(e)
        } else {
            handleEdit(e)
        }
        setLoading(false)
    }

    const handleCreate = async (e) => {
        setError({})
        let data = new FormData(e.target)
        const name = data.get('name')

        data = { name: name }

        const createdLanguage = await createLanguage(data, true)

        if (createdLanguage && createdLanguage.uuid) {
            setText('La langue a été crée')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setTimeout(() => {
                navigate('/languages')
            }, 2000)
        } else if (createdLanguage.violations) {
            const errorField = handleFormError(createdLanguage, ['name'])
            setError(errorField)
            setText('Erreur lors de la création')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        } else {
            setText((createdLanguage && createdLanguage['hydra:description']) || 'Erreur lors de la création')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
    }

    const handleEdit = async (e) => {
        setError({})
        let data = new FormData(e.target)
        const name = data.get('name')

        data = { name: name }

        const updatedLanguage = await updateLanguage(id, data)

        if (updatedLanguage && updatedLanguage.uuid) {
            setText('La langue a été modifié')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setTimeout(() => {
                navigate('/languages')
            }, 2000)
        } else if (updatedLanguage.violations) {
            const errorField = handleFormError(updatedLanguage, ['name'])
            setError(errorField)
            setText('Erreur lors de la modification')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        } else {
            setText((updatedLanguage && updatedLanguage['hydra:description']) || 'Erreur lors de la modification')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
    }

    const handleChange = () => {
        setLoading(false)
    }

    return (
        <Zoom in={zoom}>
            <Paper
                sx={{
                    pt: 1
                }}
            >
                <Typography
                    variant='h5'
                    color={darkState ? 'secondary' : 'primary'}
                    sx={{
                        ml: 3,
                        mb: 1
                    }}
                >
                    {id ? 'Modifier' : 'Ajouter'} une langue
                </Typography>
                <Divider />
                {(id && language) || !id ? <form noValidate autoComplete="off" onSubmit={handleSubmit} onChange={handleChange}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                color={darkState ? 'secondary' : 'primary'}
                                error={!!error.name}
                                helperText={error.name}
                                id="name"
                                name='name'
                                label="Nom de la langue"
                                defaultValue={language.name}
                                sx={{
                                    width: 300,
                                    mt: 2,
                                    mb: 3,
                                    ml: 3,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                        </Grid>
                    </Grid><br />
                    <ButtonGroup
                        color={darkState ? 'secondary' : "primary"}
                        variant="contained"
                        aria-label="contained primary button group"
                        sx={{m: 3,}}
                    >
                        <Button
                            disabled={loading}
                            type="submit"
                        >
                            Enregister
                        </Button>
                        <Button onClick={handleBack}>
                            Retour
                        </Button>
                    </ButtonGroup>
                </form> : 
                <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
            </Paper>
        </Zoom>
    )
}