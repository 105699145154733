import { useReducer } from "react"
import { apiFetch } from "../service/apiFetch"

const initialState = []

const FETCHING_INTERVENTIONS = 'FETCHING_INTERVENTIONS'
const SET_INTERVENTIONS = 'SET_INTERVENTIONS'
const ADD_INTERVENTIONS = 'ADD_INTERVENTIONS'
const UPDATE_INTERVENTIONS = 'UPDATE_INTERVENTIONS'
const DELETE_INTERVENTIONS = 'DELETE_INTERVENTIONS'

export function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCHING_INTERVENTIONS:
            return { ...state, loading: true }
        case SET_INTERVENTIONS:
            return {...state, interventions: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_INTERVENTIONS:
            return {...state, interventions: [action.payload, ...state.interventions]}
        case UPDATE_INTERVENTIONS:
            return {...state, interventions: action.payload }
        case DELETE_INTERVENTIONS:
            return {...state, interventions: state.interventions.filter((i) => {
                return i.uuid !== action.payload
            })}
        default:
            return state
    }
}

export function useInterventions() {
    const [state, dispatch] = useReducer(reducer, {    
        interventions: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        interventions: state.interventions,
        totalItems: state.totalItems,
        view: state.view,
        fetchInterventions: async function (route, isSubscribed = () => ({current: true})) {
            if ((state.interventions || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_INTERVENTIONS' })
            const interventions = await apiFetch(route || '/interventions?order[startDate]', {method: 'GET'})

            if (isSubscribed().current && interventions && interventions['hydra:member']) {
                dispatch({type: 'SET_INTERVENTIONS', payload: interventions})
            }
        },
        createIntervention: async function(data, notIterate) {
            const newIntervention = await apiFetch('/interventions', {
                method: 'POST',
                body: JSON.stringify(data),
            })

            if (newIntervention.uuid && !notIterate) {
                dispatch({type: 'ADD_INTERVENTIONS', payload: newIntervention })
            }
            return newIntervention
        },
        updateIntervention: async function(interventionId, data, forceResponse) {
            const updatedIntervention = await apiFetch('/interventions/' + interventionId, {
                method: 'PUT',
                body: JSON.stringify(data),
            }, forceResponse)

            const interventions = await apiFetch('/interventions', {method: 'GET'})

            if (!updatedIntervention.violations && !updatedIntervention['hydra:description'] && interventions && interventions['hydra:member']) {
                dispatch({type: 'SET_INTERVENTIONS', payload: interventions})
            }

            return updatedIntervention
        },
        deleteIntervention: async function (interventionId) {
            const deletedIntervention = await apiFetch('/interventions/' + interventionId, {
                method: 'DELETE'
            }, true)
            if (deletedIntervention && deletedIntervention.ok) {
                dispatch({type: 'DELETE_INTERVENTIONS', payload: interventionId})
            }
            return deletedIntervention
        },
    }
}