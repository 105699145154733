import React from 'react'
import {
    Paper,
    Zoom,
    Tooltip,
    Typography,
} from '@mui/material';

export default function Reference({ specialist, zoom, darkState }) {
    return (
        <Zoom in={zoom} style={{ transitionDelay: '400ms' }}>
            <Paper
                sx={{
                    padding: 1.25,
                    boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)',
                    mt: 3.75
                }}
            >
                {specialist.companies && specialist.companies.length ? specialist.companies.map((ref) => (
                    <Tooltip key={ref.id} TransitionComponent={Zoom} title={ref.name}>
                        <img
                            src={`${ref.logo && ref.logo.contentUrl}`}
                            alt=""
                            style={{
                                width: 110,
                                marginRight: 5
                            }}
                        />
                    </Tooltip>
                ))
            : 
            <>
                <Typography variant='h6' color={darkState ? 'secondary' : 'primary'} style={{marginBottom: 10, fontWeight: 'bold'}}>
                    <span>Référence(s)</span>
                </Typography>
                <Typography style={{marginLeft: 50}}>Pas encore de référence...</Typography>
            </>}
            </Paper>
        </Zoom>
    )
}