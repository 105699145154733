import React, { useEffect, useState } from 'react'
import SpecialistFilters from '../components/SpecialistFilters'
import { TransitionLeft } from '../helpers/utils';
import { apiFetch } from '../service/apiFetch';
import MapContainer from './SpecialistsMap'
import { CircularProgress } from '@mui/material';

export default function SpecialistsMapFilter({
    newCoordonnate,
    setNewCoordonnate,
    filterList,
    setFilterList,
    language,
    setLanguage,
    languages,
    setLanguages,
    activity,
    setActivity,
    activities,
    setActivities,
    speciality,
    setSpeciality,
    specialities,
    setSpecialities,
    administrativeType,
    setAdministrativeType,
    administrativeTypes,
    setAdministrativeTypes,
    certification,
    setCertification,
    certifications,
    setCertifications,
    specialistType,
    setSpecialistType,
    specialistTypes,
    setSpecialistTypes,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    setState,
    state,
    darkState,
    zoom,
    setOpenSnackbar,
    setText,
    setTransition,
    setSeverity,
    phoneResponsive
}) {
    const [expanded, setExpanded] = useState(false)
    const [specialists, setSpecialists] = useState(false)
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {
        (async () => {
            setNewCoordonnate(null)
            setAdministrativeType([administrativeTypes.find((item) => item.name === 'Pros-Consulte')])

            setFilterList([speciality,
                [{'@type': 'firstName', name: firstName}],
                [{'@type': 'lastName', name: lastName}],
                language,
                [administrativeTypes.find((item) => item.name === 'Pros-Consulte')],
                specialistType,
                certification,
                state,
                activity
            ])

            const specialistsFetch = await apiFetch('/specialists?pagination=false&administrativeTypes.name[]=Pros-Consulte&')
            if (specialistsFetch && specialistsFetch['hydra:member']) {
                setSpecialists(specialistsFetch)
            } else {
                setText((specialistsFetch && specialistsFetch['hydra:description']) || 'Erreur de la récupération des spécialistes !')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
            }
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setText, setTransition, setSeverity, setOpenSnackbar, setAdministrativeType])

    return (
        <>
            <SpecialistFilters
                isFromMap={true}
                newCoordonnate={newCoordonnate}
                setNewCoordonnate={setNewCoordonnate}
                activities={activities}
                setActivities={setActivities}
                activity={activity}
                setActivity={setActivity}
                setFilterList={setFilterList}
                filterList={filterList}
                state={state}
                setState={setState}
                language={language}
                setLanguage={setLanguage}
                languages={languages}
                setLanguages={setLanguages}
                speciality={speciality}
                setSpeciality={setSpeciality}
                specialities={specialities}
                setSpecialities={setSpecialities}
                administrativeType={administrativeType}
                setAdministrativeType={setAdministrativeType}
                administrativeTypes={administrativeTypes}
                setAdministrativeTypes={setAdministrativeTypes}
                certification={certification}
                setCertification={setCertification}
                certifications={certifications}
                setCertifications={setCertifications}
                specialistType={specialistType}
                setSpecialistType={setSpecialistType}
                specialistTypes={specialistTypes}
                setSpecialistTypes={setSpecialistTypes}
                firstName={firstName}
                setFirstName={setFirstName}
                lastName={lastName}
                setLastName={setLastName}
                phoneResponsive={phoneResponsive}
                setRefresh={setRefresh}
                setSpecialists={setSpecialists}
                darkState={darkState}
                expanded={expanded}
                setExpanded={setExpanded}
                zoom={zoom}
                setOpenSnackbar={setOpenSnackbar}
                setText={setText}
                setTransition={setTransition}
                setSeverity={setSeverity}
            />
            {specialists && !refresh ? <MapContainer newCoordonnate={newCoordonnate} specialists={specialists} isFromGeneralMap={true} /> : 
            <div style={{textAlign: 'center'}}>
                <CircularProgress color={darkState ? 'secondary' : 'primary'} />
            </div>}
        </>
    )
}