import { useReducer } from "react"
import { apiFetch } from "../service/apiFetch"

const initialState = []

const FETCHING_CONTRACTS = 'FETCHING_CONTRACTS'
const SET_CONTRACTS = 'SET_CONTRACTS'
const ADD_CONTRACTS = 'ADD_CONTRACTS'
const UPDATE_CONTRACTS = 'UPDATE_CONTRACTS'
const DELETE_CONTRACTS = 'DELETE_CONTRACTS'

export function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCHING_CONTRACTS:
            return { ...state, loading: true }
        case SET_CONTRACTS:
            return {...state, contracts: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_CONTRACTS:
            return {...state, contracts: [action.payload, ...state.contracts]}
        case UPDATE_CONTRACTS:
            const tempState = state.contracts.filter((i) => {
                return i.uuid !== action.payload.uuid
            })
            return {...state, contracts: [action.payload, ...tempState]}
        case DELETE_CONTRACTS:
            return {...state, contracts: state.contracts.filter((i) => {
                return i.uuid !== action.payload
            })}
        default:
            return state
    }
}

export function useContracts() {
    const [state, dispatch] = useReducer(reducer, {    
        contracts: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        contracts: state.contracts,
        totalItems: state.totalItems,
        view: state.view,
        fetchContracts: async function (id, isSubscribed = () => ({current: true})) {
            if ((state.contracts || state.loading || state.totalItems)) {
                return
            }
            dispatch({ type: 'FETCHING_CONTRACTS' })
            const contracts = await apiFetch(`/contracts?specialist.uuid=${id}`, {method: 'GET'})

            if (isSubscribed().current && contracts && contracts['hydra:member']) {
                dispatch({type: 'SET_CONTRACTS', payload: contracts})
            }
        },
        createContract: async function(data, notIterate) {
            const newContract = await apiFetch('/contracts', {
                method: 'POST',
                body: JSON.stringify(data),
            })

            if (newContract.uuid && !notIterate) {
                dispatch({type: 'ADD_CONTRACTS', payload: newContract })
            }
            return newContract
        },
        updateContract: async function(contractId, data, forceResponse) {
            const updatedContract = await apiFetch('/contracts/' + contractId, {
                method: 'PUT',
                body: JSON.stringify(data),
            }, forceResponse)

            if (!updatedContract.violations && !updatedContract['hydra:description']) {
                dispatch({type: 'UPDATE_CONTRACTS', payload: updatedContract })
            }
            return updatedContract
        },
        deleteContract: async function (documentId) {
            const deletedContract = await apiFetch('/contracts/' + documentId, {
                method: 'DELETE'
            }, true)
            if (deletedContract && deletedContract.ok) {
                dispatch({type: 'DELETE_CONTRACTS', payload: documentId})
            }
            return deletedContract
        },
    }
}