import React from 'react';
import { Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Grid,
    TextField,
    LinearProgress,
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddCv({ loading, handleCreate, error, open, handleClose, darkState }) {
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="title"
                aria-describedby="description"
            >
                <form noValidate onSubmit={handleCreate} autoComplete="off">
                    <DialogTitle id="title">{"Ajouter un cv"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="description" component='div'>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        color={darkState ? 'secondary' : "primary"}
                                        error={!!error.name}
                                        helperText={error.name}
                                        id="name"
                                        name="name"
                                        label="Nom du CV"
                                        sx={{width: 400, mt: 2}}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color={darkState ? 'secondary' : "primary"}>
                            Annuler
                        </Button>
                        <Button disabled={loading} color={darkState ? 'secondary' : "primary"} type='submit'>
                            Enregistrer
                        </Button>
                    </DialogActions>
                </form>
                {loading && <LinearProgress color={darkState ? 'secondary' : "primary"} />}
            </Dialog>
        </div>
    );
}