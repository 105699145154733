import React, { useState } from 'react'
import {
    Zoom,
    Paper,
    Button,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import DescriptionIcon from '@mui/icons-material/Description';
import Information from './ShowSpecialistListItem/Information';
import Documents from './ShowSpecialistListItem/Documents';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Contracts from './ShowSpecialistListItem/Contracts';
import {isSpecialistManagerOrAdmin, isSpecialistManagerOrAdminOrIntervention} from '../../service/security/hasRoles';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import Cvs from './ShowSpecialistListItem/Cvs';

export default function ShowSpecialist({
    setOpenSnackbar,
    setText,
    setTransition,
    setSeverity,
    darkState,
    zoom,
    user,
    handleCloseBackdrop,
    handleToggle,
    phoneResponsive
}) {
    const [active, setActive] = useState({
        info: true,
        doc: false,
        contract: false,
        cv: false,
    })
    const [specialist, setSpecialist] = useState(false)

    const handleChangeTab = (tab) => {
        switch(tab) {
            case 'info':
                return setActive({info: true, doc: false, contract: false, cv: false})
            case 'doc':
                return setActive({info: false, doc: true, contract: false, cv: false})
            case 'contract':
                return setActive({info: false, doc: false, contract: true, cv: false})
            default:
                return setActive({info: false, doc: false, contract: false, cv: true})
        }
    }


    return (
        <Zoom in={zoom}>
            <div>
                <Paper style={{padding: 15, paddingLeft: 30, marginBottom: 10, marginTop: 0}}>
                    <Button
                        startIcon={<InfoIcon />}
                        onClick={() => handleChangeTab('info')}
                        variant={active.info ? 'contained' : 'text'}
                        color={darkState ? 'secondary': "primary"}
                        style={active.info ? {marginRight: 20, boxShadow: '0 4px 18px -4px rgb(23 4 125 / 65%)'} : {marginRight: 20}}
                    >
                        Informations Générales
                    </Button>
                    {isSpecialistManagerOrAdminOrIntervention() && <Button
                        startIcon={<DescriptionIcon />}
                        onClick={() => handleChangeTab('doc')}
                        variant={active.doc ? 'contained' : 'text'}
                        color={darkState ? 'secondary': "primary"}
                        style={active.doc ? {marginRight: 20, boxShadow: '0 4px 18px -4px rgb(23 4 125 / 65%)'} : {marginRight: 20}}
                    >
                        Documents
                    </Button>}
                    {isSpecialistManagerOrAdmin() && <Button
                        startIcon={<AssignmentIcon />}
                        onClick={() => handleChangeTab('contract')}
                        variant={active.contract ? 'contained' : 'text'}
                        color={darkState ? 'secondary': "primary"}
                        style={active.contract ? {marginRight: 20, boxShadow: '0 4px 18px -4px rgb(23 4 125 / 65%)'} : {marginRight: 20}}
                    >
                        Contrats
                    </Button>}
                    {isSpecialistManagerOrAdmin() && <Button
                        startIcon={<LibraryBooksIcon />}
                        onClick={() => handleChangeTab('cv')}
                        variant={active.cv ? 'contained' : 'text'}
                        color={darkState ? 'secondary': "primary"}
                        style={active.cv ? {marginRight: 20, boxShadow: '0 4px 18px -4px rgb(23 4 125 / 65%)'} : {marginRight: 20}}
                    >
                        CV AO
                    </Button>}
                </Paper>
                {active.info ? <Information
                    specialist={specialist}
                    setSpecialist={setSpecialist}
                    phoneResponsive={phoneResponsive}
                    darkState={darkState}
                    zoom={zoom}
                    user={user}
                    setOpenSnackbar={setOpenSnackbar}
                    setText={setText}
                    setTransition={setTransition}
                    setSeverity={setSeverity}
                /> : 
                active.doc ? <Documents
                    phoneResponsive={phoneResponsive}
                    handleCloseBackdrop={handleCloseBackdrop}
                    handleToggle={handleToggle}
                    setOpenSnackbar={setOpenSnackbar}
                    setText={setText}
                    setTransition={setTransition}
                    setSeverity={setSeverity}
                    darkState={darkState}
                    zoom={zoom}
                    user={user}
                /> :
                active.contract ? <Contracts
                    phoneResponsive={phoneResponsive}
                    handleCloseBackdrop={handleCloseBackdrop}
                    handleToggle={handleToggle}
                    setOpenSnackbar={setOpenSnackbar}
                    setText={setText}
                    setTransition={setTransition}
                    setSeverity={setSeverity}
                    darkState={darkState}
                    zoom={zoom}
                    user={user}
                /> : 
                <Cvs
                    specialist={specialist}
                    phoneResponsive={phoneResponsive}
                    handleCloseBackdrop={handleCloseBackdrop}
                    handleToggle={handleToggle}
                    setOpenSnackbar={setOpenSnackbar}
                    setText={setText}
                    setTransition={setTransition}
                    setSeverity={setSeverity}
                    darkState={darkState}
                    zoom={zoom}
                    user={user}
                />}
            </div>
        </Zoom>
    )
}
