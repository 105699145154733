import React from "react";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const StyledButton = styled(IconButton)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
}));

const StyledDay = styled(PickersDay)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    color:
        theme.palette.mode === 'light'
            ? theme.palette.secondary.dark
            : theme.palette.secondary.light,
}));

export function DateTimePickerComponent({ text, selectedDate, handleDateChange, error, darkState }) {

  return (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'fr'}>
            <DateTimePicker
                error={error}
                value={selectedDate}
                disablePast
                onChange={handleDateChange}
                label={text}
                showTodayButton
                color={darkState ? 'secondary' : 'primary'}
                slotProps={{
                    openPickerButton: { color:darkState ? 'secondary' : 'primary' },
                    textField: {
                        color: darkState ? 'secondary' : 'primary',
                        style: {
                            wdith: 300,
                        }
                    },
                }}
                slots={{
                    openPickerButton: StyledButton,
                    day: StyledDay,
                }}
            />
        </LocalizationProvider>
    );
}

export function DatePickerComponent({ text, selectedDate, handleDateChange, error, color, darkState, customStyle }) {

    return (
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'fr'}>
                <DatePicker
                    label={text}
                    error={!!error}
                    value={selectedDate}
                    onChange={handleDateChange}
                    animateYearScrolling
                    sx={{width: 300}}
                    slotProps={{
                        openPickerButton: { color:darkState ? 'secondary' : 'primary' },
                        textField: {
                            color: darkState ? 'secondary' : 'primary',
                            style: {
                                wdith: 300,
                            }
                        },
                    }}
                    slots={{
                        openPickerButton: StyledButton,
                        day: StyledDay,
                    }}
                    format="DD MMMM YYYY"
                    color={darkState ? 'secondary' : 'primary'}
                />
            </LocalizationProvider>
    );
  }
