import React from 'react';
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    LinearProgress,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText
} from '@mui/material';
import { DatePickerComponent } from '../../../../components/DateTimePickerComponent';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddContract({
    error,
    handleChangeContractTypes,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    setName,
    darkState,
    loading,
    open,
    handleClose,
    handleAddContract,
    contractTypes,
    contractType,
}) {

    const handleStartDateChange = (e) => {
        setStartDate(e)
    }

    const handleEndDateChange = (e) => {
        setEndDate(e)
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" TransitionComponent={Transition} keepMounted>
                <DialogTitle id="form-dialog-title">Ajouter un contrat</DialogTitle>
                <DialogContent style={{maxWidth: 500}} >
                    <TextField
                        autoFocus
                        id="name"
                        label="Nom du contrat"
                        color={darkState ? 'secondary' : 'primary'}
                        onChange={(e) => setName(e.target.value)}
                        error={!!error.name}
                        helperText={error.name}
                    />
                    <FormControl color={darkState ? 'secondary' : 'primary'} error={!!error.contractType}>
                        <InputLabel id="select-error-label">Type de contrat</InputLabel>
                        <Select
                            labelId="select-error-label"
                            id="select-error"
                            value={contractType}
                            onChange={handleChangeContractTypes}
                        >
                            <MenuItem value="">
                              <em>Selectionnez un type de contrat</em>
                            </MenuItem>
                            {contractTypes && contractTypes.map((contractType) => (
                                <MenuItem key={contractType.id} value={contractType['@id']}>{contractType.name}</MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{error.contractType}</FormHelperText>
                    </FormControl>
                    <DatePickerComponent
                        darkState={darkState}
                        error={error.startDate}
                        color={!darkState && 'primary' }
                        handleDateChange={handleStartDateChange}
                        selectedDate={startDate}
                        text="Date de début *" 
                    /><br />
                    <DatePickerComponent
                        darkState={darkState}
                        error={error.endDate}
                        color={!darkState && 'primary' }
                        handleDateChange={handleEndDateChange}
                        selectedDate={endDate}
                        text="Date de fin *"
                    /><br />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color={darkState ? 'secondary' : 'primary'}>
                        Annuler
                    </Button>
                    <Button disabled={loading} onClick={handleAddContract} color={darkState ? 'secondary' : 'primary'}>
                        Ajouter
                    </Button>
                </DialogActions>
            </Dialog>
            {loading && <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
        </>
    );
}
