import { ADMIN, SUPER_ADMIN, USER, INTERVENTION_MANAGER, SPECIALIST_MANAGER } from "../../constantes/mainConstantes";
import { isAuth } from "./isAuth";

export function hasRoles() {
    if(isAuth() && getRole()) {
        if (getRole()[0] === USER) {
            return 'user'
        } else if (getRole()[0] === ADMIN || getRole()[0] === SUPER_ADMIN) {
            return 'admin'
        } else if (getRole()[0] === INTERVENTION_MANAGER) {
            return 'intervention_manager'
        } else if (getRole()[0] === SPECIALIST_MANAGER) {
            return 'specialist_manager'
        }
        return null
    }
}

export function isSpecialistManagerOrAdmin() {
    if (isAuth() && getRole()) {
        return getRole()[0] === ADMIN || getRole()[0] === SUPER_ADMIN || getRole()[0] === SPECIALIST_MANAGER
    }
}

export function isSpecialistManagerOrAdminOrIntervention() {
    if (isAuth() && getRole()) {
        return getRole()[0] === ADMIN || getRole()[0] === SUPER_ADMIN || getRole()[0] === SPECIALIST_MANAGER || getRole()[0] === INTERVENTION_MANAGER
    }
}

export function isSpecialistOrInterventionManager() {
    if (isAuth() && getRole()) {
        return getRole()[0] === INTERVENTION_MANAGER || getRole()[0] === SPECIALIST_MANAGER
    }
}

function getRole() {
    return JSON.parse(localStorage.getItem('REACT_ROLE'))
}