import React, { useState } from 'react';
import { Zoom, createTheme, ThemeProvider, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Toolbar, Typography, Paper } from '@mui/material';
import moment from 'moment'
import * as locales from '@mui/material/locale';
import 'moment/locale/fr'
import { getRightColor } from '../../helpers/utils';

moment.locale('fr')

const headCells = [
    { id: 'name', numeric: false, disablePadding: false, label: 'Nom' },
    { id: 'type', numeric: true, disablePadding: false, label: 'Type de document' },
    { id: 'expiredAt', numeric: true, disablePadding: false, label: 'Date d\'expiration' },
    { id: 'specialist', numeric: true, disablePadding: false, label: 'Nom, prénom du spécialiste' },
    { id: 'status', numeric: true, disablePadding: false, label: 'Statut' },
];

function EnhancedTableHead() {

  return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                    >
                        <TableSortLabel>
                            {headCell.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const EnhancedTableToolbar = ({ darkState }) => {

    return (
        <Toolbar>
            <Typography color={darkState ? 'secondary' : 'primary'} variant="h6" id="tableTitle" component="div">
                Documents
            </Typography>
        </Toolbar>
    );
};

export default function Documents({ data, darkState, zoom }) {
    const [page, setPage] = useState(0);
    const [locale] = useState('frFR');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    return (
        <Zoom in={zoom} style={{ transitionDelay: '1000ms' }}>
            <Paper>
                <EnhancedTableToolbar darkState={darkState} />
                <TableContainer>
                    <Table
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead />
                        <TableBody>
                            {data
                                .slice(page * 5, page * 5 + 5)
                                .map((row, index) => {
                                    const labelId = `enhanced-table-${index}`;
                                    
                                    return (
                                    <TableRow
                                        hover
                                        tabIndex={-1}
                                        key={row.id}
                                    >
                                        <TableCell component="th" id={labelId} scope="row" padding="normal">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{row.documentType.name}</TableCell>
                                        <TableCell align="right">{row.expiredAtText}</TableCell>
                                        <TableCell align="right"><a href={`/specialists/${row.specialist.uuid}`}  style={{textDecoration: 'none', color: darkState ? 'white' : "black"}}>{row.specialist.lastName} {row.specialist.firstName}</a></TableCell>
                                        <TableCell align="right">
                                            <div style={{float: 'right', backgroundColor: row.status && getRightColor(row.status), color: "white", maxWidth: 160, padding: 10, borderRadius: 4, textAlign: 'center', boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)'}}>
                                                {row.status}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {data.length === 0 && (
                                <TableRow>
                                    <TableCell colSpan={6} >Pas de documents pour le moment...</TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
                    <TablePagination
                        labelRowsPerPage=''
                        rowsPerPageOptions={[]}
                        component="div"
                        count={data.length}
                        rowsPerPage={5}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                </ThemeProvider>
            </Paper>
        </Zoom>
    );
}