import React from 'react'
import { Navigate } from 'react-router-dom'
import { hasRoles } from '../security/hasRoles'


export const DashboardRoute = ({ children }) => {
    
    if (hasRoles() === 'specialist_manager' || hasRoles() === 'intervention_manager' || hasRoles() === 'admin') {
        return children
    }
    return <Navigate to='/specialists' />
}