import React from 'react'
import {
    Paper,
    Zoom,
    Typography,
    Toolbar
} from '@mui/material';
import moment from 'moment'

export default function Interventions({ specialist, zoom, darkState }) {
    return (
        <Zoom in={zoom} style={{ transitionDelay: '500ms' }}>
            <Paper
                sx={{
                    padding: '0px 0px 10px 0px',
                    boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)',
                    mt: 3.75
                }}
            >
                <Toolbar>
                    <Typography variant='h6' color={darkState ? 'secondary' : 'primary'} style={{marginLeft: -10, marginBottom: 10, flex: '1 1'}}>
                        <span style={{fontWeight: 'bold', marginLeft: 20, marginRight: 10}}>Intervention(s)</span>
                    </Typography>
                    <Typography variant='body1' style={{color: '#FFB400'}}>
                        <span style={{fontWeight: 'bold', marginLeft: 20, marginRight: 10}}>
                            Note : </span>{Boolean(specialist.interventionRating) ? `${specialist.interventionRating} / 5` : 'Non noté'}
                    </Typography>
                </Toolbar>
                
                {specialist.interventions.length ? specialist.interventions.map((intervention) => {
                    return <React.Fragment key={intervention.id}>
                        <Typography style={{marginLeft: 50}}>• {intervention.company.name} :</Typography>
                        <Typography style={{marginLeft: 80}}>
                            <span 
                                style={{
                                    color: darkState ? '#7fe1dd' : '#12047D',
                                    fontWeight: 'bold',
                                    marginLeft: 20,
                                    marginRight: 10
                                }}
                            >
                                Adresse :</span> {intervention.address.address} à {intervention.address.city}, {intervention.address.zipCode}
                        </Typography>
                        <Typography style={{marginLeft: 80}}>
                            <span 
                                style={{
                                    color: darkState ? '#7fe1dd' : '#12047D',
                                    fontWeight: 'bold',
                                    marginLeft: 20,
                                    marginRight: 10
                                }}
                            >
                                Date de début :</span> {moment(intervention.startDate).format('DD-MM-YYYY')}
                        </Typography>
                        {intervention.endDate && <Typography style={{marginLeft: 80}}>
                            <span 
                                style={{
                                    color: darkState ? '#7fe1dd' : '#12047D',
                                    fontWeight: 'bold',
                                    marginLeft: 20,
                                    marginRight: 10
                                }}
                            >
                                Date de fin :</span> {moment(intervention.endDate).format('DD-MM-YYYY')}
                        </Typography>}
                    </React.Fragment>
                }) : 
                <Typography style={{marginLeft: 50}}>Pas d'intervention...</Typography>}
            </Paper>
        </Zoom>
    )
}