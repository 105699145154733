import React, { useState } from 'react';
import {
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    InputAdornment,
    Tooltip,
    Zoom,
    Typography
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { handleFormError } from '../../service/security/formError';
import { TransitionLeft } from '../../helpers/utils';
import { apiFetch } from '../../service/apiFetch';
import UploadLogo from '../../components/UploadLogo';

const filter = createFilterOptions();

export default function AutoCompleteCompany({
    phoneResponsive,
    customStyle,
    handleFileChanged,
    setLoading,
    companies,
    company,
    setCompany,
    createCompany,
    darkState,
    setOpenSnackbar,
    setText,
    setTransition,
    setSeverity 
}) {
    const [open, toggleOpen] = useState(false);
    const [error, setError] = useState({});
    const [disable, setDisable] = useState(false);
    const [photo, setPhoto] = useState(false);
    const options = companies.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option,
        };
    });

    const handleClose = () => {
        setDialogValue({
            name: '',
            nbEmployee: '',
        });

        toggleOpen(false);
    };

    const [dialogValue, setDialogValue] = useState({
        name: '',
        nbEmployee: '',
    });

    const handleSubmitCompany = async (event) => {
        event.preventDefault();
        setError({})
        setDisable(true)

        let createPhoto = null
        if (photo) {
            let dataPhoto = new FormData() 
            dataPhoto.append('file', photo && photo[0])
            createPhoto = await apiFetch('/logo_objects', {
                method: 'POST',
                body: dataPhoto
            }, true)
        }

        const createdCompany = await createCompany({
            name: dialogValue.name,
            nbEmployee: parseInt(dialogValue.nbEmployee),
            logo: createPhoto && createPhoto['@id'],
        })

        if (createdCompany && createdCompany.uuid) {
            setText('L\'entreprise a été crée')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setCompany({
                name: dialogValue.name,
                nbEmployee: parseInt(dialogValue.nbEmployee),
                logo: createdCompany.logo,
                '@id': createdCompany['@id'],
            });
            handleFileChanged && handleFileChanged(createdCompany)
            handleClose();
        } else if (createdCompany.violations) {
            const errorField = handleFormError(createdCompany, ['name', 'nbEmployee'])
            setError(errorField)
            setText('Erreur lors de la création')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        } else {
            setText((createdCompany && createdCompany['hydra:description']) || 'Erreur lors de la création')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setDisable(false)
    };

    return (
        <React.Fragment>
            <Autocomplete
                value={company}
                groupBy={(option) => option.firstLetter}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                        toggleOpen(true);
                        setDialogValue({
                            name: newValue,
                            nbEmployee: '',
                        });
                    });
                    } else if (newValue && newValue.inputValue) {
                        toggleOpen(true);
                        setDialogValue({
                            name: newValue.inputValue,
                            nbEmployee: '',
                        });
                    } else {
                        setCompany(newValue);
                        handleFileChanged && handleFileChanged(newValue)
                    }
                    setLoading && setLoading(false)
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            name: `Ajouter l'entreprise : "${params.inputValue}"`,
                        });
                    }
              
                    return filtered;
                }}
                id="autocomplete-company"
                options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    return option.name;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option) => (
                    <li {...props} key={option['@id']}>
                        <Typography
                            color={darkState ? 'secondary' : 'primary'}
                        >
                            {option.name}
                        </Typography>
                    </li>
                )}
                style={phoneResponsive ? {
                    marginBottom: 30,
                    width: 190,
                    marginTop: 30,
                } : {
                    marginRight: 50,
                    marginTop: 30,
                    width: 400, 
                }}
                freeSolo
                renderInput={(params) => (
                    <TextField 
                        {...params} 
                        label="Nom de l'entreprise *"
                        color={darkState ? 'secondary' : "primary"}
                        style={customStyle ? {width: 300} : {}}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <>
                                    {!company || !company.name ? <Tooltip TransitionComponent={Zoom} title='Ajouter un entreprise'>
                                    <InputAdornment position="end" style={{cursor: 'pointer'}} onClick={() => toggleOpen(true)}>
                                        <AddIcon />
                                    </InputAdornment>
                                    </Tooltip>
                                    :
                                    <InputAdornment position="end" style={{cursor: 'pointer'}} onClick={() => setCompany({ name: '', nbEmployee: ''})}>
                                        <CloseIcon />
                                    </InputAdornment>}
                                </>
                            )
                        }}
                    />
                )}
            />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="dialog-title"
                style={phoneResponsive ? {maxWidth: 320} : {}}
            >
                    <DialogTitle id="dialog-title">Ajouter une nouvelle entreprise</DialogTitle>
                    <DialogContent style={phoneResponsive ? {overflow: 'hidden'} : {}} sx={{overflowX: 'hidden'}}>
                        <DialogContentText sx={{mb: 2}}>
                            Pour ajouter une entreprise, vous devez renseigner au minimum le nom.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            id="name"
                            value={dialogValue.name}
                            onChange={(event) => setDialogValue({ ...dialogValue, name: event.target.value })}
                            label="Nom de l'entreprise"
                            type="text"
                            style={phoneResponsive ? {} : {marginRight: 100}}
                            error={!!error.name}
                            helperText={error.name}
                            color={darkState ? 'secondary' : 'primary'}
                        />
                        <TextField
                            id="nbEmployee"
                            value={dialogValue.nbEmployee}
                            onChange={(event) => setDialogValue({ ...dialogValue, nbEmployee: event.target.value })}
                            label="Nombre d'employés"
                            type="number"
                            error={!!error.nbEmployee}
                            helperText={error.nbEmployee}
                            style={{marginBottom: 30}}
                        />
                        <UploadLogo setPhoto={setPhoto} title={'Ajouter le logo de l\'entreprise'} id='logo' />
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={disable} onClick={handleClose} color={darkState ? 'secondary' : 'primary'}>
                            Annuler
                        </Button>
                        <Button onClick={handleSubmitCompany} color={darkState ? 'secondary' : 'primary'}>
                            Ajouter
                        </Button>
                    </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}