import React from 'react'
import { Navigate } from 'react-router-dom'
import {hasRoles} from '../security/hasRoles'


export const AdminRoute = ({ children }) => {

    if (hasRoles() === 'admin') {
        return children
    }
    <Navigate to='/specialists' />
}