import React from 'react'
import { Paper, Zoom, Typography, Divider, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function InterventionSelected({ 
    phoneResponsive,
    loading,
    zoom,
    darkState,
    selectedSpecialists,
    handleRemoveSelected,
    handleSubmitInterventionRequest 
}) {
    return (
        <Zoom in={zoom} style={{ transitionDelay: '600ms' }}>
            <Paper
                style={phoneResponsive ? {
                    marginTop: 30,
                    textAlign: 'center',
                    padding: 10
                    } : {
                        padding: 20,
                        marginTop: 30,
                        textAlign: 'center',
                        marginBottom: 30
                    }
                }
            >
                <Typography variant='h6' color={darkState ? 'secondary' : 'primary'} style={{ textAlign: 'left'}}>
                    Liste des Psys sélectionnés : 
                </Typography>
                <div style={{ maxHeight: 220, overflowY: 'scroll', textAlign: 'left'}}>
                {selectedSpecialists && !!selectedSpecialists.length ? selectedSpecialists.map((selectedSpecialist) => (
                    <div key={selectedSpecialist.id} style={{marginLeft: 15, marginTop: 10, marginBottom: 20, display: 'flex'}}>
                        <Typography>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    fontStyle: 'italic',
                                    color: darkState ? '#7fe1dd' : '#12047D'
                                }}
                            >
                                Spécialiste : </span>{selectedSpecialist.firstName} {selectedSpecialist.lastName}
                        </Typography>
                        <CloseIcon 
                            onClick={() => handleRemoveSelected(selectedSpecialist)}
                            style={{cursor: 'pointer', marginLeft: 'auto', marginRight: 5}}
                        />
                        <Divider />
                    </div>
                )) : 
                <Typography style={{marginTop: 20, marginBottom: 20}}>
                    Pas de spécialistes sélectionnés pour le moment...
                </Typography>}
                </div>
                <Button
                    variant='contained'
                    color={darkState ? 'secondary' : 'primary'}
                    disabled={!selectedSpecialists.length || loading}
                    onClick={handleSubmitInterventionRequest}
                >
                    Envoyer le(s) SMS
                </Button>
            </Paper>
        </Zoom>
    )
}