import React from 'react'
import { Grid, Zoom, Typography, Avatar } from '@mui/material';
import moment from "moment"

export default function InterventionInformation({ phoneResponsive, zoom, intervention, handleChange }) {

    return (
        <Zoom in={zoom} style={{ transitionDelay: '200ms' }}>
            <form onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} noValidate autoComplete="off" onChange={handleChange}>
                <Grid container spacing={3} style={phoneResponsive ? {marginLeft: 5, marginTop: 20} : {marginTop: 20, marginLeft: 15}}>
                    <Grid item xs={12} sm={6}>
                        <Grid container spacing={3} >
                            <Grid item xs={6} sm={2}>
                                <Avatar
                                    variant='rounded'
                                    src={`${intervention.company.logo && intervention.company.logo.contentUrl}`}
                                    aria-label="intervention"
                                    sx={{
                                        width: 75,
                                        height: 75,
                                    }}
                                >
                                    {intervention.company.name.charAt(0)}
                                </Avatar>
                            </Grid>
                            <Grid item xs={6} sm={5}>
                                <Typography
                                    variant='body2'
                                    style={
                                        phoneResponsive ? {
                                            maxWidth: '87px',
                                            fontSize: 15,
                                            marginTop: 10,
                                            marginLeft: -20,
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap'
                                        } : {
                                            marginTop: 10,
                                            marginLeft: -20
                                        }
                                    }
                                >
                                    {intervention.company.name}
                                </Typography>
                                <Typography
                                    variant='body2'
                                    style={
                                        phoneResponsive ? {
                                            maxWidth: '87px',
                                            fontSize: 15,
                                            marginTop: 5,
                                            marginLeft: -20,
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap'
                                        } : {
                                            marginTop: 10,
                                            marginLeft: -20
                                        }
                                    }
                                >
                                    {intervention.company.nbEmployee} employés
                                </Typography>
                            </Grid>
                        </Grid>
                        {intervention.caseNumber && <Typography style={{marginTop: 20}}>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    color: '#7fe1dd',
                                }}
                            >
                                N° de dossier : 
                            </span> {intervention.caseNumber}
                        </Typography>}
                        {intervention.comment && <Typography style={{marginTop: 20}}>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    color: '#7fe1dd',
                                }}
                            >
                                Commentaire : </span>{intervention.comment}
                        </Typography>}
                        {intervention.status === 'Canceled' && <Typography style={{marginTop: 20}}>
                            <span style={{fontWeight: 'bold'}}>Intervention annulé !</span>
                        </Typography>}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography style={phoneResponsive ? {fontSize: 14, maxWidth: 200} : {}}>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    color: '#7fe1dd',
                                }}
                            >
                                Date de début potentielle: </span>
                            {intervention.availableDates.map((availableDate) => {
                                if (intervention.availableDates[intervention.availableDates.length - 1] !== availableDate) {
                                    return ` ${moment(availableDate).format('DD MMMM YYYY')} / `
                                }
                                return moment(availableDate).format('DD MMMM YYYY')
                            })}
                        </Typography>
                        {intervention.selectedDate && <Typography style={phoneResponsive ? {fontSize: 14, maxWidth: 200} : {}}>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    color: '#7fe1dd',
                                }}
                            >
                                Date sélectionnée : 
                            </span> {moment(intervention.selectedDate).format('DD MMMM YYYY')}
                        </Typography>}
                        <Typography style={phoneResponsive ? {fontSize: 14, maxWidth: 200} : {}}>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    color: '#7fe1dd',
                                }}
                            >
                                Adresse : 
                            </span> {intervention.address.address}, {intervention.address.city} ({intervention.address.zipCode})
                        </Typography>
                        <Typography style={phoneResponsive ? {fontSize: 14, maxWidth: 200} : {}}>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    color: '#7fe1dd',
                                }}
                            >
                                Type d'intervention : 
                            </span> {intervention.interventionType.name}
                        </Typography>
                        <Typography style={phoneResponsive ? {fontSize: 14, maxWidth: 200} : {}}>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    color: '#7fe1dd',
                                }}
                            >
                                Rémunération : 
                            </span> {intervention.remuneration ? `${intervention.remuneration} €` : 'Non renseigné'}
                        </Typography>
                        <Typography style={phoneResponsive ? {fontSize: 14, maxWidth: 200} : {}}>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    color: '#7fe1dd',
                                }}
                            >
                                Heure de début : 
                            </span> {intervention.startTime}
                        </Typography>
                        <Typography style={phoneResponsive ? {fontSize: 14, maxWidth: 200} : {}}>
                            <span
                                style={{
                                    fontWeight: 'bold',
                                    color: '#7fe1dd',
                                }}
                            >
                                Heure de fin : 
                            </span> {intervention.endTime}
                        </Typography>
                    </Grid>
                </Grid>
                <br />
            </form>
        </Zoom>
    )
}