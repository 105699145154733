import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function MultipleCheckBoxes({ phoneResponsive, allOptions, label, placeholder, id, setData, defaultValue, darkState, data, error }) {
    const handleChange = (e, newValue) => {
        setData(newValue)
    }

    return (
        <Autocomplete
            multiple
            id={id}
            value={data}
            defaultValue={defaultValue}
            options={allOptions}
            onChange={handleChange}
            disableCloseOnSelect
            isOptionEqualToValue={(option, value) => {
                return option.name === value.name
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        color={darkState ? 'secondary' : 'primary'}
                    />
                    {option.name}
                </li>
            )}
            style={phoneResponsive ? { width: 180, marginBottom: 20 } : { width: 400, marginBottom: 40 }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    color={darkState ? 'secondary' : "primary"}
                    label={label}
                    placeholder={placeholder}
                    error={error && !!error[id]}
                    helperText={error && error[id]}
                />
            )}
        />
    );
}