import React, { useState } from 'react'
import {
    Paper,
    Zoom,
    Typography,
    Collapse,
    IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function Experiences({ specialist, zoom, darkState }) {
    const [expanded, setExpanded] = useState(false);
    
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Zoom in={zoom} style={{ transitionDelay: '300ms' }}>
            <Paper
                sx={{
                    padding: 1.25,
                    boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)',
                    mt: 3.75
                }}
            >
                <Typography variant='h6' color={darkState ? 'secondary' : 'primary'} style={{fontWeight: 'bold', marginBottom: 10}}>
                    <span>Expérience(s) professionnelle(s)</span>
                </Typography>
                {specialist.experiences.length ? specialist.experiences.map((experience, index) => {
                    if (index < 3) {
                        return <React.Fragment key={experience.id}>
                            <Typography style={{marginLeft: 50}}>• {experience.experience.name} :</Typography>
                            <Typography style={{marginLeft: 80}}>
                                <span style={{color: darkState ? '#7fe1dd' : '#12047D'}}>Description : </span>{experience.description}
                            </Typography>
                        </React.Fragment>
                    }
                    return null
                })
                : 
                <Typography style={{marginLeft: 50}}>Pas encore d'expériences...</Typography>}
                {specialist.experiences.length > 3 && <Collapse in={expanded} timeout="auto" unmountOnExit>
                    {specialist.experiences.length && specialist.experiences.map((experience, index) => {
                        if (index > 2) {
                            return <React.Fragment key={experience.id}>
                                <Typography style={{marginLeft: 50}}>• {experience.experience.name} :</Typography>
                                <Typography style={{marginLeft: 80}}>
                                    <span style={{color: darkState ? '#7fe1dd' : '#12047D'}}>Description : </span>{experience.description}
                                </Typography>
                            </React.Fragment>
                        }
                        return null
                    })}
                </Collapse>}
                {specialist.experiences.length > 3 && <IconButton
                    onClick={handleExpandClick}
                    color='inherit'
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    {!expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>}
            </Paper>
        </Zoom>
    )
}