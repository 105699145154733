import { useReducer } from "react"
import { apiFetch } from "../service/apiFetch"

const initialState = []

const FETCHING_CERTIFICATIONS = 'FETCHING_CERTIFICATIONS'
const SET_CERTIFICATIONS = 'SET_CERTIFICATIONS'
const ADD_CERTIFICATIONS = 'ADD_CERTIFICATIONS'
const UPDATE_CERTIFICATIONS = 'UPDATE_CERTIFICATIONS'
const DELETE_CERTIFICATIONS = 'DELETE_CERTIFICATIONS'

export function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCHING_CERTIFICATIONS:
            return { ...state, loading: true }
        case SET_CERTIFICATIONS:
            return {...state, certifications: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_CERTIFICATIONS:
            return {...state, certifications: [action.payload, ...state.certifications]}
        case UPDATE_CERTIFICATIONS:
            return {...state, certifications: action.payload }
        case DELETE_CERTIFICATIONS:
            return {...state, certifications: state.certifications.filter((i) => {
                return i.uuid !== action.payload
            })}
        default:
            return state
    }
}

export function useCertifications() {
    const [state, dispatch] = useReducer(reducer, {    
        certifications: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        certifications: state.certifications,
        totalItems: state.totalItems,
        view: state.view,
        fetchCertifications: async function (route, isSubscribed = () => ({current: true})) {
            if ((state.certifications || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_CERTIFICATIONS' })
            const certifications = await apiFetch(route || '/certifications', {method: 'GET'})

            if (isSubscribed().current && certifications && certifications['hydra:member']) {
                dispatch({type: 'SET_CERTIFICATIONS', payload: certifications})
            }
        },
        createCertification: async function(data, notIterate) {
            const newCertification = await apiFetch('/certifications', {
                method: 'POST',
                body: JSON.stringify(data),
            })

            if (newCertification.uuid && !notIterate) {
                dispatch({type: 'ADD_CERTIFICATIONS', payload: newCertification })
            }
            return newCertification
        },
        updateCertification: async function(interventionId, data, forceResponse) {
            const updatedCertification = await apiFetch('/certifications/' + interventionId, {
                method: 'PUT',
                body: JSON.stringify(data),
            }, forceResponse)

            if (!updatedCertification.violations && !updatedCertification['hydra:description']) {
                dispatch({type: 'UPDATE_CERTIFICATIONS', payload: updatedCertification })
            }
            return updatedCertification
        },
        deleteCertification: async function (interventionId) {
            const deletedCertification = await apiFetch('/certifications/' + interventionId, {
                method: 'DELETE'
            }, true)
            if (deletedCertification && deletedCertification.ok) {
                dispatch({type: 'DELETE_CERTIFICATIONS', payload: interventionId})
            }
            return deletedCertification
        },
    }
}