import { useReducer } from "react"
import { apiFetch } from "../service/apiFetch"

const initialState = []

const FETCHING_COMPANIES = 'FETCHING_COMPANIES'
const SET_COMPANIES = 'SET_COMPANIES'
const ADD_COMPANIES = 'ADD_COMPANIES'
const UPDATE_COMPANIES = 'UPDATE_COMPANIES'
const DELETE_COMPANIES = 'DELETE_COMPANIES'

export function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCHING_COMPANIES:
            return { ...state, loading: true }
        case SET_COMPANIES:
            return {...state, companies: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_COMPANIES:
            return {...state, companies: [action.payload, ...state.companies]}
        case UPDATE_COMPANIES:
            return {...state, companies: action.payload }
        case DELETE_COMPANIES:
            return {...state, companies: state.companies.filter((i) => {
                return i.uuid !== action.payload
            })}
        default:
            return state
    }
}

export function useCompanies() {
    const [state, dispatch] = useReducer(reducer, {    
        companies: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        companies: state.companies,
        totalItems: state.totalItems,
        view: state.view,
        fetchCompanies: async function (route, isSubscribed = () => ({current: true})) {
            if ((state.companies || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_COMPANIES' })
            const companies = await apiFetch(route || '/companies', {method: 'GET'})

            if (isSubscribed().current && companies && companies['hydra:member']) {
                dispatch({type: 'SET_COMPANIES', payload: companies})
            }
            return companies
        },
        createCompany: async function(data, notIterate) {
            const newCompany = await apiFetch('/companies', {
                method: 'POST',
                body: JSON.stringify(data),
            })

            if (newCompany.uuid && !notIterate) {
                dispatch({type: 'ADD_COMPANIES', payload: newCompany })
            }
            return newCompany
        },
        updateCompany: async function(companyId, data, forceResponse) {
            const updatedCompany = await apiFetch('/companies/' + companyId, {
                method: 'PUT',
                body: JSON.stringify(data),
            }, forceResponse)

            if (!updatedCompany.violations && !updatedCompany['hydra:description']) {
                dispatch({type: 'UPDATE_COMPANIES', payload: updatedCompany })
            }
            return updatedCompany
        },
        deleteCompany: async function (companyId) {
            const deletedCompany = await apiFetch('/companies/' + companyId, {
                method: 'DELETE'
            }, true)
            if (deletedCompany && deletedCompany.ok) {
                dispatch({type: 'DELETE_COMPANIES', payload: companyId})
            }
            return deletedCompany
        },
    }
}