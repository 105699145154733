import React from 'react';
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    FilledInput,
    Checkbox,
    ListItemText,
    ListSubheader
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 50;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function CsvPopup({ open, handleClose, darkState, handleAccept, csvName, setCsvName, setCsvSpecialistType, csvSpecialistType}) {

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setCsvName('')
        setCsvSpecialistType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

  return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="export-popup-title"
            aria-describedby="export-popup-description"
        >
            <DialogTitle id="export-popup-title">{"Exporter des spécialistes"}</DialogTitle>
            <DialogContent>
                <FormControl color={darkState ? 'secondary' : 'primary'} style={{ marginLeft: 10, width: 290, marginBottom: 20 }}>
                    <InputLabel id="multiple-checkbox-label">Type de spécialiste</InputLabel>
                    <Select
                        labelId="multiple-checkbox-label"
                        id="multiple-checkbox"
                        multiple
                        value={csvSpecialistType}
                        onChange={handleChange}
                        input={<FilledInput style={{marginLeft: -10, marginTop: 3}} label="Type de spécialiste" />}
                        renderValue={(selected) => selected.join(', ')}
                        MenuProps={MenuProps}
                    >
                        <ListSubheader>Type de spécialiste</ListSubheader>
                        {[{id: 'prosconsulte', value: 'Pros Consulte'}, {id: 'geopsy', value: 'Geo Psy'}, {id: 'prestataire', value: 'Prestataire'}].map((type) => (
                            <MenuItem key={type.id} value={type.id}>
                                <Checkbox checked={csvSpecialistType.indexOf(type.id) > -1} />
                                <ListItemText primary={type.value} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl><br />
                {csvSpecialistType && csvSpecialistType.length === 0 && <FormControl color={darkState ? 'secondary' : 'primary'} style={{width: 300, marginBottom: 20}}>
                    <InputLabel id="select-label">Type d'export</InputLabel>
                    <Select
                        labelId="select-label"
                        id="select"
                        value={csvName}
                        onChange={(e) => setCsvName(e.target.value)}
                    >
                        <MenuItem value="">
                            <em>Selectionnez un document</em>
                        </MenuItem>
                        <ListSubheader>Nom du document</ListSubheader>
                            <MenuItem value={' '}>Tous</MenuItem>
                            <MenuItem value={'kbis'}>KBIS</MenuItem>
                            <MenuItem value={'urssaf'}>Attestation URSSAF</MenuItem>
                            <MenuItem value={'contrat'}>Contrat</MenuItem>
                            <MenuItem value={'iprp'}>IPRP</MenuItem>
                            <MenuItem value={'diplome'}>Diplôme</MenuItem>
                            <MenuItem value={'certification-formation'}>Certification/Formation</MenuItem>
                    </Select>
                </FormControl>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={darkState ? 'secondary' : 'primary'}>
                    Annuler
                </Button>
                <Button onClick={handleAccept} color={darkState ? 'secondary' : 'primary'}>
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
  );
}