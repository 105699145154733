import React, { useEffect, useRef, useState } from 'react'
import { useInterventions } from '../hooks/interventions'
import { 
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Paper,
    createTheme,
    ThemeProvider,
    Menu,
    MenuItem,
    IconButton,
    Zoom,
    LinearProgress,
    TextField,
} from '@mui/material';
import * as locales from '@mui/material/locale';
import EnhancedTableHead from './InterventionsItem/EnhancedTableHead';
import { EnhancedTableToolbar } from './InterventionsItem/EnhancedTableToolbar';
import moment from 'moment'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import 'moment/locale/fr'
import { useNavigate } from 'react-router-dom';
import { TransitionLeft, translateStatus } from '../helpers/utils';
import {hasRoles} from '../service/security/hasRoles';
import DeletedConfirmationPopup from '../components/DeletedConfirmationPopup';
import CancelIcon from '@mui/icons-material/Cancel';
moment.locale('fr')

let debounce = null

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function Interventions({ zoom, darkState, setOpenSnackbar, setText, setTransition, setSeverity }) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('company');
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [openMenu, setOpenMenu] = useState(false);
    const [openDeletePopup, setOpenDeletePopup] = useState(false);
    const [canceled, setCanceled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [locale] = useState('frFR');
    const mounted = useRef(false);
    let navigate = useNavigate()
    document.title = 'Codex - Demande d\'intervention'

    const {
        interventions,
        totalItems,
        view,
        fetchInterventions,
        deleteIntervention,
        updateIntervention,
    } = useInterventions()
    
    useEffect(() => {
        (async () => {
            mounted.current = true
            await fetchInterventions(null, () => mounted)
        })()

        return () => mounted.current = false
    }, [fetchInterventions, interventions])

    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
  
    const handleChangePage = async (event, newPage) => {
      setLoading(true)
      if (newPage > page) {
        await fetchInterventions(view['hydra:next'])
      } else if (newPage < page) {
        await fetchInterventions(view['hydra:previous'])
      }
      setLoading(false)
      setPage(newPage);
    };
  
    const handleClick = (event, row) => {
        setSelected(row);
        setOpenMenu(event.currentTarget);
    };
    
    const handleCloseAction = () => {
        setOpenMenu(null);
    };

    const handleNewIntervention = () => {
        navigate('/interventions/new')
    }

    const handleEditIntervention = (uuid) => {
        navigate(`/interventions/${selected.uuid || uuid}/edit`)
    }

    const handleOpenDeletePopup = (e, mayCanceled) => {
      setCanceled(mayCanceled)
      setOpenDeletePopup(!openDeletePopup)
    }

    const handleDelete = async () => {
        setOpenMenu(false)
        setOpenDeletePopup(false)
        const deletedIntervention = await deleteIntervention(selected.uuid)

        if (deletedIntervention && deletedIntervention.ok) {
            setText('L\'intervention a été supprimé')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
        } else {
            setText((deletedIntervention && deletedIntervention['hydra:description']) || 'Erreur lors de la suppression')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
    }

    const handleCancel = async () => {
        setOpenMenu(false)
        setOpenDeletePopup(false)
        const canceledIntervention = await updateIntervention(selected.uuid, {status: 'Canceled'})

        if (canceledIntervention && canceledIntervention.uuid) {
            setText('L\'intervention a été annulé')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
        } else {
            setText((canceledIntervention && canceledIntervention['hydra:description']) || 'Erreur lors de l\'annulation')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
    }

    const handleSearch = async (e) => {
      clearTimeout(debounce)
      if (e.target.value.length > 1) {
          debounce = setTimeout(async () => {
              await fetchInterventions(`/interventions?order[startDate]&company.name=${e.target.value}`)
          }, 500)
      }
      if (e.target.value.length === 0) {
          await fetchInterventions('/interventions?order[startDate]')
      }
  }

    return (
      <Zoom in={zoom} style={{ transitionDelay: '500ms' }}>
      <div>
        {interventions && <Paper>
          <EnhancedTableToolbar
            handleSearch={handleSearch}
            darkState={darkState}
            numSelected={selected.length}
            handleNewIntervention={handleNewIntervention}
          />
          <TextField 
            label="Nom de l'entreprise"
            onChange={handleSearch}
            color={darkState ? 'secondary' : 'primary'}
            style={{marginLeft: 16, marginBottom: 10, width: 250}}
          />
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={interventions.length}
                darkState={darkState}
              />
              <TableBody>
                {stableSort(interventions, getComparator(order, orderBy))
                  /* .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) */
                  .map((row, index) => {
                    const labelId = `enhanced-table-${index}`;
  
                    return (
                      <TableRow
                        hover
                        tabIndex={-1}
                        key={row.id}
                      >
                        <TableCell
                          onClick={() => handleEditIntervention(row.uuid)}
                          style={{cursor: 'pointer'}}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normal"
                        >
                          {row.company && row.company.name}
                        </TableCell>
                        <TableCell
                          onClick={() => handleEditIntervention(row.uuid)} style={{cursor: 'pointer'}}
                          align="right"
                        >
                          {translateStatus(row.status)}
                        </TableCell>
                        <TableCell
                          onClick={() => handleEditIntervention(row.uuid)}
                          style={{cursor: 'pointer'}}
                          align="right"
                        >
                          {row.address && `${row.address.fullAddress}`}
                        </TableCell>
                        <TableCell
                          onClick={() => handleEditIntervention(row.uuid)}
                          style={{cursor: 'pointer'}}
                          align="right"
                        >
                          {row.specialist && row.specialist.lastName} {row.specialist && row.specialist.firstName}
                        </TableCell>
                        <TableCell
                          onClick={() => handleEditIntervention(row.uuid)}
                          style={{cursor: 'pointer'}}
                          align="right"
                        >
                          {row.selectedDate ? moment(row.selectedDate).format('DD MMMM YYYY') : 'Pas encore de date sélectionnée'}
                        </TableCell>
                        <TableCell
                          onClick={() => handleEditIntervention(row.uuid)}
                          style={{cursor: 'pointer'}}
                          align="right"
                        >
                          {row.startTime}
                        </TableCell>
                        {(hasRoles() === 'admin' || hasRoles() === 'intervention_manager') && <TableCell align="right">
                            <IconButton color='inherit' onClick={(event) => handleClick(event, row)}>
                                <MoreVertIcon />
                            </IconButton> 
                        </TableCell>}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            {!interventions && <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
          </TableContainer>
          <ThemeProvider theme={(outerTheme) => createTheme(outerTheme, locales[locale])}>
            <TablePagination
                labelRowsPerPage=''
                rowsPerPageOptions={[]}
                component="div"
                count={totalItems}
                nextIconButtonProps={{ disabled: loading || page >= (totalItems / 30) - 1}}
                backIconButtonProps={{ disabled: loading || page === 0 }} 
                rowsPerPage={30}
                page={page}
                onPageChange={handleChangePage}
            />
          </ThemeProvider>
            <Menu
                id='action-menu'
                anchorEl={openMenu}
                keepMounted
                open={Boolean(openMenu)}
                onClose={handleCloseAction}
            >
                <MenuItem onClick={handleEditIntervention}><EditIcon style={{marginRight: 8}}/> Modifier</MenuItem>
                {hasRoles() === 'admin' && <MenuItem onClick={handleOpenDeletePopup}>
                  <DeleteIcon style={{marginRight: 8}}/> Supprimer
                </MenuItem>}
                {(hasRoles() === 'intervention_manager' || hasRoles() === 'admin') &&
                  <MenuItem onClick={(e) => handleOpenDeletePopup(e, true)}>
                    <CancelIcon style={{marginRight: 8}}/> Annuler
                  </MenuItem>}
            </Menu>
            <DeletedConfirmationPopup
              canceled={canceled}
              handleCancel={handleCancel}
              handleAccept={handleDelete}
              open={openDeletePopup}
              handleClose={handleOpenDeletePopup}
              darkState={darkState}
            />
            {loading && <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
        </Paper>}
      </div>
      </Zoom>
    );
}
