import { LinearProgress, TextField, useTheme } from '@mui/material'
import React from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { removeLastComma } from '../helpers/utils'

export default function AutoCompleteAddress({
    isCv = false,
    setRefresh,
    setExpanded,
    handleFileChanged,
    marginLeft,
    disabled,
    setDisable,
    matches,
    className = '',
    darkState,
    id,
    name,
    label,
    defaultValue = '',
    required,
    error,
    setNewAddress,
    setNewCoordonnate,
    searchOptions = {},
    text = '',
    customStyle = false,
    style,
}) {
    const [address, setAddress] = React.useState(defaultValue)
    const theme = useTheme();

    const handleSelect = async (value) => {
        setRefresh && setRefresh(true)
        const results = await geocodeByAddress(value)
        if (searchOptions.types) {
            setAddress(removeLastComma(value))
            setNewAddress && setNewAddress(results[0].formatted_address)
        } else {
            setAddress(value)
            setNewAddress && setNewAddress(results)
        }
        setNewCoordonnate && setNewCoordonnate({lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()})
        handleFileChanged && setTimeout(() => {
            handleFileChanged(results, {lat: results[0].geometry.location.lat(), lng: results[0].geometry.location.lng()})
            setAddress('')
        }, 300)
        setExpanded && setExpanded(false)
        setRefresh && setRefresh(false)
    }

    const handleChange = (value) => {
        setRefresh && setRefresh(true)
        setDisable && setDisable(false)
        if (searchOptions.types) {
            setAddress(removeLastComma(value))
        } else {
            setAddress(value)
        }
        if (!value) {
            setNewCoordonnate && setNewCoordonnate(false)
            setNewAddress && setNewAddress(false)
            setExpanded && setExpanded(false)
            setRefresh && setRefresh(false)
        }
    }

    return (
        <PlacesAutocomplete value={address} onChange={handleChange} onSelect={handleSelect} searchOptions={searchOptions}>
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                <div>
                    <TextField
                        {...getInputProps(
                            {
                                disabled: disabled,
                                label: label,
                                error: error,
                                className: className,
                                id: id,
                                name: name,
                                helperText: text,
                                required: required,
                                autoComplete: 'off',
                                color: darkState ? 'secondary' : "primary",
                                style: style,
                            }
                        )}/>
                    <div>
                        {loading ? <LinearProgress
                            style={
                                {
                                    maxWidth: '400px',
                                    marginLeft: !matches && customStyle && '35.5%',
                                    textAlign: customStyle && 'left',
                                    marginTop: 10
                                }
                            }
                        /> : null}
                        {suggestions.map((suggestion, index) => {
                            const style = {
                                padding: '5px',
                                maxWidth: '400px',
                                marginLeft: marginLeft,
                                textAlign: customStyle && 'left',
                                color: theme.palette.mode === 'light' ? 'black' : 'white',
                                cursor: 'pointer',
                            }
                            return <div {...getSuggestionItemProps(suggestion, { style })} key={index} >{removeLastComma(suggestion.description)}</div>
                        })}
                    </div>
                </div>
            )}
        </PlacesAutocomplete>
    )
}