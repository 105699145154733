import React from 'react';
import { 
    Toolbar, 
    Typography,
} from '@mui/material/';

export const EnhancedTableToolbar = () => {
    
    return (
        <Toolbar>
            <Typography variant="h6" id="tableTitle" component="div">
                Psychologues
            </Typography>
        </Toolbar>
    );
};