import React, { useEffect, useState } from 'react';
import { 
    Chip,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    InputAdornment,
    Tooltip,
    Zoom,
    Typography
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import { TransitionLeft } from '../helpers/utils';
import { handleFormError } from '../service/security/formError';
import UploadLogo from './UploadLogo';
import { apiFetch } from '../service/apiFetch';

const filter = createFilterOptions();

export default function AutoCompleteCompany({
    selectedData,
    text,
    label,
    id,
    customStyle,
    handleFileChanged,
    setLoading,
    data,
    setData,
    createData,
    darkState,
    setOpenSnackbar,
    setText,
    setTransition,
    setSeverity 
}) {
    const [open, toggleOpen] = useState(false);
    const [disable, setDisable] = useState(false);
    const [title, setTitle] = useState(false);
    const [error, setError] = useState({});
    const [photo, setPhoto] = useState(false)

    const options = data.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option,
        };
    });

    const handleClose = () => {
        setDialogValue({
            name: '',
            description: '',
        });
        setTitle(false)
        toggleOpen(false);
    };

    const [dialogValue, setDialogValue] = useState({
        name: '',
        description: '',
    });

    useEffect(() => {
        if (selectedData) {
            setData(selectedData)
        }
    }, [selectedData, setData])

    const handleSubmit = async (event) => {
        event.preventDefault();
        setTitle(false)
        setError({})
        let createPhoto = null
        if (photo) {
            let dataPhoto = new FormData() 
            dataPhoto.append('file', photo && photo[0])
            createPhoto = await apiFetch('/logo_objects', {
                method: 'POST',
                body: dataPhoto,
            }, true)
        }
        setDisable(true)
        let createdData = {}
        if (text === 'expérience') {
            createdData.uuid = 'ok'
        } else if (text === 'spécialité') {
            createdData = await createData({
                name: dialogValue.name,
            })
        } else if (text === 'référence') {
            createdData = await createData({
                name: dialogValue.name,
                description: dialogValue.description,
                logo: createPhoto && createPhoto['@id'] 
            })
        } else {
            createdData = await createData({
                name: dialogValue.name,
                description: dialogValue.description,
            })
        }
        
        if (createdData && createdData.uuid) {
            setText(`L'${text} a été crée`)
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            if (text === 'expérience') {
                if (dialogValue.isModify) {
                    const modifyExperience = selectedData.find((data) => {
                        if (data.isNew) {
                            return data.experience.name === dialogValue.data.experience.name
                        }
                        return data.experience === dialogValue.data.experience
                    })
                    modifyExperience.description = dialogValue.description
                } else if (dialogValue.tempValue) {
                    setData([...selectedData, {
                        experience: data.find((item) => item.name === dialogValue.name),
                        description: dialogValue.description,
                        name: dialogValue.name,
                        isAdd: true
                    }]);
                } else {
                    setData([...selectedData, {
                        experience: {
                            name: dialogValue.name,
                        },
                        description: dialogValue.description,
                        name: dialogValue.name,
                        isNew: true
                    }]);
                }
            } else {
                setData([...selectedData, {
                    name: dialogValue.name,
                    description: dialogValue.description,
                    '@id': createdData['@id'],
                }]);
            }
            setDialogValue({
                name: '',
                description: '',
            })
            handleFileChanged && handleFileChanged(createdData)
            handleClose();
        } else if (createdData.violations) {
            const errorField = handleFormError(createdData, ['name', 'description'])
            setError(errorField)
            setText('Erreur lors de la création')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        } else {
            setText((createdData && createdData['hydra:description']) || 'Erreur lors de la création')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setDisable(false)
    };

    const optionDisable = (option) => {
        const disable = selectedData.find((data) => {
            return data === option.name || data.name === option.name
        })
        return !!disable
    }

    const disableEnterKey = (e) => {
        if (e.code === 'Enter') {
            e.stopPropagation()
            e.preventDefault()
        }
    }

    const handleModify = (e, props) => {
        setTitle('Modifier une expérience')
        setDialogValue({
            name: props.data.name,
            description: props.data.description,
            data: props.data,
            isModify: true,
            isDisable: true,
        })
        toggleOpen(true)
    }

    const MyChip = (props) => {
        if (text === 'expérience') {
            return (
                <Chip
                    {...props}
                    onClick={(e) => handleModify(e, props)}
                />
            );
        }
        return (
            <Chip
                {...props}
            />
        );
        
      };

    return (
        <React.Fragment>
            <Autocomplete
                disableCloseOnSelect
                onKeyPress={disableEnterKey}
                multiple
                getOptionDisabled={text === 'expérience' ? () => null : optionDisable}
                isOptionEqualToValue={(option, value) => {
                    return option.name === value.name
                }}
                value={selectedData || ''}
                groupBy={(option) => option.firstLetter}
                onChange={(event, newValue) => {
                    if (typeof newValue === 'string') {
                        // timeout to avoid instant validation of the dialog's form.
                        setTimeout(() => {
                            toggleOpen(true);
                            setDialogValue({
                                name: newValue,
                                description: '',
                            });
                        });
                    } else if (
                        newValue &&
                        newValue.length &&
                        newValue[newValue.length - 1].inputValue &&
                        event.currentTarget.tagName !== 'svg'
                    ) {
                        toggleOpen(true);
                        setDialogValue({
                            name: newValue[newValue.length - 1].inputValue,
                            description: '',
                        });
                    } else {
                        if (text === 'expérience' && event.currentTarget.tagName !== 'svg') {
                            toggleOpen(true);
                            return setDialogValue({
                                name: newValue[newValue.length - 1].name,
                                description: '',
                                isDisable: true,
                                tempValue: newValue,
                            });
                        }
                        const rightValue = newValue.filter((value) => !value.inputValue)
                        setData(rightValue);
                        handleFileChanged && handleFileChanged(newValue)
                    }
                    setLoading && setLoading(false)
                }}
                filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    
                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            name: (text === 'certification' || text === 'spécialité') ?
                                `Ajouter la ${text} : "${params.inputValue}"` :
                                `Ajouter l'${text} : "${params.inputValue}"`,
                        });
                    }

                    return filtered;
                }}
                id={id}
                options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.inputValue) {
                        return option.inputValue;
                    }
                    if (text === 'expérience') {
                        return option.name
                    }
                    return option.name;
                }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.uuid}>
                        <Typography
                            style={{ marginRight: 8 }}
                            color={darkState ? 'secondary' : 'primary'}
                        />
                        {option.name}
                    </li>
                )}
                style={{
                    marginRight: 50,
                    marginBottom: 30,
                    marginTop: 30,
                    width: 400, 
                }}
                renderTags={(tagValue, getTagProps) => {
                    return tagValue.map((option, index) => {
                        return <MyChip {...getTagProps({ index })} data={option} label={option.name} />
                    });
                }}
                renderInput={(params) => (
                    <TextField 
                        {...params} 
                        label={label}
                        color={darkState ? 'secondary' : "primary"}
                        style={customStyle ? {width: 300} : {}}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <Tooltip TransitionComponent={Zoom} title={`Ajouter une ${text}`}>
                                    <InputAdornment position="end" style={{cursor: 'pointer'}} onClick={() => toggleOpen(true)}>
                                        <AddIcon />
                                    </InputAdornment>
                                </Tooltip>
                            )
                        }}
                    />
                )}
            />
            <Dialog open={open} onClose={handleClose} aria-labelledby="dialog-title">
                    <DialogTitle id="dialog-title">{title || `Ajouter une nouvelle ${text}`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {`Pour ajouter une ${text}, vous devez renseigner au minimum le nom.`}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            id="name"
                            value={dialogValue.name}
                            onChange={(event) => setDialogValue({ ...dialogValue, name: event.target.value })}
                            label={text === 'spécialité' ? `Nom de la ${text}` : `Nom de l'${text}`}
                            type="text"
                            style={{width: '100%'}}
                            error={!!error.name}
                            helperText={error.name}
                            color={darkState ? 'secondary' : 'primary'}
                            disabled={dialogValue.isDisable}
                        /><br />
                        {text !== 'spécialité' && <TextField
                            id="description"
                            value={dialogValue.description}
                            onChange={(event) => setDialogValue({ ...dialogValue, description: event.target.value })}
                            label="Description"
                            type="text"
                            multiline
                            rows={5}
                            error={!!error.description}
                            helperText={error.description}
                            color={darkState ? 'secondary' : 'primary'}
                            style={{marginTop: 20, width: '100%'}}
                        />}
                        {text === 'référence' && 
                            <div style={{marginTop: 30}}>
                                <UploadLogo setPhoto={setPhoto} title={'Ajouter le logo de l\'entreprise'} id='logo' />
                            </div>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={disable} onClick={handleClose} color={darkState ? 'secondary' : 'primary'}>
                            Annuler
                        </Button>
                        <Button onClick={handleSubmit} color={darkState ? 'secondary' : 'primary'}>
                            {dialogValue.isModify ? 'Modifier' : 'Ajouter'}
                        </Button>
                    </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}