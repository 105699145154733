import { useReducer } from "react"
import { apiFetch } from "../service/apiFetch"

const initialState = []

const FETCHING_EXPERIENCES = 'FETCHING_EXPERIENCES'
const SET_EXPERIENCES = 'SET_EXPERIENCES'
const ADD_EXPERIENCES = 'ADD_EXPERIENCES'
const UPDATE_EXPERIENCES = 'UPDATE_EXPERIENCES'
const DELETE_EXPERIENCES = 'DELETE_EXPERIENCES'

export function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCHING_EXPERIENCES:
            return { ...state, loading: true }
        case SET_EXPERIENCES:
            return {...state, experiences: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_EXPERIENCES:
            return {...state, experiences: [action.payload, ...state.experiences]}
        case UPDATE_EXPERIENCES:
            return {...state, experiences: action.payload }
        case DELETE_EXPERIENCES:
            return {...state, experiences: state.experiences.filter((i) => {
                return i.uuid !== action.payload
            })}
        default:
            return state
    }
}

export function useExperiences() {
    const [state, dispatch] = useReducer(reducer, {    
        experiences: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        experiences: state.experiences,
        totalItems: state.totalItems,
        view: state.view,
        fetchExperiences: async function (route, isSubscribed = () => ({current: true})) {
            if ((state.experiences || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_EXPERIENCES' })
            const experiences = await apiFetch(route || '/experiences', {method: 'GET'})

            if (isSubscribed().current && experiences && experiences['hydra:member']) {
                dispatch({type: 'SET_EXPERIENCES', payload: experiences})
            }
        },
        createExperience: async function(data, notIterate, route) {
            const newExperience = await apiFetch(route || '/experiences', {
                method: 'POST',
                body: JSON.stringify(data),
            })

            if (newExperience.uuid && !notIterate) {
                dispatch({type: 'ADD_EXPERIENCES', payload: newExperience })
            }
            return newExperience
        },
        updateExperience: async function(interventionId, data, forceResponse) {
            const updatedExperience = await apiFetch('/experiences/' + interventionId, {
                method: 'PUT',
                body: JSON.stringify(data),
            }, forceResponse)

            if (!updatedExperience.violations && !updatedExperience['hydra:description']) {
                dispatch({type: 'UPDATE_EXPERIENCES', payload: updatedExperience })
            }
            return updatedExperience
        },
        deleteExperience: async function (interventionId) {
            const deletedExperience = await apiFetch('/experiences/' + interventionId, {
                method: 'DELETE'
            }, true)
            if (deletedExperience && deletedExperience.ok) {
                dispatch({type: 'DELETE_EXPERIENCES', payload: interventionId})
            }
            return deletedExperience
        },
    }
}