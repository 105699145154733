import React, { useEffect, useRef, useState } from 'react'
import {
    Paper,
    Typography,
    Button,
    LinearProgress,
    Zoom,
    Box,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    
} from '@mui/material';
import { apiFetch } from '../../service/apiFetch';
import { useNavigate, useParams } from 'react-router-dom';
import { 
    convertActivitiesToArray,
    convertActivitiesToObject,
    formatAddress,
    formatExperiences,
    formatMultipleCompanies,
    handleMultipleTextFieldValue,
    TransitionLeft 
} from '../../helpers/utils';
import { handleFormError } from '../../service/security/formError';
import { useSpecialists } from '../../hooks/specialist';
import { useCompanies } from '../../hooks/company';
import { useExpertises } from '../../hooks/expertise';
import { useExperiences } from '../../hooks/experience';
import { useSpecialities } from '../../hooks/specialities';
import { useCertifications } from '../../hooks/certification';
import moment from 'moment'
import Information from '../ManageSpecialistItem/Information';
import ProfessionnalInformation from '../ManageSpecialistItem/ProfressionnalInformation';
import Skills from '../ManageSpecialistItem/Skills';

const steps = [
    {
        label: 'Informations Générales',
    },
    {
        label: 'Informations Professionnelles',
    },
    {
        label: 'Compétences',
    }
];

export default function ManageSpecialist({
    setText,
    setTransition,
    setSeverity,
    setOpenSnackbar,
    darkState,
    zoom 
}) {
    const { id } = useParams()
    const [specialistEdit, setSpecialistEdit] = useState(false)
    const [loading, setLoading] = useState(false)
    const [languages, setLanguages] = useState(false)
    const [activities, setActivities] = useState(false)
    const [specialistTypes, setSpecialistTypes] = useState([])
    const [specialistType, setSpecialistType] = useState([])
    const [administrativeTypes, setAdministrativeTypes] = useState(false)
    const [switchOffice, setSwitchOffice] = useState(false)
    const [comment, setComment] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [adeli, setAdeli] = useState('')
    const [rpps, setRpps] = useState('')
    const [mobilePro, setMobilePro] = useState('')
    const [phoneRegionCode, setphoneRegionCode] = useState('')
    const [mobilePerso, setMobilePerso] = useState('')
    const [phone, setPhone] = useState('')
    const [descriptionCvHeader, setDescriptionCvHeader] = useState('')
    const [urssafAddress, setUrssafAddress] = useState('')
    const [urssafAddressZipCode, setUrssafAddressZipCode] = useState('')
    const [urssafAddressCity, setUrssafAddressCity] = useState('')
    const [siret, setSiret] = useState('')
    const [taxStatus, setTaxStatus] = useState('')
    const [taxStatuses, setTaxStatuses] = useState([])
    const [expertise, setExpertise] = useState([])
    const [language, setLanguage] = useState([])
    const [activity, setActivity] = useState([])
    const [status, setStatus] = useState([])
    const [experience, setExperience] = useState([])
    const [speciality, setSpeciality] = useState([])
    const [certification, setCertification] = useState([])
    const [newAddress, setNewAddress] = useState(null)
    const [newCoordonnate, setNewCoordonnate] = useState(null)
    const [birthdate, setBirthdate] = useState(null)
    const [multipleAddress, setMultipleAddress] = useState([])
    const [error, setError] = useState({})
    const [activeStep, setActiveStep] = React.useState(0);
    const [title, setTitle] = useState('')
    const [state, setState] = useState({
        motorized: false,
        iprp: false,
        coachWellmonday: false,
        pssm: false,
        allowSurroundingAreaVisibility: false,
    });
    const [multipleCompanies, setMultipleCompanies] = useState([])
    const [photo, setPhoto] = useState(false);
    let navigate = useNavigate()
    const mounted = useRef(false);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
  
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const {
        companies,
        createCompany,
        fetchCompanies
    } = useCompanies()

    const {
        updateSpecialist,
        createSpecialist
    } = useSpecialists()

    const {
        expertises,
        createExpertise,
        fetchExpertises,
    } = useExpertises()

    const {
        experiences,
        fetchExperiences,
        createExperience
    } = useExperiences()

    const {
        specialities,
        fetchSpecialities,
        createSpeciality
    } = useSpecialities()

    const {
        certifications,
        fetchCertifications,
        createCertification
    } = useCertifications()

    useEffect(() => {
        (async () => {
            mounted.current = true
            setActivities([
                {id: 'homeIntervention', name: 'Intervention à domicile'},
                {id: 'platform', name: 'Ligne d\'écoute'},
                {id: 'crisisUnit', name: 'Cellules de crise'},
                {id: 'conflictResolutionSupport', name: 'Appui à la résolution de conflits, médiation'},
                {id: 'pse', name: 'Permanence sur site'},
                {id: 'app', name: 'Analyse de la Pratique Professionnelle'},
                {id: 'coaching', name: 'Coaching individuel et collectif'},
                {id: 'certifiedCoaching', name: 'Coaching certifié'},
                {id: 'projectManager', name: 'Chef de projet/audit'},
                {id: 'harassmentInvestigation', name: 'Enquête harcèlement'},
                {id: 'rpsCoach', name: 'Formateur dans la prévention des RPS auprès de salariés et de managers'},
                {id: 'call', name: 'Appel'},
                {id: 'visio', name: 'Visio'},
            ])
            await fetchCompanies('/companies?pagination=false', () => mounted)
            const languagesFetch = await apiFetch('/languages')
            if (languagesFetch && languagesFetch['hydra:member']) {
                setLanguages(languagesFetch['hydra:member'])
            } else {
                setText((languagesFetch && languagesFetch['hydra:description']) || 'Erreur lors de la récupération des langues')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
            }
            const specialistTypesFetch = await apiFetch('/specialist_types')
            if (specialistTypesFetch && specialistTypesFetch['hydra:member']) {
                setSpecialistTypes(specialistTypesFetch['hydra:member'])
            } else {
                setText((specialistTypesFetch && specialistTypesFetch['hydra:description']) || 'Erreur lors de la récupération des types')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
            }
            const administrativeTypes = await apiFetch('/administrative_types')
            if (administrativeTypes && administrativeTypes['hydra:member']) {
                setAdministrativeTypes(administrativeTypes['hydra:member'])
            } else {
                setText((administrativeTypes && administrativeTypes['hydra:description']) || 'Erreur lors de la récupération des statuts administratifs')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
            }

            await fetchExpertises('/expertises?pagination=false', () => mounted)

            await fetchExperiences('/experiences?pagination=false', () => mounted)

            await fetchSpecialities('/specialities?pagination=false', () => mounted)

            await fetchCertifications('/certifications?pagination=false', () => mounted)

            const taxStatusFetch = await apiFetch('/tax_statuses')
            if (taxStatusFetch && taxStatusFetch['hydra:member']) {
                setTaxStatuses(taxStatusFetch['hydra:member'])
            } else {
                setText((administrativeTypes && administrativeTypes['hydra:description']) || 'Erreur lors de la récupération des statuts fiscaux')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
            }

            if (id) {
                const specialistFetch = await apiFetch(`/specialists/${id}`)
                if (specialistFetch) {
                    setFirstName(specialistFetch.firstName)
                    setLastName(specialistFetch.lastName)
                    setAdeli(specialistFetch.adeli)
                    setRpps(specialistFetch.rpps)
                    setMobilePro(specialistFetch.mobilePro)
                    setphoneRegionCode(specialistFetch.phoneRegionCode || '')
                    setMobilePerso(specialistFetch.mobilePerso)
                    setPhone(specialistFetch.phone)
                    setUrssafAddress(specialistFetch.urssafAddress)
                    setUrssafAddressCity(specialistFetch.urssafAddressCity)
                    setUrssafAddressZipCode(specialistFetch.urssafAddressZipCode)
                    setEmail(specialistFetch.email)
                    setSiret(specialistFetch.siret)
                    setDescriptionCvHeader(specialistFetch.descriptionCvHeader)
                    setSpecialistEdit(specialistFetch)
                    setTitle(specialistFetch.title)
                    setBirthdate(moment(specialistFetch.birthdate))
                    setState({
                        motorized: specialistFetch.motorized,
                        iprp: specialistFetch.iprp,
                        coachWellmonday: specialistFetch.coachWellmonday,
                        pssm: specialistFetch.pssm,
                        allowSurroundingAreaVisibility: specialistFetch.allowSurroundingAreaVisibility,
                    })
                    setSwitchOffice(!!specialistFetch.offices.length)
                    setMultipleAddress(specialistFetch.offices)
                    setLanguage(specialistFetch.languages)
                    setActivity(convertActivitiesToArray(specialistFetch.activity))
                    setSpecialistType(specialistFetch.specialistType)
                    setStatus(specialistFetch.administrativeTypes)
                    setExpertise(specialistFetch.expertises)
                    setExperience(formatExperiences(specialistFetch.experiences))
                    setSpeciality(specialistFetch.specialities)
                    setCertification(specialistFetch.certifications)
                    setMultipleCompanies(specialistFetch.companies)
                    setComment(specialistFetch.comment)
                    setTaxStatus(specialistFetch.taxStatus ? specialistFetch.taxStatus['@id'] : '')
                } else {
                    setText((specialistFetch && specialistFetch['hydra:description']) || 'Erreur lors de la récupération des données spécialiste')
                    setTransition(() => TransitionLeft)
                    setSeverity('error')
                    setOpenSnackbar(true)
                }
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, setOpenSnackbar, setTransition, setSeverity, setText])

    const handleBackSpecialists = () => {
        navigate('/specialists')
    }

    const handleChangeTitle = (e) => {
        setTitle(e.target.value)
    }
    const handlephoneRegionCodeChange = (event) => {
        setphoneRegionCode(event.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError({})
        let createPhoto = null
        if (photo) {
            let dataPhoto = new FormData()
            dataPhoto.append('file', photo && photo[0])
            createPhoto = await apiFetch('/photo_objects', {
                method: 'POST',
                body: dataPhoto
            }, true)
        }
        if (createPhoto && createPhoto.violations) {
            setText(createPhoto['hydra:description'])
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
            return null
        }

        const selectedTitle = title
        const selectedBirthdate = birthdate
        let data = { 
            title: selectedTitle,
            email: email,
            firstName: firstName,
            lastName: lastName,
            mobilePro: mobilePro ? mobilePro : undefined,
            phoneRegionCode: phoneRegionCode,
            mobilePerso: mobilePerso ? mobilePerso : undefined,
            phone: phone ? phone : undefined,
            birthdate: selectedBirthdate,
            adeli: adeli ? adeli : undefined,
            rpps: rpps ? rpps : undefined,
            siret: siret && taxStatus && getName(taxStatus) !== 'Portage' ? siret : null,
            activity: convertActivitiesToObject(activity),
            urssafAddress:urssafAddress,
            urssafAddressZipCode:urssafAddressZipCode,
            urssafAddressCity:urssafAddressCity,
            experiences: handleMultipleTextFieldValue(experience,experiences),
            expertises: handleMultipleTextFieldValue(expertise),
            specialities: handleMultipleTextFieldValue(speciality),
            certifications: handleMultipleTextFieldValue(certification),
            companies: formatMultipleCompanies(multipleCompanies),
            offices: multipleAddress,
            languages: handleMultipleTextFieldValue(language),
            specialistType: handleMultipleTextFieldValue(specialistType),
            administrativeTypes: handleMultipleTextFieldValue(status),
            motorized: state.motorized,
            iprp: state.iprp,
            comment: comment,
            descriptionCvHeader: descriptionCvHeader,
            taxStatus: taxStatus || undefined,
            coachWellmonday: state.coachWellmonday,
            pssm: state.pssm,
            allowSurroundingAreaVisibility: state.allowSurroundingAreaVisibility
        }

        if (photo) {
            data.photo = createPhoto['@id']
        }
        if (newAddress) {
            data.address = formatAddress(newAddress, newCoordonnate)
        }

        const manageSpecialist = id ? await updateSpecialist(id, data) : await createSpecialist(data, true)
        if (manageSpecialist && manageSpecialist.uuid) {
            setText(id ? 'L\'utilisateur a été modifié' : 'L\'utilisateur a été crée')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setTimeout(() => {
                navigate(`/specialists/${manageSpecialist.uuid}`)
            }, 1000)
        }  else if (manageSpecialist.violations) {
            const errorField = handleFormError(manageSpecialist,
                [
                    'comment',
                    'title',
                    'mobilePro',
                    'phoneRegionCode',
                    'mobilePerso',
                    'firstName',
                    'lastName',
                    'email',
                    'phone',
                    'birthdate',
                    'address',
                    'adeli',
                    'rpps',
                    'siret',
                    'urssafAddress',
                    'urssafAddressZipCode',
                    'urssafAddressCity',
                    'administrativeTypes',
                    'specialistType',
                ]
            )
            setError(errorField)
            setText(manageSpecialist['hydra:description'])
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        } else {
            setText(
                (manageSpecialist && manageSpecialist['hydra:description']) ||
                id ? 'Erreur lors de la modification' : 'Erreur lors de la création'
            )
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setLoading(false)
    }


    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const handleChangeSwitch = () => {
        if (switchOffice && !specialistEdit) {
            setMultipleAddress([])
        }
        setSwitchOffice(!switchOffice)
    }

    const getStepContent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <div>
                        <Information
                            error={error}
                            title={title}
                            handleChangeTitle={handleChangeTitle}
                            lastName={lastName}
                            setLastName={setLastName}
                            firstName={firstName}
                            setFirstName={setFirstName}
                            email={email}
                            setEmail={setEmail}
                            state={state}
                            handleChange={handleChange}
                            mobilePro={mobilePro}
                            setMobilePro={setMobilePro}
                            phoneRegionCode={phoneRegionCode}
                            handlephoneRegionCodeChange={handlephoneRegionCodeChange}
                            mobilePerso={mobilePerso}
                            setMobilePerso={setMobilePerso}
                            phone={phone}
                            setPhone={setPhone}
                            setBirthdate={setBirthdate}
                            birthdate={birthdate}
                            darkState={darkState}
                            id={id}
                            specialistEdit={specialistEdit}
                            setNewAddress={setNewAddress}
                            setNewCoordonnate={setNewCoordonnate}
                            setPhoto={setPhoto}
                        />
                    </div>
                )
            case 1:
                return (
                    <div>
                        <ProfessionnalInformation 
                            error={error}
                            state={state}
                            handleChange={handleChange}
                            darkState={darkState}
                            taxStatus={taxStatus}
                            setTaxStatus={setTaxStatus}
                            adeli={adeli}
                            setAdeli={setAdeli}
                            rpps={rpps}
                            setRpps={setRpps}
                            taxStatuses={taxStatuses}
                            siret={siret}
                            setSiret={setSiret}
                            urssafAddress={urssafAddress}
                            setUrssafAddress={setUrssafAddress}
                            urssafAddressZipCode={urssafAddressZipCode}
                            setUrssafAddressZipCode={setUrssafAddressZipCode}
                            urssafAddressCity={urssafAddressCity}
                            setUrssafAddressCity={setUrssafAddressCity}
                            switchOffice={switchOffice}
                            handleChangeSwitch={handleChangeSwitch}
                            multipleAddress={multipleAddress}
                            setMultipleAddress={setMultipleAddress}
                            getName={getName}
                        />
                    </div>
                )
            case 2:
                return (
                    <div>
                        <Skills
                            error={error}
                            darkState={darkState}
                            descriptionCvHeader={descriptionCvHeader}
                            setDescriptionCvHeader={setDescriptionCvHeader}
                            specialistEdit={specialistEdit}
                            status={status}
                            setStatus={setStatus}
                            administrativeTypes={administrativeTypes}
                            specialistType={specialistType}
                            setSpecialistType={setSpecialistType}
                            specialistTypes={specialistTypes}
                            language={language}
                            setLanguage={setLanguage}
                            languages={languages}
                            setText={setText}
                            setTransition={setTransition}
                            setOpenSnackbar={setOpenSnackbar}
                            setSeverity={setSeverity}
                            setExpertise={setExpertise}
                            expertises={expertises}
                            expertise={expertise}
                            createExpertise={createExpertise}
                            certification={certification}
                            certifications={certifications}
                            setCertification={setCertification}
                            createCertification={createCertification}
                            experience={experience}
                            experiences={experiences}
                            setExperience={setExperience}
                            createExperience={createExperience}
                            activities={activities}
                            activity={activity}
                            setActivity={setActivity}
                            comment={comment}
                            setComment={setComment}
                            multipleCompanies={multipleCompanies}
                            setMultipleCompanies={setMultipleCompanies}
                            createCompany={createCompany}
                            companies={companies}
                            speciality={speciality}
                            setSpeciality={setSpeciality}
                            specialities={specialities}
                            createSpeciality={createSpeciality}
                            convertActivitiesToArray={convertActivitiesToArray}
                        />
                    </div>
                )
            default:
                return 'Unknown stepIndex';
        }
    }

    const isStepFailed = (step) => {
        return (Object.keys(error)).map(key => findErreurInStepper(key)).filter(e => e === step).length
    };

    const findErreurInStepper = (key) => {
        switch (key) {
            case 'comment':
                return 2
            case 'title':
                return 0
            case 'mobilePro':
                return 0
            case 'phoneRegionCode':
                return 0
            case 'mobilePerso':
                return 0
            case 'firstName':
                return 0
            case 'lastName':
                return 0
            case 'email':
                return 0
            case 'phone':
                return 0
            case 'birthdate':
                return 0
            case 'address':
                return 0
            case 'adeli':
                return 1
            case 'rpps':
                return 1
            case 'siret':
                return 1
            case 'urssafAddress':
                return 1
            case 'urssafAddressZipCode':
                return 1
            case 'urssafAddressCity':
                return 1
            case 'administrativeTypes':
                return 2
            case 'specialistType':
                return 2
            default:
                return false
        }
    }

    const getName = (el) => {
        let item = null
        taxStatuses.map((i) => {
            if (i['@id'] === el) {
                return item = i
            }
            return null
        })
        return item.name
    }

    return (
        <Zoom in={zoom}>
            <div>
                {(id && !specialistEdit) ? <LinearProgress color={darkState ? 'secondary' : 'primary'} /> :
                <div>
                    <Zoom in={zoom} style={{ transitionDelay: '400ms' }}>
                        <Paper sx={{p: 2}}>
                            <div style={{display: 'flex'}}>
                                <Typography variant='h5' color={darkState ? 'secondary' : "primary"} style={{flex: '1 1 100%'}}>
                                    {id ? `Modifier le profil de ${specialistEdit.firstName} ${specialistEdit.lastName}` : 'Ajouter un spécialiste'}
                                </Typography>
                                <Typography variant='caption' component='div' style={{display: 'flex', marginRight: 40}}>
                                    <div style={{width: 20, height: 10, backgroundColor: '#ffae00', marginTop: 5, marginRight: 10}}></div> 
                                    champs affiché dans le cv
                                </Typography>
                                <Button onClick={handleBackSpecialists} color={darkState ? 'secondary' : "primary"} variant='outlined'>
                                    Retour
                                </Button>
                            </div>
                        </Paper>
                    </Zoom>
                    <Zoom in={zoom} style={{ transitionDelay: '200ms' }}>
                        <Box>
                            <Stepper activeStep={activeStep} orientation="vertical">
                                {steps.map((step, index) => {
                                    const labelProps = {};
                                    if (isStepFailed(index)) {
                                      labelProps.optional = (
                                        <Typography variant="caption" color="error">
                                            Il y a une ou plusieurs erreurs
                                        </Typography>
                                      );
                          
                                      labelProps.error = true;
                                    }
                                    return (
                                    <Step key={step.label}>
                                        <StepLabel {...labelProps}>
                                            {step.label}
                                        </StepLabel>
                                        <StepContent>
                                            <div>
                                                {((id && specialistEdit) || !id) && <form noValidate onSubmit={handleSubmit} autoComplete="off">
                                                    {getStepContent()}
                                                    <Box sx={{ mt: 2 }}>
                                                        <div>
                                                            {index !== steps.length - 1 ? <Button
                                                                variant="contained"
                                                                onClick={handleNext}
                                                                sx={{ mt: 1, mr: 1 }}
                                                                color={darkState ? 'secondary' : "primary"}
                                                            >
                                                                Continue
                                                            </Button> : 
                                                            <Button
                                                                onClick={handleSubmit}
                                                                disabled={loading}
                                                                color={darkState ? 'secondary' : "primary"}
                                                                sx={{ mt: 1, mr: 1 }}
                                                                variant="contained"
                                                            >
                                                                Enregistrer
                                                            </Button>}
                                                            <Button
                                                                disabled={loading || index === 0}
                                                                onClick={handleBack}
                                                                sx={{ mt: 1, mr: 1 }}
                                                                color={darkState ? 'secondary' : "primary"}
                                                            >
                                                                Retour
                                                            </Button>
                                                        </div>
                                                    </Box>
                                                </form>}
                                            </div>
                                        </StepContent>
                                    </Step>)
                                })}
                            </Stepper>
                        </Box>
                    </Zoom>
                </div>}
            </div>
        </Zoom>
    )
}
