import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function HandleRemindersPopup({ open, handleClose, darkState, handleAccept}) {

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="change-date-popup-title"
            aria-describedby="change-date-popup-description"
        >
            <DialogTitle id="change-date-popup-title">{open.activeReminder ? "Désactiver les relances" : 'Activer les relances'}</DialogTitle>
            <DialogContent style={{paddingLeft: 80}}>
                <Button variant={'contained'} color={darkState ? 'secondary' : 'primary'} onClick={handleAccept}>
                    {open.activeReminder ? 'Désactiver' : 'Activer'}
                </Button>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={darkState ? 'secondary' : 'primary'}>
                    Annuler
                </Button>
            </DialogActions>
        </Dialog>
    );
}   