import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    TextField
} from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DescriptionPopup({ open, handleClose, darkState, handleAccept, setDescriptionData, descriptionData, error }) {

  return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="description-popup-title"
            aria-describedby="description-popup-description"
            style={{zIndex: 1301}}
        >
            <DialogTitle id="description-popup-title">Choix du psy référent</DialogTitle>
            <DialogContent>
                <DialogContentText id="description-why-popup-description">
                    Veuillez entrer les descriptions pour choisir ce psy en tant que référent
                </DialogContentText>
                <TextField
                    id="whyDescription"
                    name="whyDescription"
                    label="Pourquoi ?"
                    multiline
                    onChange={(e) => setDescriptionData({...descriptionData, whyDescription: e.target.value})}
                    value={descriptionData.whyDescription}
                    rows={5}
                    color={darkState ? 'secondary' : 'primary'}
                    error={!!error.whyDescription}
                    helperText={error.whyDescription}
                    style={{width: '100%', marginBottom: 10}}
                />
                <TextField
                    id="whoDescription"
                    name="whoDescription"
                    label="Qui ?"
                    multiline
                    onChange={(e) => setDescriptionData({...descriptionData, whoDescription: e.target.value})}
                    value={descriptionData.whoDescription}
                    rows={5}
                    color={darkState ? 'secondary' : 'primary'}
                    error={!!error.whoDescription}
                    helperText={error.whoDescription}
                    style={{width: '100%', marginBottom: 10}}
                />
                <TextField
                    id="howDescription"
                    name="howDescription"
                    label="Comment ?"
                    multiline
                    onChange={(e) => setDescriptionData({...descriptionData, howDescription: e.target.value})}
                    value={descriptionData.howDescription}
                    rows={5}
                    color={darkState ? 'secondary' : 'primary'}
                    error={!!error.howDescription}
                    helperText={error.howDescription}
                    style={{width: '100%', marginBottom: 10}}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={darkState ? 'secondary' : 'primary'}>
                    Annuler
                </Button>
                <Button onClick={handleAccept} color={darkState ? 'secondary' : 'primary'}>
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
  );
}