import { useReducer } from "react"
import { apiFetch } from "../service/apiFetch"

const initialState = []

const FETCHING_ADMINISTRATIFSTATUS = 'FETCHING_ADMINISTRATIFSTATUS'
const SET_ADMINISTRATIFSTATUS = 'SET_ADMINISTRATIFSTATUS'
const ADD_ADMINISTRATIFSTATUS = 'ADD_ADMINISTRATIFSTATUS'
const UPDATE_ADMINISTRATIFSTATUS = 'UPDATE_ADMINISTRATIFSTATUS'
const DELETE_ADMINISTRATIFSTATUS = 'DELETE_ADMINISTRATIFSTATUS'

export function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCHING_ADMINISTRATIFSTATUS:
            return { ...state, loading: true }
        case SET_ADMINISTRATIFSTATUS:
            return {...state, administratifStatus: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_ADMINISTRATIFSTATUS:
            return {...state, administratifStatus: [action.payload, ...state.administratifStatus]}
        case UPDATE_ADMINISTRATIFSTATUS:
            return {...state, administratifStatus: action.payload }
        case DELETE_ADMINISTRATIFSTATUS:
            return {...state, administratifStatus: state.administratifStatus.filter((i) => {
                return i.uuid !== action.payload
            })}
        default:
            return state
    }
}

export function useAdministratifStatus() {
    const [state, dispatch] = useReducer(reducer, {    
        administratifStatus: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        administratifStatus: state.administratifStatus,
        totalItems: state.totalItems,
        view: state.view,
        fetchAdministratifStatus: async function (route, isSubscribed = () => ({current: true})) {
            if ((state.administratifStatus || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_ADMINISTRATIFSTATUS' })
            const administratifStatus = await apiFetch(route || '/administrative_types', {method: 'GET'})

            if (isSubscribed().current && administratifStatus && administratifStatus['hydra:member']) {
                dispatch({type: 'SET_ADMINISTRATIFSTATUS', payload: administratifStatus})
            }
        },
        createAdministratifStatus: async function(data, notIterate) {
            const newAdministratifStatus = await apiFetch('/administrative_types', {
                method: 'POST',
                body: JSON.stringify(data),
            })

            if (newAdministratifStatus.uuid && !notIterate) {
                dispatch({type: 'ADD_ADMINISTRATIFSTATUS', payload: newAdministratifStatus })
            }
            return newAdministratifStatus
        },
        updateAdministratifStatus: async function(interventionId, data, forceResponse) {
            const updatedAdministratifStatus = await apiFetch('/administrative_types/' + interventionId, {
                method: 'PUT',
                body: JSON.stringify(data),
            }, forceResponse)

            if (!updatedAdministratifStatus.violations && !updatedAdministratifStatus['hydra:description']) {
                dispatch({type: 'UPDATE_ADMINISTRATIFSTATUS', payload: updatedAdministratifStatus })
            }
            return updatedAdministratifStatus
        },
        deleteAdministratifStatus: async function (interventionId) {
            const deletedAdministratifStatus = await apiFetch('/administrative_types/' + interventionId, {
                method: 'DELETE'
            }, true)
            if (deletedAdministratifStatus && deletedAdministratifStatus.ok) {
                dispatch({type: 'DELETE_ADMINISTRATIFSTATUS', payload: interventionId})
            }
            return deletedAdministratifStatus
        },
    }
}