import React from 'react';
import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
} from '@mui/material';
import moment from 'moment';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SelectDatePopup({ open, handleClose, darkState, handleAccept, intervention, selectedDate, setSelectedDate}) {

  return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="date-popup-title"
            aria-describedby="date-popup-description"
        >
            <DialogTitle id="date-popup-title">{"Choisir la date de l'intervention"}</DialogTitle>
            <DialogContent>
                {intervention && intervention.availableDates.length > 0 && <FormControl color={darkState ? 'secondary' : 'primary'} style={{width: 300, marginBottom: 20}}>
                    <InputLabel id="select-label">Dates disponibles</InputLabel>
                    <Select
                        labelId="select-label"
                        id="select"
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                    >
                        <MenuItem value="">
                            <em>Selectionnez une date</em>
                        </MenuItem>
                        {intervention.availableDates.map((date, i) => {
                            return <MenuItem key={i} value={date}>{moment(date).format('DD MMMM YYYY')}</MenuItem>
                        })}
                    </Select>
                </FormControl>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={darkState ? 'secondary' : 'primary'}>
                    Annuler
                </Button>
                <Button onClick={() => handleAccept(open)} color={darkState ? 'secondary' : 'primary'}>
                    Valider la date
                </Button>
            </DialogActions>
        </Dialog>
  );
}