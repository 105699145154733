import React from 'react'
import {
    Paper,
    Zoom,
    Typography
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

export default function Activities({ specialist, zoom, darkState }) {
    return (
        <Zoom in={zoom} style={{ transitionDelay: '500ms' }}>
            <Paper
                sx={{
                    padding: 1.25,
                    boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)',
                    mt: 3.75
                }}
            >
                <Typography variant='h6' color={darkState ? 'secondary' : 'primary'} style={{fontWeight: 'bold', marginBottom: 10}}>
                    <span>Activité(s)</span>
                </Typography>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Analyse de la Pratique Professionnelle : </span>
                    </Typography>
                    {specialist.activity.app ? <CheckIcon style={{color: 'green', marginLeft: 10}}/> : <CloseIcon style={{color: 'red', marginLeft: 10}} />}
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Coaching individuel et collectif : </span>
                    </Typography>
                    {specialist.activity.coaching ? <CheckIcon style={{color: 'green', marginLeft: 10}}/> : <CloseIcon style={{color: 'red', marginLeft: 10}} />}
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Coaching certifié : </span>
                    </Typography>
                    {specialist.activity.certifiedCoaching ? <CheckIcon style={{color: 'green', marginLeft: 10}}/> : <CloseIcon style={{color: 'red', marginLeft: 10}} />}
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Appui à la résolution de conflits, médiation : </span>
                    </Typography>
                    {specialist.activity.conflictResolutionSupport ? <CheckIcon style={{color: 'green', marginLeft: 10}}/> : <CloseIcon style={{color: 'red', marginLeft: 10}} />}
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Cellules de crise : </span>
                    </Typography>
                    {specialist.activity.crisisUnit ? <CheckIcon style={{color: 'green', marginLeft: 10}}/> : <CloseIcon style={{color: 'red', marginLeft: 10}} />}
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Equête harcèlement : </span>
                    </Typography>
                    {specialist.activity.harassmentInvestigation ? <CheckIcon style={{color: 'green', marginLeft: 10}}/> : <CloseIcon style={{color: 'red', marginLeft: 10}} />}
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Ligne d'écoute : </span>
                    </Typography>
                    {specialist.activity.platform ? <CheckIcon style={{color: 'green', marginLeft: 10}}/> : <CloseIcon style={{color: 'red', marginLeft: 10}} />}
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Chef de projet/audit : </span>
                    </Typography>
                    {specialist.activity.projectManager ? <CheckIcon style={{color: 'green', marginLeft: 10}}/> : <CloseIcon style={{color: 'red', marginLeft: 10}} />}
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Permanence sur site : </span>
                    </Typography>
                    {specialist.activity.pse ? <CheckIcon style={{color: 'green', marginLeft: 10}}/> : <CloseIcon style={{color: 'red', marginLeft: 10}} />}
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Formateur dans la prévention des RPS : </span>
                    </Typography>
                    {specialist.activity.rpsCoach ? <CheckIcon style={{color: 'green', marginLeft: 10}}/> : <CloseIcon style={{color: 'red', marginLeft: 10}} />}
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Appel : </span>
                    </Typography>
                    {specialist.activity.call ? <CheckIcon style={{color: 'green', marginLeft: 10}}/> : <CloseIcon style={{color: 'red', marginLeft: 10}} />}
                </div>
                <div style={{display: 'flex'}}>
                    <Typography color={darkState ? 'secondary' : 'primary'}>
                        <span style={{fontWeight: 'bold'}}>Visio : </span>
                    </Typography>
                    {specialist.activity.visio ? <CheckIcon style={{color: 'green', marginLeft: 10}}/> : <CloseIcon style={{color: 'red', marginLeft: 10}} />}
                </div>
            </Paper>
        </Zoom>
    )
}