import { useReducer } from "react"
import { apiFetch } from "../service/apiFetch"

const initialState = []

const FETCHING_USERS = 'FETCHING_USERS'
const SET_USERS = 'SET_USERS'
const ADD_USERS = 'ADD_USERS'
const UPDATE_USERS = 'UPDATE_USERS'
const DELETE_USERS = 'DELETE_USERS'

export function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCHING_USERS:
            return { ...state, loading: true }
        case SET_USERS:
            return {...state, users: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_USERS:
            return {...state, users: [action.payload, ...state.users]}
        case UPDATE_USERS:
            return {...state, users: action.payload }
        case DELETE_USERS:
            return {...state, users: state.users.filter((i) => {
                return i.uuid !== action.payload
            })}
        default:
            return state
    }
}

export function useUsers() {
    const [state, dispatch] = useReducer(reducer, {    
        users: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        users: state.users,
        totalItems: state.totalItems,
        view: state.view,
        fetchUsers: async function (route, isSubscribed = () => ({current: true})) {
            if ((state.users || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_USERS' })
            const users = await apiFetch(route || '/users', {method: 'GET'})
            if (isSubscribed().current) {
                dispatch({type: 'SET_USERS', payload: users})
            }
        },
        createUser: async function(data, forceResponse) {
            const newUser = await apiFetch('/users', {
                method: 'POST',
                body: JSON.stringify(data),
            }, forceResponse)

            if (newUser.uuid) {
                dispatch({type: 'ADD_USERS', payload: newUser })
            }
            return newUser
        },
        updateUser: async function(userId, data, forceResponse) {
            const updatedUser = await apiFetch('/users/' + userId, {
                method: 'PUT',
                body: JSON.stringify(data),
            }, forceResponse)

            if (!updatedUser.violations && !updatedUser['hydra:description']) {
                dispatch({type: 'UPDATE_USERS', payload: updatedUser })
            }
            return updatedUser
        },
        deleteUser: async function (userId) {
            const deletedUser = await apiFetch('/users/' + userId, {
                method: 'DELETE'
            }, true)
            if (deletedUser && deletedUser.ok) {
                dispatch({type: 'DELETE_USERS', payload: userId})
            }
            return deletedUser
        },
    }
}