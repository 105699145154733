import React from 'react'
import { Zoom, Typography, Paper } from '@mui/material';
import Rating from '@mui/material/Rating';

export default function ChoosenSpecialist({ darkState, zoom, intervention, rating, handleRating }) {

    return (
        <Zoom in={zoom} style={{ transitionDelay: '200ms' }}>
            <Paper style={{padding: 20}}>
                <Typography variant='h6' style={{color: darkState ? '#7fe1dd' : '#12047D'}}>Spécialiste de l'intervention : </Typography>
                <div style={{marginLeft: 15, marginTop: 10}}>
                    <Typography>
                        • <span
                            style={{
                                color: darkState ? '#7fe1dd' : '#12047D',
                                fontWeight: 'bold',
                            }}
                        >
                            Nom : 
                        </span> {intervention.specialist.lastName}
                    </Typography>
                    <Typography>
                        • <span
                            style={{
                                color: darkState ? '#7fe1dd' : '#12047D',
                                fontWeight: 'bold',
                            }}
                        >
                            Prénom : 
                        </span> {intervention.specialist.firstName}
                    </Typography>
                    <Typography>
                        • <span
                            style={{
                                color: darkState ? '#7fe1dd' : '#12047D',
                                fontWeight: 'bold',
                            }}
                        >
                            Email : 
                        </span> {intervention.specialist.email}
                    </Typography>
                    <Typography>
                        • <span
                            style={{
                                color: darkState ? '#7fe1dd' : '#12047D',
                                fontWeight: 'bold',
                            }}
                        >
                            N° Mobile pro
                        : </span> {intervention.specialist.mobilePro}
                    </Typography>
                    <Typography>
                        • <span
                            style={{
                                color: darkState ? '#7fe1dd' : '#12047D',
                                fontWeight: 'bold',
                            }}
                        >
                            N° Fixe 
                        : </span> {intervention.specialist.phone}
                    </Typography>
                    {intervention.travelDistance && <Typography>
                        • <span
                            style={{
                                color: darkState ? '#7fe1dd' : '#12047D',
                                fontWeight: 'bold',
                            }}
                        >
                            Distance : 
                        </span> {intervention.travelDistance} km
                    </Typography>}
                </div>
                <div style={{display: 'flex', marginTop: 20, marginLeft: 15}}>
                    <Typography>
                        • <span 
                            style={{
                                marginRight: 30,
                                color: darkState ? '#7fe1dd' : '#12047D',
                                fontWeight: 'bold',
                            }}
                        >
                            Note de l'intervention : 
                        </span>
                    </Typography>
                    <Rating
                        name="rating"
                        value={rating}
                        onChange={(event, newValue) => {
                            handleRating(newValue);
                        }}
                    />
                </div>
            </Paper>
        </Zoom>
    )
}