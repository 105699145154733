import React from 'react'
import {
    Paper,
    Zoom,
    Typography,
} from '@mui/material';

export default function Expertise({ specialist, zoom, darkState }) {
    return (
        <Zoom in={zoom} style={{ transitionDelay: '400ms' }}>
            <Paper
                sx={{
                    padding: 1.25,
                    boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)',
                    mt: 3.75
                }}
            >
                <Typography variant='h6' color={darkState ? 'secondary' : 'primary'} style={{fontWeight: 'bold', marginBottom: 10}}>
                    <span>Expertise(s)</span>
                </Typography>
                {specialist.expertises && specialist.expertises.length ? specialist.expertises.map((expert) => {
                    return <React.Fragment key={expert.id}>
                        <Typography style={{marginLeft: 50}}>• {expert.name} :</Typography>
                        <Typography style={{marginLeft: 80}}>{expert.description}</Typography>
                    </React.Fragment>
                }) : 
                <Typography style={{marginLeft: 50}}>Pas d'expertise...</Typography>}
            </Paper>
        </Zoom>
    )
}