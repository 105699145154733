import React, { useState } from 'react'
import {
    Paper,
    Zoom,
    Typography,
    IconButton,
    Grid,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment'
import AddVacation from './AddVacation';
import DeletedConfirmationPopup from '../../../../components/DeletedConfirmationPopup';
import { apiFetch } from '../../../../service/apiFetch';
import { useNavigate, useParams } from 'react-router-dom';
import { TransitionLeft } from '../../../../helpers/utils';

export default function Vacation({
    specialist,
    zoom,
    darkState,
    setOpenSnackbar,
    setText,
    setTransition,
    setSeverity,
    setSpecialist
}) {
    const [open, setOpen] = useState(false)
    const [openDeletePopup, setOpenDeletePopup] = useState(false)
    const [loading, setLoading] = useState(false)
    const [holiday, setHoliday] = useState(false)
    const { id } = useParams()
    let navigate = useNavigate()

    const handleAddVacationPopup = () => {
        !loading && setOpen(!open)
    }

    const handleDeleteVacationPopup = (e, holiday) => {
        setHoliday(holiday)
        setOpenDeletePopup(!openDeletePopup)
    }

    const handleDelete = async () => {
        const deletedHoliday = await apiFetch(`/holidays/${holiday.uuid}`, {method: 'DELETE'})

        if (deletedHoliday && deletedHoliday.ok) {
            setText('Les vacances ont été supprimé')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenDeletePopup(!openDeletePopup)
            setOpenSnackbar(true)
            const specialistFetch = await apiFetch(`/specialists/${id}`)
            if (specialistFetch && specialistFetch.uuid) {
                setSpecialist(specialistFetch)
            } else {
                navigate('/specialists')
            }
        } else {
            setText((deletedHoliday && deletedHoliday['hydra:description']) || 'Erreur lors de la suppression')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
    }

    return (
        <Zoom in={zoom}>
            <Paper
                sx={{
                    padding: 1.25,
                    boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)',
                    mt: 3.75
                }}
            >
                <div style={{display: 'flex'}}>
                    <Typography
                        variant='h6'
                        color={darkState ? 'secondary' : 'primary'}
                        style={{marginBottom: 10, flex: '1 1 100%', fontWeight: 'bold'}}
                    >
                        <span>Congé(s)</span>
                    </Typography>
                    <IconButton color={darkState? 'secondary' : 'primary'} onClick={handleAddVacationPopup}>
                        <AddIcon />
                    </IconButton>
                </div>
                {specialist.nextHoliday && specialist.nextHoliday.length ? specialist.nextHoliday.map((holiday) => {
                    return <Grid container spacing={3} key={holiday.id}>
                        <Grid item xs={12} sm={10}>
                            <Typography style={{marginLeft: 50}}>
                                • Du {moment(holiday.startDate).format('DD MMMM YYYY')} au {moment(holiday.endDate).format('DD MMMM YYYY')}
                                {holiday.comment && ' : '}
                            </Typography>
                            <Typography style={{marginLeft: 80}}>{holiday.comment}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={2} color={darkState? 'secondary' : 'primary'} onClick={(e) => handleDeleteVacationPopup(e, holiday)}>
                            <IconButton color='inherit'>
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                }) : 
                <Typography style={{marginLeft: 50}}>Pas de vacance prévue...</Typography>}
                <DeletedConfirmationPopup
                    handleAccept={handleDelete}
                    open={openDeletePopup}
                    handleClose={handleDeleteVacationPopup}
                    darkState={darkState}
                />
                <AddVacation
                    loading={loading}
                    setLoading={setLoading}
                    setSpecialist={setSpecialist}
                    darkState={darkState}
                    open={open}
                    setOpenSnackbar={setOpenSnackbar}
                    setText={setText}
                    setTransition={setTransition}
                    setSeverity={setSeverity}
                    handleAddVacationPopup={handleAddVacationPopup}
                />
            </Paper>
        </Zoom>
    )
}