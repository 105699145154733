import { useReducer } from "react"
import { apiFetch } from "../service/apiFetch"

const initialState = []

const FETCHING_EXPERTISES = 'FETCHING_EXPERTISES'
const SET_EXPERTISES = 'SET_EXPERTISES'
const ADD_EXPERTISES = 'ADD_EXPERTISES'
const UPDATE_EXPERTISES = 'UPDATE_EXPERTISES'
const DELETE_EXPERTISES = 'DELETE_EXPERTISES'

export function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCHING_EXPERTISES:
            return { ...state, loading: true }
        case SET_EXPERTISES:
            return {...state, expertises: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_EXPERTISES:
            return {...state, expertises: [action.payload, ...state.expertises]}
        case UPDATE_EXPERTISES:
            return {...state, expertises: action.payload }
        case DELETE_EXPERTISES:
            return {...state, expertises: state.expertises.filter((i) => {
                return i.uuid !== action.payload
            })}
        default:
            return state
    }
}

export function useExpertises() {
    const [state, dispatch] = useReducer(reducer, {    
        expertises: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        expertises: state.expertises,
        totalItems: state.totalItems,
        view: state.view,
        fetchExpertises: async function (route, isSubscribed = () => ({current: true})) {
            if ((state.expertises || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_EXPERTISES' })
            const expertises = await apiFetch(route || '/expertises', {method: 'GET'})

            if (isSubscribed().current && expertises && expertises['hydra:member']) {
                dispatch({type: 'SET_EXPERTISES', payload: expertises})
            }
        },
        createExpertise: async function(data, notIterate) {
            const newExpertise = await apiFetch('/expertises', {
                method: 'POST',
                body: JSON.stringify(data),
            })

            if (newExpertise.uuid && !notIterate) {
                dispatch({type: 'ADD_EXPERTISES', payload: newExpertise })
            }
            return newExpertise
        },
        updateExpertise: async function(interventionId, data, forceResponse) {
            const updatedExpertise = await apiFetch('/expertises/' + interventionId, {
                method: 'PUT',
                body: JSON.stringify(data),
            }, forceResponse)

            if (!updatedExpertise.violations && !updatedExpertise['hydra:description']) {
                dispatch({type: 'UPDATE_EXPERTISES', payload: updatedExpertise })
            }
            return updatedExpertise
        },
        deleteExpertise: async function (interventionId) {
            const deletedExpertise = await apiFetch('/expertises/' + interventionId, {
                method: 'DELETE'
            }, true)
            if (deletedExpertise && deletedExpertise.ok) {
                dispatch({type: 'DELETE_EXPERTISES', payload: interventionId})
            }
            return deletedExpertise
        },
    }
}