import React, { useEffect, useState } from 'react'
import {
    Grid,
    Paper,
    Zoom,
    Typography,
    Divider,
    LinearProgress,
    Button,
    Toolbar,
    CircularProgress,
    IconButton
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {convertActivitiesToArray, formatAddress, TransitionLeft} from '../../helpers/utils';
import { apiFetch } from '../../service/apiFetch';
import InterventionInformation from './ManageInterventionItem/InterventionInformation';
import InterventionRequest from './ManageInterventionItem/InterventionRequest';
import MapContainer from '../SpecialistsMap'
import SpecialistCard from './ManageInterventionItem/SpecialistCard';
import InterventionSelected from './ManageInterventionItem/InterventionSelected';
import SpecialistFilters from '../../components/SpecialistFilters';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ChoosenSpecialist from './ManageInterventionItem/ChoosenSpecialist';
import SelectDatePopup from './ManageInterventionItem/SelectDatePopup';
import moment from 'moment-timezone';
import ModifyPopup from './ManageInterventionItem/ModifyPopup';
import { handleFormError } from '../../service/security/formError';

export default function ManageIntervention({
    newCoordonnate,
    setNewCoordonnate,
    filterList,
    setFilterList,
    activity,
    setActivity,
    activities,
    setActivities,
    language,
    setLanguage,
    languages,
    setLanguages,
    speciality,
    setSpeciality,
    specialities,
    setSpecialities,
    administrativeType,
    setAdministrativeType,
    administrativeTypes,
    setAdministrativeTypes,
    certification,
    setCertification,
    certifications,
    setCertifications,
    specialistType,
    setSpecialistType,
    specialistTypes,
    setSpecialistTypes,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    setState,
    state,
    darkState,
    zoom,
    setOpenSnackbar,
    setText,
    setTransition,
    setSeverity,
    phoneResponsive 
}) {
    const [specialists, setSpecialists] = useState(null);
    const [intervention, setIntervention] = useState(false)
    const [loading, setLoading] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [refreshMap, setRefreshMap] = useState(false)
    const [openDatePopup, setOpenDatePopup] = useState(false)
    const [openModifyPopup, setOpenModifyPopup] = useState(false)
    const [selectedDate, setSelectedDate] = useState('')
    const [rating, setRating] = useState(0)
    const [mapZoom, setMapZoom] = useState(8)
    const [mapCenter, setMapCenter] = useState({})
    const [error, setError] = useState({})
    const [expanded, setExpanded] = useState(false)
    const [selectedSpecialists, setSelectedSpecialists] = useState([])
    const [stateInformation, setStateInformation] = useState({
        startHour: '',
        endHour: '',
        startMinute: '',
        endMinute: '',
    })
    const [sendBackState, setSendBackState] = useState([])
    const [startDates, setStartDates] = useState([moment()]);
    const [newAddress, setNewAddress] = useState(null);
    const [newAddressCoordonnate, setAddressNewCoordonnate] = useState(null);
    const [comment, setComment] = useState('')
    const [remuneration, setRemuneration] = useState('')
    let navigate = useNavigate()
    const { id } = useParams()

    useEffect(() => {
        (async () => {
            setNewCoordonnate(null)
            setAdministrativeType([administrativeTypes.find((item) => item.name === 'Pros-Consulte')])

            let activityFilter = activity

            if (activity) {
                if (!activity.find(item => item.id === 'crisisUnit')) {
                    activityFilter.push({id: 'crisisUnit', name: 'Cellule de crise', '@type': 'Activity'})
                }
            } else {
                activityFilter = convertActivitiesToArray({crisisUnit: true})
            }

            setActivity(activityFilter)

            setFilterList([
                speciality,
                [{'@type': 'firstName',name: firstName}],
                [{'@type': 'lastName',name: lastName}],
                language,
                [administrativeTypes.find((item) => item.name === 'Pros-Consulte')],
                specialistType,
                certification,
                state,
                activityFilter
            ])

            const interventionFetch = await apiFetch(`/interventions/${id}`)
            if (interventionFetch && interventionFetch.uuid) {
                setRating(interventionFetch.rating)
                setIntervention(interventionFetch)

                const planningFrom = moment(interventionFetch.availableDates[0])
                const planningTo = moment(interventionFetch.availableDates[interventionFetch.availableDates.length - 1])
                planningFrom.set({h: interventionFetch.startTime.slice(0, 2), m: interventionFetch.startTime.slice(-2)})
                planningTo.set({h: interventionFetch.endTime.slice(0, 2), m: interventionFetch.endTime.slice(-2)})

                const specialistsFetch = await apiFetch(`/specialists?distance_from=${interventionFetch.address.latitude},${interventionFetch.address.longitude},1000&pagination=false&administrativeTypes.name[]=Pros-Consulte&activity.crisisUnit=true&planning_from=${encodeURIComponent(planningFrom.format())}&planning_to=${encodeURIComponent(planningTo.format())}&`)
                if (specialistsFetch && specialistsFetch['hydra:member']) {
                    handleInterventionRequestResponse(specialistsFetch['hydra:member'], interventionFetch, getLatLngOffset, mapZoom)
                    setSpecialists(specialistsFetch)
                } else {
                    setSeverity('error')
                    setText('Error lors de la récupération des données')
                    setTransition(() => TransitionLeft)
                    setOpenSnackbar(true)
                    navigate('/interventions')
                }
            } else {
                setSeverity('error')
                setText('Error lors de la récupération des données')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                navigate('/interventions')
            }
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, navigate, setOpenSnackbar, setSeverity, setTransition, setText])

    const handleBack = () => {
        navigate('/interventions')
    }

    const handleRemoveSelected = (selected) => {
        selected.isSelected = false
        selected.address.latitude = selected.address.latitude - getLatLngOffset(mapZoom).lat
        selected.address.longitude = selected.address.longitude + getLatLngOffset(mapZoom).lng
        setSelectedSpecialists(selectedSpecialists.filter((spe) => spe.uuid !== selected.uuid))
    }

    const handleChange = (specialist) => {
        if (specialist.isSelected) {
            specialist.isSelected = false
            specialist.address.latitude = specialist.address.latitude - getLatLngOffset(mapZoom).lat
            specialist.address.longitude = specialist.address.longitude + getLatLngOffset(mapZoom).lng
            setSelectedSpecialists(selectedSpecialists.filter((spe) => spe.uuid !== specialist.uuid))
        } else {
            specialist.isSelected = true
            specialist.address.latitude = specialist.address.latitude + getLatLngOffset(mapZoom).lat
            specialist.address.longitude = specialist.address.longitude - getLatLngOffset(mapZoom).lng
            setSelectedSpecialists([...selectedSpecialists, specialist])
            setMapCenter({lat: specialist.address.latitude, lng: specialist.address.longitude})
        }
    }


    const getLatLngOffset = (zoom, specialOffset) => {
        switch(zoom) {
            case 0:
                return specialOffset ? {lat: 17.76, lng: 16.92} : {lat: 28.52, lng: 34}
            case 1:
                return specialOffset ? {lat: 9.88, lng: 7.96} : {lat: 17.76, lng: 16.92}
            case 2:
                return specialOffset ? {lat: 4.88, lng: 4.48} : {lat: 9.88, lng: 7.96}
            case 3:
                return specialOffset ? {lat: 2.72, lng: 2.24} : {lat: 4.88, lng: 4.48}
            case 4:
                return specialOffset ? {lat: 1.36, lng: 1.12} : {lat: 2.72, lng: 2.24}
            case 5:
                return specialOffset ? {lat: 0.68, lng: 0.56} : {lat: 1.36, lng: 1.12}
            case 6:
                return specialOffset ? {lat: 0.34, lng: 0.28} : {lat: 0.68, lng: 0.56}
            case 7:
                return specialOffset ? {lat: 0.17, lng: 0.14} : {lat: 0.34, lng: 0.28}
            case 8:
                return specialOffset ? {lat: 0.085, lng: 0.065} : {lat: 0.17, lng: 0.14}
            case 9:
                return specialOffset ? {lat: 0.0425, lng: 0.033} : {lat: 0.085, lng: 0.065}
            case 10:
                return specialOffset ? {lat: 0.0213, lng: 0.0165} : {lat: 0.0425, lng: 0.033}
            case 11:
                return specialOffset ? {lat: 0.0106, lng: 0.00825} : {lat: 0.0213, lng: 0.0165}
            case 12:
                return specialOffset ? {lat: 0.0053, lng: 0.0041} : {lat: 0.0106, lng: 0.00825}
            case 13:
                return specialOffset ? {lat: 0.0027, lng: 0.00205} : {lat: 0.0053, lng: 0.0041}
            case 14:
                return specialOffset ? {lat: 0.0013, lng: 0.001025} : {lat: 0.0027, lng: 0.00205}
            case 15:
                return specialOffset ? {lat: 0.0007, lng: 0.0005125} : {lat: 0.0013, lng: 0.001025}
            case 16:
                return specialOffset ? {lat: 0.0003, lng: 0.00025625} : {lat: 0.0007, lng: 0.0005125}
            case 17:
                return specialOffset ? {lat: 0.00015, lng: 0.000128125} : {lat: 0.0003, lng: 0.00025625}
            default:
                return specialOffset ? {lat: 0, lng: 0} : {lat: 0, lng: 0}
        }
    }

    const handleSelectDate = (request) => {
        if (intervention && intervention.availableDates && intervention.availableDates.length === 1) {
            handleSelectSpecialist(request, intervention.availableDates[0])
        } else {
            setOpenDatePopup(request)
        }
    }

    const handleSelectSpecialist = async (request, automaticallySelectedDate) => {
        setOpenDatePopup(false)
        setDisabled(true)
        const selectSpecialist = await apiFetch(`/interventions/${id}`, {
            method: 'PUT',
            body: JSON.stringify({specialist: request.specialist['@id'], selectedDate: selectedDate || automaticallySelectedDate})
        })
        if (selectSpecialist && selectSpecialist.uuid) {
            setText('Vous venez de choisir un spécialiste')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setIntervention(selectSpecialist)
        } else {
            setText((selectSpecialist && selectSpecialist['hydra:description']) || 'Erreur lors du choix du spécialiste !')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setDisabled(false)
    }

    const handleSubmitInterventionRequest = async () => {
        setLoading(true)
        const interventionRequest = await apiFetch(`/intervention_requests/multiple`, {
            method: 'POST',
            body: JSON.stringify({
                intervention: `/interventions/${id}`,
                specialists: formatInterventionRequest(selectedSpecialists)
            })
        })

        if (interventionRequest && interventionRequest.uuid) {
            setText('L\'intervention a été mis à jour')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setIntervention(interventionRequest)
        } else {
            setText((interventionRequest && interventionRequest['hydra:description']) || 'Erreur lors de l\'envoie des SMS !')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        selectedSpecialists.map((specialist) =>  {
            if (specialist.isSelected) {
                specialist.address.latitude = specialist.address.latitude - getLatLngOffset(mapZoom).lat
                specialist.address.longitude = specialist.address.longitude + getLatLngOffset(mapZoom).lng
            }
            specialist.isSelected = false
            return false
        })
        setSelectedSpecialists([])
        setLoading(false)
    }

    const formatInterventionRequest = (specialist) => {
        const tab = []
        specialist.map((s) => {
            return tab.push(s['@id'])
        })
        return tab
    }

    const handleRating = async (newValue) => {
        setRating(newValue)
        const changeRating = await apiFetch(`/interventions/${id}`, {
            method: 'PUT',
            body: JSON.stringify({
                rating: newValue,
            })
        })

        if (changeRating && changeRating.uuid) {
            setText('La note a été mis à jour')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
        } else {
            setText((changeRating && changeRating['hydra:description']) || 'Erreur lors du changement de la note !')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
            setRating(rating)
        }
    }

    const handleModifyPopup = () => {
        setStateInformation({
            startHour: intervention.startTime.slice(0, 2),
            endHour: intervention.endTime.slice(0, 2),
            startMinute: intervention.startTime.slice(-2),
            endMinute: intervention.endTime.slice(-2),
        })
        const formatDate = intervention.availableDates.map(date => moment(date))
        setStartDates(formatDate)
        setComment(intervention.comment)
        setRemuneration(intervention.remuneration)
        setOpenModifyPopup(true)
    }

    const handleStartDateChange = (e, i) => {
        setLoading(false)
        const findDate = startDates.map((startDate, index) => {
            if (index === i) {
                return e
            }
            return startDate
        })
        setStartDates(findDate)
    }

    const handleChangeTime = (event) => {
        setStateInformation({ ...stateInformation, [event.target.name]: event.target.value })
    }

    const handleAddDate = () => {
        setStartDates([...startDates, moment()])
    }

    const handleRemoveDate = (e) => {
        const removeDate = startDates.filter(startDate => startDate._d !== e._d)
        setStartDates(removeDate)
    }

    const handleModify = async () => {
        setError({})
        let data = {
            address: formatAddress(newAddress, newAddressCoordonnate),
            remuneration: parseInt(remuneration),
            availableDates: startDates.map((startDate => moment(startDate).format('Y-M-D HH:mm:ss'))),
            comment: comment,
            startTime: stateInformation.startHour && stateInformation.startMinute ?
                `${stateInformation.startHour}:${stateInformation.startMinute}` : '',
            endTime: stateInformation.endHour && stateInformation.endMinute ?
                `${stateInformation.endHour}:${stateInformation.endMinute}` : '',
        }

        const updateIntervention = await apiFetch(`/interventions/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        })

        if (updateIntervention && updateIntervention.uuid) {
            setText('L\'intervention a été mise à jour')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            const interventionFetch = await apiFetch(`/interventions/${id}`)
            if (interventionFetch && interventionFetch.uuid) {
                setRating(interventionFetch.rating)
                setIntervention(interventionFetch)

                const planningFrom = moment(interventionFetch.availableDates[0])
                const planningTo = moment(interventionFetch.availableDates[interventionFetch.availableDates.length - 1])
                planningFrom.set({h: interventionFetch.startTime.slice(0, 2), m: interventionFetch.startTime.slice(-2)})
                planningTo.set({h: interventionFetch.endTime.slice(0, 2), m: interventionFetch.endTime.slice(-2)})

                const specialistsFetch = await apiFetch(
                    `/specialists?distance_from=${interventionFetch.address.latitude},
                    ${interventionFetch.address.longitude},1000&
                    pagination=false&
                    administrativeTypes.name[]=Pros-Consulte&
                    activity.crisisUnit=true&
                    planning_from=${encodeURIComponent(planningFrom.format())}&
                    planning_to=${encodeURIComponent(planningTo.format())}&`)
                if (specialistsFetch && specialistsFetch['hydra:member']) {
                    handleInterventionRequestResponse(specialistsFetch['hydra:member'], interventionFetch, getLatLngOffset, mapZoom)
                    setSpecialists(specialistsFetch)
                    setOpenModifyPopup(false)
                } else {
                    setSeverity('error')
                    setText('Error lors de la récupération des données')
                    setTransition(() => TransitionLeft)
                    setOpenSnackbar(true)
                    navigate('/interventions')
                }
            } else {
                setSeverity('error')
                setText('Error lors de la récupération des données')
                setTransition(() => TransitionLeft)
                setOpenSnackbar(true)
                navigate('/interventions')
            }

        } else if (updateIntervention.violations) {
            const errorField = handleFormError(updateIntervention, ['startTime', 'endTime' ,'comment', 'address', 'availableDates', 'remuneration'])
            setError(errorField)
            setText('Erreur lors de la modification')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        } else {
            setText((updateIntervention && updateIntervention['hydra:description']) || 'Erreur lors de la création')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
    }

    const handleSendBack = async () => {
        const data = {
            interventionRequests: formatInterventionRequests(sendBackState)
        }
        const sendBack = await apiFetch('/intervention_requests/send-back-sms', {
            method: 'POST',
            body: JSON.stringify(data)
        })
        if (sendBack === 'SMS envoyés.') {
            setText(sendBack)
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
        } else {
            setText('Erreur lors de l\'envoie du sms')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
    }

    const formatInterventionRequests = (state) => {
        let arr = []
        state.map((item) => arr.push(`/intervention_requests/${Object.keys(item)}`))
        return arr
    }

    return (
        <Zoom in={zoom}>
            <div style={{paddingLeft: 10, overflowX: 'hidden'}}>
                <Paper style={{paddingBottom: 0, marginBottom: 30}}>
                    <Toolbar>
                        <Typography variant='h5' color={darkState ? 'secondary' : 'primary'} sx={{flex: '1 1 100%'}}>
                            Gérer une intervention
                        </Typography>
                        {!phoneResponsive ? <>
                                <Button 
                                    variant='contained'
                                    color={darkState ? 'secondary' : 'primary'}
                                    onClick={handleModifyPopup} 
                                    style={{marginRight: 10}}
                                >
                                    Modifier
                                </Button>
                                <Button variant='contained' color={darkState ? 'secondary' : 'primary'} onClick={handleBack}>
                                    Retour
                                </Button>
                            </> :
                            <IconButton color='inherit' onClick={handleBack} style={{marginLeft: 15}}>
                                <KeyboardBackspaceIcon />
                            </IconButton>}
                    </Toolbar>
                    <Divider />
                    {id && intervention ?
                        <InterventionInformation
                            handleModify={handleModifyPopup}
                            phoneResponsive={phoneResponsive}
                            darkState={darkState}
                            zoom={zoom}
                            intervention={intervention}
                        /> :
                        <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
                </Paper>
                {intervention &&
                !intervention.specialist &&
                intervention.status !== 'Canceled' &&
                administrativeTypes && administrativeTypes.length &&
                    <SpecialistFilters
                        isFromMap={true}
                        newCoordonnate={newCoordonnate}
                        setNewCoordonnate={setNewCoordonnate}
                        activities={activities}
                        setActivities={setActivities}
                        activity={activity}
                        setActivity={setActivity}
                        setRefresh={setRefreshMap}
                        setFilterList={setFilterList}
                        filterList={filterList}
                        state={state}
                        setState={setState}
                        language={language}
                        setLanguage={setLanguage}
                        languages={languages}
                        setLanguages={setLanguages}
                        speciality={speciality}
                        setSpeciality={setSpeciality}
                        specialities={specialities}
                        setSpecialities={setSpecialities}
                        administrativeType={administrativeType}
                        setAdministrativeType={setAdministrativeType}
                        administrativeTypes={administrativeTypes}
                        setAdministrativeTypes={setAdministrativeTypes}
                        certification={certification}
                        setCertification={setCertification}
                        certifications={certifications}
                        setCertifications={setCertifications}
                        specialistType={specialistType}
                        setSpecialistType={setSpecialistType}
                        specialistTypes={specialistTypes}
                        setSpecialistTypes={setSpecialistTypes}
                        firstName={firstName}
                        setFirstName={setFirstName}
                        lastName={lastName}
                        setLastName={setLastName}
                        phoneResponsive={phoneResponsive}
                        setSpecialists={setSpecialists}
                        firstFilter={`distance_from=${intervention.address && intervention.address.latitude},${intervention.address && intervention.address.longitude}, 1000`}
                        darkState={darkState}
                        expanded={expanded}
                        setExpanded={setExpanded}
                        zoom={zoom}
                    />}
                {intervention && intervention.specialist &&
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                            <ChoosenSpecialist
                                rating={rating}
                                handleRating={handleRating}
                                darkState={darkState}
                                zoom={zoom}
                                intervention={intervention}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <InterventionRequest
                                handleSendBack={handleSendBack}
                                state={sendBackState}
                                setState={setSendBackState}
                                darkState={darkState}
                                zoom={zoom}
                                intervention={intervention}
                            />
                        </Grid>
                    </Grid>}
                {specialists && intervention && !intervention.specialist && intervention.status !== 'Canceled'  ?
                    <>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={3}>
                                <SpecialistCard
                                    phoneResponsive={phoneResponsive}
                                    handleChange={handleChange}
                                    selectedSpecialists={selectedSpecialists}
                                    setSelectedSpecialists={setSelectedSpecialists}
                                    zoom={zoom}
                                    specialists={specialists}
                                    darkState={darkState}
                                />
                                <InterventionSelected
                                    phoneResponsive={phoneResponsive}
                                    loading={loading}
                                    handleSubmitInterventionRequest={handleSubmitInterventionRequest}
                                    handleRemoveSelected={handleRemoveSelected}
                                    selectedSpecialists={selectedSpecialists}
                                    darkState={darkState}
                                    zoom={zoom}
                                    intervention={intervention}
                                />
                            </Grid>
                            <Grid item xs={12} sm={9}>
                                {!refreshMap && <MapContainer
                                    newCoordonnate={newCoordonnate}
                                    mapCenter={mapCenter}
                                    handleChange={handleChange}
                                    getLatLngOffset={getLatLngOffset}
                                    setMapZoom={setMapZoom}
                                    selectedSpecialists={selectedSpecialists}
                                    setSelectedSpecialists={setSelectedSpecialists}
                                    intervention={intervention}
                                    specialists={specialists}
                                    mapZoom={mapZoom}
                                />}
                            </Grid>
                        </Grid>
                        <InterventionRequest
                            handleSendBack={handleSendBack}
                            state={sendBackState}
                            setState={setSendBackState}
                            handleSelectDate={handleSelectDate}
                            disabled={disabled}
                            darkState={darkState}
                            zoom={zoom}
                            intervention={intervention}
                        />
                    </> :
                    (!intervention || !intervention.specialist) && intervention.status !== 'Canceled' && <div style={{textAlign: 'center'}}>
                        <CircularProgress color={darkState ? 'secondary' : 'primary'} />
                    </div>}
                <SelectDatePopup
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    open={openDatePopup}
                    handleClose={() => setOpenDatePopup(false)}
                    intervention={intervention}
                    darkState={darkState}
                    handleAccept={handleSelectSpecialist}
                />
                {intervention && <ModifyPopup
                    intervention={intervention}
                    open={openModifyPopup}
                    handleClose={() => setOpenModifyPopup(false)}
                    darkState={darkState}
                    phoneResponsive={phoneResponsive}
                    error={error}
                    handleAddDate={handleAddDate}
                    handleChangeTime={handleChangeTime}
                    handleRemoveDate={handleRemoveDate}
                    handleStartDateChange={handleStartDateChange}
                    handleAccept={handleModify}
                    startDates={startDates}
                    state={stateInformation}
                    setNewAddress={setNewAddress}
                    setNewCoordonnate={setAddressNewCoordonnate}
                    loading={loading}
                    setRemuneration={setRemuneration}
                    remuneration={remuneration}
                    setComment={setComment}
                    comment={comment}
                />}
            </div>
        </Zoom>
    )
}

function handleInterventionRequestResponse(specialists, intervention, getLatLngOffset, mapZoom) {
    specialists.map((specialist) => {
        return intervention.interventionRequests.find((request) => {
            if (specialist.id === request.specialist.id) {
                addRightAnswer(request.status, specialist)
                specialist.address.latitude = specialist.address.latitude + getLatLngOffset(mapZoom).lat
                specialist.address.longitude = specialist.address.longitude - getLatLngOffset(mapZoom).lng
            }
            return specialist.id === request.specialist.id
        })
    })
}

function addRightAnswer(answer, specialist) {
    switch(answer) {
        case 'Pending':
            specialist.isWaiting = true
            return specialist.hasCutomIcon = true
        case 'Refused':
            specialist.hasCutomIcon = true
            return specialist.hasRefused = true
        default:
            specialist.hasCutomIcon = true
            return specialist.hasAccepted = true
    }
}