import React from 'react'
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction"
import ListItemText from "@mui/material/ListItemText"
import Avatar from "@mui/material/Avatar"
import IconButton from "@mui/material/IconButton"
import Grid from "@mui/material/Grid"
import DeleteIcon from "@mui/icons-material/Delete"
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/Add';

export default function UploadPdf({ setPdf, title }) {
    const [docs, setDocs] = React.useState([]);
    const [docUrl, setDocUrl] = React.useState(null);


    const handleFileChanged = e => {
      const inputFiles = document.querySelector("#inputPdf");
      const objectFiles = inputFiles.files;
      const docs = Object.keys(objectFiles).map(index => {
          return objectFiles[index]
      });
      setDocs(docs)
      setPdf(docs)
      const reader = new FileReader()
      reader.onload = e => {
          setDocUrl(e.target.result)
      }
      reader.readAsDataURL(e.target.files[0])
    };
    
    const handleDeleteFile = lastModified => _ => {
      const updatedFiles = docs.filter(f => f.lastModified !== lastModified)
      setDocs(updatedFiles)
      setPdf(updatedFiles)
    };

    return (
        <Grid container spacing={2} style={{marginLeft: '-7px', marginBottom: 30}}>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    onClick={() => document.querySelector("#inputPdf").click()}
                    disabled={docs.length > 0}
                    size="small"
                    style={{backgroundColor: '#ffae00'}}
                >
                    <AddIcon/>
                    {title}
                </Button>
                <input
                    type="file"
                    id="inputPdf"
                    style={{ visibility: "hidden" }}
                    multiple
                    onChange={handleFileChanged}
                    accept="*"
                />
            </Grid>
            <Grid item xs={3} md={5}>
                <div>
                    {docs.length > 0 && <List>
                        {docs.map(f => (
                            <ListItem key={f.lastModified}>
                                <ListItemAvatar>
                                    <Avatar alt="Pdf" src={docUrl} />
                                </ListItemAvatar>
                                <ListItemText
                                    primary={f.name}
                                    secondary={f.type}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        color='inherit'
                                        edge="end"
                                        aria-label="delete"
                                        onClick={handleDeleteFile(f.lastModified)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                        ))}
                    </List>}
                </div>
            </Grid>
        </Grid>
    )
}