import { useReducer } from "react"
import { apiFetch } from "../service/apiFetch"

const initialState = []

const FETCHING_CVS = 'FETCHING_CVS'
const SET_CVS = 'SET_CVS'
const ADD_CVS = 'ADD_CVS'
const UPDATE_CVS = 'UPDATE_CVS'
const DELETE_CVS = 'DELETE_CVS'

export function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCHING_CVS:
            return { ...state, loading: true }
        case SET_CVS:
            return {...state, cvs: action.payload['hydra:member'], loading: false, totalItems: action.payload['hydra:totalItems'], view: action.payload['hydra:view']}
        case ADD_CVS:
            return {...state, cvs: [action.payload, ...state.cvs]}
        case UPDATE_CVS:
            const tempState = state.cvs.filter((i) => {
                return i.uuid !== action.payload.uuid
            })
            return {...state, cvs: [action.payload, ...tempState]}
        case DELETE_CVS:
            return {...state, cvs: state.cvs.filter((i) => {
                return i.uuid !== action.payload
            })}
        default:
            return state
    }
}

export function useCvs() {
    const [state, dispatch] = useReducer(reducer, {    
        cvs: null,
        loading: false,
        totalItems: 0,
        view: null,
    })

    return {
        cvs: state.cvs,
        totalItems: state.totalItems,
        view: state.view,
        fetchCvs: async function (id, isSubscribed = () => ({current: true})) {
            if ((state.cvs || state.loading || state.totalItems)) {
                return
            }
            dispatch({ type: 'FETCHING_CVS' })
            const cvs = await apiFetch(`/cvs?specialist.uuid=${id}`, {method: 'GET'})

            if (isSubscribed().current && cvs && cvs['hydra:member']) {
                dispatch({type: 'SET_CVS', payload: cvs})
            }
        },
        createCv: async function(data, notIterate) {
            const newCv = await apiFetch('/cvs', {
                method: 'POST',
                body: JSON.stringify(data),
            })

            if (newCv.name && !notIterate) {
                dispatch({type: 'ADD_CVS', payload: newCv })
            }
            return newCv
        },
        updateCv: async function(cvId, data, notIterate) {
            const updatedCv = await apiFetch('/cvs/' + cvId, {
                method: 'PUT',
                body: JSON.stringify(data),
            })

            if (!updatedCv.violations && !updatedCv['hydra:description'] && !notIterate) {
                dispatch({type: 'UPDATE_CVS', payload: updatedCv })
            }
            return updatedCv
        },
        deleteCv: async function (documentId) {
            const deletedCv = await apiFetch('/cvs/' + documentId, {
                method: 'DELETE'
            }, true)
            if (deletedCv && deletedCv.ok) {
                dispatch({type: 'DELETE_CVS', payload: documentId})
            }
            return deletedCv
        },
    }
}