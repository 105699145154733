import React from 'react'

export default function ProgressBar({ style, customStyle }) {

    return (
        <div 
            style={
                customStyle ? 
                    {width: customStyle.width, 
                    marginTop: customStyle.marginTop, 
                    marginLeft: customStyle.marginLeft
                } : {
                    width: '40%'
                }
            }>
            {style && <div style={{width: `${style.width}%`, backgroundColor: style.color, height: 5}} />}
        </div>
    )
}