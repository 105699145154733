import React, { useState } from 'react'
import { Paper, Zoom, Typography, Divider, Grid, Checkbox, IconButton } from '@mui/material';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import PlanningEventPopup from './PlanningEventPopup';

export default function SpecialistCard({ zoom, specialists, darkState, handleChange, phoneResponsive }) {
    const [openPopup, setOpenPopup] = useState(false)

    const handleShowPopup = (e, data) => {
        setOpenPopup(data);
    }

    return (
        <Zoom in={zoom} style={{ transitionDelay: '800ms' }}>
            <Paper
                style={
                    phoneResponsive ? {
                        padding: 10,
                        marginBottom: 30
                    } : {
                        paddingRight: 0,
                        marginBottom: 30
                    }
                }
                sx={{padding: '10px 40px 30px 40px'}}
            >
                <div style={{marginBottom: 30, paddingRight: 20}} >
                    <Typography variant='h6' color={darkState ? 'secondary' : 'primary'} sx={{p: 2}}>
                        Liste des spécialistes
                    </Typography>
                    <Divider />
                </div>
                <div style={{ maxHeight: 550, overflowY: 'scroll', paddingRight: 20}}>
                    {specialists['hydra:member'].length ? specialists['hydra:member'].map((specialist) => (
                        <div key={specialist.uuid}>
                            {!phoneResponsive ? <Grid container spacing={3} style={{paddingBottom: 10, paddingTop: 10}}>
                                <Grid item xs={12} sm={9}>
                                    <div style={{marginBottom: 10, display: '-webkit-box'}}>
                                        {specialist.administrativeTypes.map((type, index) => {
                                            return <Typography
                                                key={index}
                                                variant='body1'
                                                style={
                                                    {
                                                        color : 'white',
                                                        border: `1px solid ${findAdminColor(type.name)}`,
                                                        borderRadius: 5,
                                                        padding: 5,
                                                        backgroundColor: findAdminColor(type.name)
                                                    }
                                                }
                                                sx={{
                                                    textAlign: 'left',
                                                    marginRight: 10,
                                                    fontSize: 10,
                                                }}
                                            >
                                                {type.name}
                                            </Typography>
                                        })}
                                    </div>
                                    <Typography>
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                fontStyle: 'italic',
                                                color: darkState ? '#7fe1dd' : '#12047D'
                                            }}
                                        >
                                            Nom : </span>
                                        <a
                                            target='_blank'
                                            rel="noreferrer"
                                            href={`/specialists/${specialist.uuid}`}
                                            style={{textDecoration: 'none', color: darkState ? 'white' : 'black'}}
                                        >
                                            {specialist.title} {specialist.lastName} {specialist.firstName}
                                        </a>
                                    </Typography>
                                    <Typography>
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                fontStyle: 'italic',
                                                color: darkState ? '#7fe1dd' : '#12047D'
                                            }}
                                        >
                                            Mobile pro : </span>{specialist.mobilePro}
                                    </Typography>
                                    <Typography>
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                fontStyle: 'italic',
                                                color: darkState ? '#7fe1dd' : '#12047D'
                                            }}
                                        >
                                            Distance : </span>{specialist.distance} Km
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Checkbox
                                        checked={!!specialist.isSelected}
                                        onChange={() => handleChange(specialist)}
                                        inputProps={{ 'aria-label': 'selected checkbox' }}
                                        style={{float: 'right', marginRight: -20, marginTop: -7}}
                                        color={darkState ? 'secondary' : 'primary'}
                                    />
                                    {hasEvent(specialist) && <IconButton
                                        color='inherit'
                                        onClick={(e) => handleShowPopup(e, specialist)}
                                        style={{float: 'right', marginRight: -15, marginTop: -10, color: "red"}}
                                    >
                                        <ReportProblemOutlinedIcon />
                                    </IconButton>}
                                    {specialist.photo && <a
                                        target='_blank'
                                        rel="noreferrer"
                                        href={`/specialists/${specialist.uuid}`}
                                    >
                                        <img
                                            src={specialist.photo && specialist.photo.contentUrl}
                                            alt='logo'
                                            style={{
                                                width: 70,
                                                boxShadow: darkState ? '0 2px 8px 0 #c1c9d7, 0 -2px 8px 0 #000' :
                                                    '0 2px 8px 0 #c1c9d7, 0 -2px 8px 0 #cce1e9',
                                                flexShrink: '0',
                                                borderRadius: '12px',
                                                backgroundColor: '#eeeeee'
                                            }}
                                        />
                                    </a>}
                                </Grid>
                            </Grid> : <Grid container spacing={3} style={{paddingBottom: 10, paddingTop: 10}}>
                                <Grid item xs={12} sm={9}>
                                    <Checkbox
                                        checked={!!specialist.isSelected}
                                        onChange={() => handleChange(specialist)}
                                        inputProps={{ 'aria-label': 'selected checkbox' }}
                                        style={{float: 'right', marginRight: -20, marginTop: -7}}
                                        color={darkState ? 'secondary' : 'primary'}
                                    />
                                    {hasEvent(specialist) && <IconButton
                                        onClick={(e) => handleShowPopup(e, specialist)}
                                        color='inherit'
                                        style={{float: 'right', marginRight: -20, marginTop: -10, color: "red"}}
                                    >
                                        <ReportProblemOutlinedIcon />
                                    </IconButton>}
                                    <img
                                        src={specialist.photo && specialist.photo.contentUrl}
                                        alt='logo'
                                        style={{
                                            width: 70,
                                            boxShadow: darkState ? '0 2px 8px 0 #c1c9d7, 0 -2px 8px 0 #000;' :
                                                '0 2px 8px 0 #c1c9d7, 0 -2px 8px 0 #cce1e9;',
                                            flexShrink: '0;',
                                            borderRadius: '12px;',
                                            backgroundColor: '#eeeeee;'
                                        }}
                                    />
                                    
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <div style={{marginBottom: 10, display: 'flex'}}>
                                        {specialist.administrativeTypes.map((type, index) => {
                                            return <Typography
                                                key={index}
                                                variant='body1'
                                                style={
                                                    {
                                                        color : 'white',
                                                        border: `1px solid ${findAdminColor(type.name)}`,
                                                        borderRadius: 5,
                                                        padding: 5,
                                                        backgroundColor: findAdminColor(type.name),
                                                        textAlign: 'left',
                                                        marginRight: 10,
                                                        fontSize: 10
                                                    }
                                                }
                                            >
                                                {type.name}
                                            </Typography>
                                        })}
                                    </div>
                                    <Typography>
                                        <span
                                            style={{
                                                fontWeight: 'bold',
                                                fontStyle: 'italic',
                                                color: darkState ? '#7fe1dd' : '#12047D'
                                            }}
                                        >
                                            Nom : </span>{specialist.title} {specialist.lastName} {specialist.firstName}
                                    </Typography>
                                </Grid>
                            </Grid>}
                            {specialists['hydra:member'][specialists['hydra:member'].length - 1]['@id'] !== specialist['@id'] && <Divider />}
                        </div>
                    )) : <Typography style={{marginLeft: 20, marginTop: 20, marginBottom: 20}}>Pas de spécialistes ...</Typography>}
                </div>
                <PlanningEventPopup open={openPopup} handleClose={() => setOpenPopup(false)} darkState={darkState} />
            </Paper>
        </Zoom>
    )
}

function findAdminColor(type) {
    switch(type) {
        case 'Pros-Consulte':
            return '#EE8639'
        case 'Geo-Psy':
            return '#f44336'
        default:
            return '#ba000d'
    }
}

function hasEvent(specialist) {
    if (specialist.planning) {
        return specialist.planning.events && specialist.planning.events.length > 0
    }
    return false
}