import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationPopup({ open, handleClose, darkState, handleAccept, title, content }) {

  return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="planning-event-popup-title"
            aria-describedby="planning-event-popup-description"
            style={{zIndex: 1301}}
        >
            <DialogTitle id="planning-event-popup-title">{title ? title : `Ce psychologue semble occupé pour la période demandée`}</DialogTitle>
            <DialogContent>
                {content ? <DialogContentText id="planning-event-popup-description" component='p'>
                    {content}
                </DialogContentText> : 
                <DialogContentText id="planning-event-popup-description" component='p'>
                    <b>ATTENTION !</b> Vous avez choisi un psychologue qui ne semble pas être disponible.<br />
                    Voulez-vous continuer ?
                </DialogContentText>}
            </DialogContent>
            <DialogActions>
            <Button onClick={handleClose} color={darkState ? 'secondary' : 'primary'}>
                    Annuler
                </Button>
                <Button onClick={handleAccept} color={darkState ? 'secondary' : 'primary'}>
                    Confirmer
                </Button>
            </DialogActions>
        </Dialog>
  );
}