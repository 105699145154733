import React from 'react'
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from 'recharts';
import { 
    Paper,
    Typography
} from '@mui/material';

export default function AdministrativeType({ darkState, dashboardData, phoneResponsive }) {
    const data = [
        {
            name: 'Nombre de spécialistes',
            GeoPsy: dashboardData.administrativeType.geopsy,
            Prestataire: dashboardData.administrativeType.prestataire,
            ProsConsulte: dashboardData.administrativeType.prosconsulte
        },
    ]

    return (
        <Paper style={{paddingBottom: 50 }}>
            <Typography variant='h6' color={darkState ? 'secondary' : 'primary'} style={{padding: '20px 0px 30px 20px'}} >Nombre de spécialistes par type administratif</Typography>
            <BarChart
                width={phoneResponsive ? 200 : 500}
                height={300}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
                style={{margin: 'auto'}}
            >
                <XAxis dataKey="name" stroke={darkState ? 'white' : 'black'} />
                <YAxis stroke={darkState ? 'white' : 'black'} />
                <Tooltip />
                <Legend />
                <Bar dataKey="GeoPsy" fill={'#f44336'} />
                <Bar dataKey="ProsConsulte" fill={'#EE8639'} />
                <Bar dataKey="Prestataire" fill={'#ba000d'} />
            </BarChart>
        </Paper>
    )
}