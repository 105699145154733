import React, { useEffect, useRef, useState } from 'react'
import { 
    Paper,
    CircularProgress,
    Typography,
    Zoom,
    Button,
    LinearProgress,
    Grid,
    TextField,
    FormControlLabel,
    Switch
} from '@mui/material';
import { apiFetch } from '../../../../service/apiFetch';
import { useNavigate, useParams } from 'react-router-dom';
import { convertActivitiesToArray, convertActivitiesToObject, formatExperiences, formatMultipleCompanies, handleMultipleTextFieldValue, TransitionLeft } from '../../../../helpers/utils';
import { useCompanies } from '../../../../hooks/company';
import { useExpertises } from '../../../../hooks/expertise';
import { useExperiences } from '../../../../hooks/experience';
import { useCertifications } from '../../../../hooks/certification';
import MultipleCheckBoxes from '../../../../components/MultipleCheckboxes';
import MultipleAddCheckBoxes from '../../../../components/MultipleAddCheckboxes';
import logo from '../../../../img/logo-PC-horizontal_homepage.png'
import { handleFormError } from '../../../../service/security/formError';

export default function ManageCv({ darkState, zoom, setOpenSnackbar, setText, setTransition, setSeverity }) {
    const [cvData, setCvData] = useState(false)
    const [loading, setLoading] = useState(false)
    const [specialistTypes, setSpecialistTypes] = useState(false)
    const [error, setError] = useState({})
    const [specialistType, setSpecialistType] = useState([])
    const [expertise, setExpertise] = useState([])
    const [experience, setExperience] = useState([])
    const [certification, setCertification] = useState([])
    const [multipleCompanies, setMultipleCompanies] = useState([])
    const [activities, setActivities] = useState(false)
    const [activity, setActivity] = useState([])
    const [state, setState] = useState({
        iprp: false,
    });

    const { id } = useParams()
    let navigate = useNavigate()
    const mounted = useRef(false);

    const {
        companies,
        createCompany,
        fetchCompanies
    } = useCompanies()

    const {
        expertises,
        createExpertise,
        fetchExpertises,
    } = useExpertises()

    const {
        experiences,
        fetchExperiences,
        createExperience
    } = useExperiences()

    const {
        certifications,
        fetchCertifications,
        createCertification
    } = useCertifications()

    useEffect(() => {
        (async () =>{
            mounted.current = true
            const fetchCv = await apiFetch(`/cvs/${id}`)
            if (fetchCv && fetchCv.uuid) {
                setCvData(fetchCv)
                setSpecialistType(fetchCv.specialistType)
                setExpertise(fetchCv.expertises)
                setState({
                    iprp: fetchCv.iprp,
                })
                setExperience(formatExperiences(fetchCv.experiences))
                setCertification(fetchCv.certifications)
                setMultipleCompanies(fetchCv.companies)
                setActivity(convertActivitiesToArray(fetchCv.activity))
            } else {
                setText(fetchCv['hydra:description'] || 'Erreur lors de la récupération des données')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
                navigate(-1)
            }
            await fetchCompanies(null, () => mounted)

            setActivities([
                {id: 'homeIntervention', name: 'Intervention à domicile'},
                {id: 'platform', name: 'Ligne d\'écoute'},
                {id: 'crisisUnit', name: 'Cellules de crise'},
                {id: 'conflictResolutionSupport', name: 'Appui à la résolution de conflits, médiation'},
                {id: 'pse', name: 'Permanence sur site'},
                {id: 'app', name: 'Analyse de la Pratique Professionnelle'},
                {id: 'coaching', name: 'Coaching individuel et collectif'},
                {id: 'certifiedCoaching', name: 'Coaching certifié'},
                {id: 'projectManager', name: 'Chef de projet/audit'},
                {id: 'harassmentInvestigation', name: 'Enquête harcèlement'},
                {id: 'rpsCoach', name: 'Formateur dans la prévention des RPS auprès de salariés et de managers'},
                {id: 'call', name: 'Appel'},
                {id: 'visio', name: 'Visio'},
            ])

            const specialistTypesFetch = await apiFetch('/specialist_types')
            if (specialistTypesFetch && specialistTypesFetch['hydra:member']) {
                setSpecialistTypes(specialistTypesFetch['hydra:member'])
            } else {
                setText((specialistTypesFetch && specialistTypesFetch['hydra:description']) || 'Erreur lors de la récupération des types')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
            }

            await fetchExpertises('/expertises?pagination=false', () => mounted)

            await fetchExperiences('/experiences?pagination=false', () => mounted)

            await fetchCertifications(null, () => mounted)
        })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, setTransition, setText, setSeverity, setOpenSnackbar, navigate])

    const handleModify = async (e) => {
        e.preventDefault()
        setLoading(true)
        let data = new FormData(e.target)
        const description = data.get('description')
        const adeli = data.get('adeli')

        data = {
            description: description,
            experiences: handleMultipleTextFieldValue(experience,experiences),
            expertises: handleMultipleTextFieldValue(expertise),
            certifications: handleMultipleTextFieldValue(certification),
            companies: formatMultipleCompanies(multipleCompanies),
            specialistType: handleMultipleTextFieldValue(specialistType),
            activity: convertActivitiesToObject(activity),
            adeli: adeli,
            iprp: state.iprp
        }
        const updatedCv = await apiFetch(`/cvs/${id}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        })
        if (updatedCv && updatedCv.uuid) {
            setText('Le CV a été modifier !')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setTimeout(() => {
                navigate(-1)
            }, 2000)
        } else {
            const cvError = handleFormError(updatedCv, ['adeli'])
            setError(cvError)
            setText(updatedCv['hydra:description'] || 'Erreur lors de la mise à jour')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setLoading(false)
    }

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    return (
        <>
            {cvData ? <Zoom in={zoom}>
                <Paper style={{paddingLeft: 30, marginTop: 20, paddingTop: 30, paddingBottom: 30}}>
                    <form noValidate autoComplete="off" onSubmit={handleModify}>
                        <Button
                            disabled={loading}
                            variant='contained'
                            type='submit'
                            color={darkState ? 'secondary' : 'primary'}
                        >
                            Modifier le CV : {cvData.name}
                        </Button>
                        <div style={{zIndex: 99999, position: 'absolute', marginTop: 40}}>
                            <div style={{marginLeft: 540}}>
                                <img src={logo} alt='logo' style={{width: 384, height: 56, marginTop: 30}} />
                            </div>
                            <div style={{marginLeft: 780}}>
                                <img
                                    src={cvData.specialist.photo && cvData.specialist.photo.contentUrl}
                                    alt='logo'
                                    style={{
                                        width: 128,
                                        height: 128,
                                        textAlign: 'center',
                                        marginTop: 30,
                                        marginLeft: -200
                                    }}
                                />
                            </div>
                        </div>
                        <Grid container spacing={3} style={{paddingLeft: 200, marginTop: 40}}>
                            <Grid
                                item
                                xs={12}
                                sm={4}
                                style={{
                                    backgroundColor: '#12047D',
                                    paddingTop: 300,
                                    boxShadow: '1px 1px 3px 1px rgb(0 0 0 / 12%)'
                                }}
                            >
                                <Typography
                                    variant='h6'
                                    style={{
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        color: 'white'
                                    }}
                                >
                                    DOMAINES D'EXPERTISE
                                </Typography>
                                {expertises && <MultipleAddCheckBoxes
                                    darkState={darkState}
                                    text={'expertise'}
                                    label={'Expertises'}
                                    setOpenSnackbar={setOpenSnackbar}
                                    setText={setText}
                                    setTransition={setTransition}
                                    setSeverity={setSeverity}
                                    selectedData={expertise}
                                    setData={setExpertise}
                                    id='expertises'
                                    createData={createExpertise}
                                    data={expertises}
                                />}
                                <Typography variant='h6' style={{textAlign: 'center', fontWeight: 'bold', color: 'white'}}>
                                    FORMATIONS
                                </Typography>
                                {certifications && <MultipleAddCheckBoxes
                                    darkState={darkState}
                                    text={'certification'}
                                    label={'Formations/Certifications'}
                                    setOpenSnackbar={setOpenSnackbar}
                                    setText={setText}
                                    setTransition={setTransition}
                                    setSeverity={setSeverity}
                                    selectedData={certification}
                                    setData={setCertification}
                                    id='certifications'
                                    createData={createCertification}
                                    data={certifications}
                                />}
                                <Grid container spacing={3} style={{marginTop: 100}}>
                                    <Grid item xs={4} >
                                        <Typography style={{margin: 0, color: 'white'}}>IPRP : </Typography>
                                    </Grid>
                                    <Grid item xs={6} style={{paddingTop: 5}}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={state.iprp}
                                                    onChange={handleChange}
                                                    name="iprp"
                                                    id="iprp"
                                                    color={'secondary'}
                                                />
                                            }
                                            style={{margin: 0, color: darkState ? '#bbb' : '#676', marginBottom: 20}}
                                        /><br />
                                    </Grid>
                                </Grid>
                                <TextField
                                    style={{
                                        width: 400,
                                        marginTop: 50,
                                        color: 'white'
                                    }}
                                    color={'secondary'}
                                    error={!!error.adeli}
                                    helperText={error.adeli}
                                    id="adeli"
                                    name="adeli"
                                    label="N° adeli"
                                    defaultValue={cvData.adeli}
                                /><br />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sm={7}
                                style={{
                                    paddingTop: 30,
                                    paddingLeft: 300,
                                    boxShadow: '1px 1px 3px 1px rgb(0 0 0 / 12%)'
                                }}
                            >
                                <div style={{marginLeft: -180}}>
                                    <div style={{marginTop: 150, marginBottom: 50}}>
                                        <Typography
                                            variant='h4'
                                            style={{fontWeight: 'bold'}}
                                            color={'secondary'}
                                        >
                                            {cvData.specialist.lastName} {cvData.specialist.firstName}
                                        </Typography>
                                    </div>
                                    {specialistTypes && <MultipleCheckBoxes
                                        darkState={darkState}
                                        defaultValue={cvData.specialistType}
                                        data={specialistType}
                                        setData={setSpecialistType}
                                        id='specialistTypes'
                                        allOptions={specialistTypes}
                                        label='Type de spécialistes'
                                        placeholder='Vous pouvez sélectionner une ou plusieurs types'
                                    />}
                                    <TextField
                                        label="Description"
                                        id='description'
                                        name='description'
                                        multiline
                                        rows={4}
                                        defaultValue={cvData.description}
                                        color={darkState ? 'secondary' : 'primary'}
                                        style={{width: 400}}
                                    />
                                    <Typography
                                        variant='h6'  
                                        style={{
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            marginTop: 50,
                                            marginLeft: -200
                                        }}
                                    >
                                        EXPÉRIENCES PROFESSIONNELLES
                                    </Typography>
                                    {experiences && <MultipleAddCheckBoxes
                                        darkState={darkState}
                                        text={'expérience'}
                                        label={'Expériences professionnelles'}
                                        setOpenSnackbar={setOpenSnackbar}
                                        setText={setText}
                                        setTransition={setTransition}
                                        setSeverity={setSeverity}
                                        selectedData={experience}
                                        setData={setExperience}
                                        id='experiences'
                                        createData={createExperience}
                                        data={experiences}
                                    />}
                                    <Typography
                                        variant='h6'
                                        style={{
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            marginTop: 50,
                                            marginLeft: -200
                                        }}
                                    >
                                        ACTIVITÉS
                                    </Typography>
                                    {activities && <MultipleCheckBoxes
                                        defaultValue={convertActivitiesToArray(cvData.activity)}
                                        data={activity}
                                        setData={setActivity}
                                        darkState={darkState}
                                        id='activities'
                                        allOptions={activities}
                                        label='Activités'
                                        placeholder='Vous pouvez sélectionner une ou plusieurs activités'
                                    />}
                                    <Typography
                                        variant='h6'
                                        style={{
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            marginTop: 50,
                                            marginLeft: -200
                                        }}
                                    >
                                        MES RÉFÉRENCES PROS-CONSULTE
                                    </Typography>
                                    {companies && <MultipleAddCheckBoxes
                                        darkState={darkState}
                                        text={'référence'}
                                        label={'Références'}
                                        setOpenSnackbar={setOpenSnackbar}
                                        setText={setText}
                                        setTransition={setTransition}
                                        setSeverity={setSeverity}
                                        selectedData={multipleCompanies}
                                        setData={setMultipleCompanies}
                                        id='references'
                                        createData={createCompany}
                                        data={companies}
                                    />}
                                </div>
                            </Grid>
                        </Grid>
                    </form>
                    {loading && <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
                </Paper>
            </Zoom> : 
            <div style={{textAlign: 'center'}}>
                <CircularProgress color={darkState ? 'secondary' : 'primary'} />
            </div>}
        </>
    )
}