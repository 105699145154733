import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Radio,
    RadioGroup,
    FormControlLabel,
    Checkbox,
    Button,
    Box,
    CircularProgress,
    Alert,
    TextareaAutosize,
    Typography,
    Paper,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from "@mui/material";
import moment from 'moment';
import logo from '../img/logo-PC-Menway.png';
import AlertSnackbar from '../components/AlertSnackbar'; 
import { TransitionLeft } from '../helpers/utils';

const Responses = () => {
    const { uuid } = useParams();
    const [accepted, setAccepted] = useState(null); 
    const [response, setResponse] = useState('');
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [formError, setFormError] = useState(false);
    const [dateError, setDateError] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [transition, setTransition] = useState(undefined);
    const [chosenDates, setChosenDates] = useState([]);
    const [responseExists, setResponseExists] = useState(false);
    const [responseSubmitted, setResponseSubmitted] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false); // État du dialogue
    const maxCharacters = 255;
    

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responses = await fetch(process.env.REACT_APP_BASE_URL + `/intervention_requests/${uuid}/response`);
                if (!responses.ok) {
                    throw new Error('Erreur de réponse du serveur');
                }
                const responsesData = await responses.json();
                setData(responsesData);
                setLoading(false);

                if (Array.isArray(responsesData.answers) && responsesData.answers.length > 0) {
                    setResponseExists(true);
                }
            } catch (error) {
                console.error('Erreur de récupération des données:', error);
                setError(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [uuid]);
    

    const handleOptionChange = (event) => {
        const value = event.target.value === 'true';
        setAccepted(value);
        setFormError(false);
        if (!value) {
            setChosenDates([]);
        }
    };

    const handleCommentaireChange = (event) => {
        const value = event.target.value;
        if (value.length <= maxCharacters) {
            setResponse(value);
        }
    };

    const handleDateChange = (event) => {
        const { value, checked } = event.target;
        setChosenDates((prevSelectedDates) => {
            if (checked) {
                return [...prevSelectedDates, value];
            } else {
                return prevSelectedDates.filter(date => date !== value);
            }
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (accepted === null) {
            setFormError(true);
            return;
        }
        if (accepted === true && data.intervention.availableDates.length > 1 && chosenDates.length === 0) {
            setDateError(true);
            return;
        }

        setSubmitting(true);
        try {
            await fetch(process.env.REACT_APP_BASE_URL + '/answers', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    accepted,
                    chosenDates,
                    response,
                    interventionRequest: `/intervention_requests/${uuid}`
                }),
            });
            setTimeout(() => {
                setAlertOpen(true);
            }, 3000);
            setTransition(() => TransitionLeft);
            setResponseExists(true);
            setResponseSubmitted(false);
        } catch (error) {
            console.error('Erreur lors de l\'envoi des données:', error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleClickOpen = () => {
        setDialogOpen(true);
    };

    const handleClose = () => {
        setDialogOpen(false);
    };

    const formatDate = (dateString) => {
        return moment(dateString).format('DD MMMM yyyy');
    };

    const formatDates = (dates) => {
        if (Array.isArray(dates)) {
            return dates.map(date => formatDate(date)).join(', ');
        }
        return formatDate(dates);
    };
 

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            bgcolor="#01304A"
        >
            <Paper elevation={3} style={{ padding: '20px', maxWidth: '400px', width: '100%' }}>
                <Box display="flex" alignItems="center" marginBottom="20px">
                    <img src={logo} alt="Logo" style={{ width: '320px', marginLeft: '32px' }} />
                </Box>
                {loading && <CircularProgress />}
                {error && <Alert severity="error">{error.message}</Alert>}
                {data && (
                    <Box sx={{mb: 2}}>
                        <Typography
                            variant="h5"
                            marginBottom="20px"
                            color="#01304A"
                            style={{
                                fontWeight: 'bold',
                                textAlign: 'center'
                            }}
                        >
                            Résumé de l'intervention :
                        </Typography>
                        
                        <Typography variant="body1" style={{ textAlign: 'left', fontSize: '1.2rem' }}>
                            Entreprise: <strong>{data.intervention.company.name}</strong>.<br/>
                            Lieu de l'intervention: <strong>{data.intervention.address.address}</strong> à <strong>{data.intervention.address.city}</strong> (<strong>{data.intervention.address.zipCode}</strong>)<br/>
                            Date possible: <strong>{formatDates(data.intervention.availableDates)}</strong> <br/>
                            Heure de l'intervention: <strong>{data.intervention.startTime}</strong> à <strong>{data.intervention.endTime}</strong><br/>
                            Rémunération: <strong>{data.intervention.remuneration}</strong> euros.<br/>
                            Commentaire: {data.intervention.comment.length > 80 ?
                                <React.Fragment>
                                    {data.intervention.comment.slice(0, 80)}
                                    
                                </React.Fragment>
                                :
                                data.intervention.comment
                            }
                        </Typography>
                        {data.intervention.comment.length > 80 && <Button size="small" color="primary" onClick={handleClickOpen} sx={{mt: 1}} variant='outlined'>
                            Voir plus
                        </Button>}
                        <Dialog open={dialogOpen} onClose={handleClose} fullWidth>
                            <DialogTitle>Commentaire</DialogTitle>
                            <DialogContent>
                                <DialogContentText>
                                    <Typography variant="body1" style={{ wordWrap: 'break-word' }}>
                                        {data.intervention.comment}
                                    </Typography>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Retour
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Box>
                )}
                {responseExists ?
                <Alert variant="filled" severity="info" sx={{fontSize: 17}}>
                    {responseSubmitted
                        ? "Vous avez déjà répondu à ce formulaire, si vous pensez qu'il s'agit d'une erreur, veuillez contacter l'équipe Pros consulte"
                        : "Nous avons bien reçu votre réponse, merci."
                    }
                </Alert> :
                <form onSubmit={handleSubmit}>
                    <Typography variant="body1" gutterBottom color="#01304A" style={{ fontSize: 18 }}>
                        Êtes-vous disponible ?
                    </Typography>
                    {formError && (
                        <Typography variant="body2" color="error">
                            Champ obligatoire
                        </Typography>
                    )}
                    <RadioGroup value={accepted === null ? '' : accepted.toString()} onChange={handleOptionChange} row>
                        <FormControlLabel value="true" control={<Radio />} label="Oui" />
                        <FormControlLabel value="false" control={<Radio />} label="Non" />
                    </RadioGroup>
                    {accepted === true && Array.isArray(data?.intervention.availableDates) && data.intervention.availableDates.length > 1 && (
                        <Box marginTop="20px">
                            <Typography variant="body1">Sélectionnez vos disponibilités :</Typography>
                            {data.intervention.availableDates.map(date => (
                                <FormControlLabel
                                    key={date}
                                    control={
                                        <Checkbox
                                            checked={chosenDates.includes(date)}
                                            onChange={handleDateChange}
                                            value={date}
                                        />
                                    }
                                    label={formatDate(date)}
                                />
                            ))}
                            {dateError && (
                                <Typography variant="body2" color="error">
                                    Veuillez sélectionner au moins une date.
                                </Typography>
                            )}
                        </Box>
                    )}
                    <div style={{ position: 'relative', marginBottom: '20px' }}>
                        <TextareaAutosize
                            minRows={6}
                            maxRows={10}
                            placeholder="Commentaire"
                            value={response}
                            onChange={handleCommentaireChange}
                            style={{ width: '95%', padding: '10px', fontSize: '16px' }}
                        />
                        <Typography variant="body2" style={{ position: 'absolute', bottom: '5px', left: '5px' }}>
                            {maxCharacters - response.length}/255
                        </Typography>
                    </div>
                    <Button type="submit" variant="contained" color="primary" style={{ width: '100%' }} disabled={submitting || (responseExists && responseSubmitted)}>
                        {submitting ? "Envoi en cours..." : "Envoyer"}
                    </Button>
                </form>}
                <AlertSnackbar
                    open={alertOpen}
                    setOpen={setAlertOpen}
                    message={responseSubmitted ? "Vous avez déjà fourni une réponse à cette intervention." : "Nous avons bien reçu votre réponse, merci."}
                    severity="success"
                    transition={transition}
                />
            </Paper>
        </Box>
    );
};

export default Responses;