import React from 'react'
import { hasRoles } from '../security/hasRoles'
import { Navigate } from 'react-router-dom'

export const InterventionManagerRoute = ({ children }) => {

    if ( hasRoles() === 'intervention_manager' || hasRoles() === 'admin') {
        return children
    }
    return <Navigate to='/specialists' />
}