import React from 'react'
import {
    Paper,
    Zoom,
    Typography,
} from '@mui/material';

export default function Specialities({ specialist, zoom, darkState }) {
    return (
        <Zoom in={zoom} style={{ transitionDelay: '300ms' }}>
            <Paper
                sx={{
                    padding: 1.25,
                    boxShadow: '0 4px 24px 0 rgb(34 41 47 / 10%)',
                    mt: 3.75
                }}
            >
                <Typography variant='h6' color={darkState ? 'secondary' : 'primary'} style={{fontWeight: 'bold', marginBottom: 10}}>
                    <span>Spécialités cliniques</span>
                </Typography>
                {specialist.specialities.length ? specialist.specialities.map((speciality) => {
                    return <Typography key={speciality.id} style={{marginLeft: 50}}>• {speciality.name}</Typography>
                }) : 
                <Typography style={{marginLeft: 50}}>Pas de spécialitée...</Typography>}
            </Paper>
        </Zoom>
    )
}