import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';
import { DatePickerComponent } from '../../../../components/DateTimePickerComponent';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ChangeDatePopup({ open, handleClose, darkState, handleAccept, setStartDate, startDate, handleDelete, modify, setModify }) {

    const handleStartDateChange = (e) => {
        setStartDate(e)
    }

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="change-date-popup-title"
            aria-describedby="change-date-popup-description"
        >
            <DialogTitle id="change-date-popup-title">{"Modifier/Supprimer la date de renouvellement"}</DialogTitle>
            <DialogContent style={{paddingLeft: 80, paddingBottom: 25}}>
                {!modify ? <>
                    <Button variant={'contained'} color={darkState ? 'secondary' : 'primary'} onClick={() => setModify(true)} style={{marginRight: 100}}>
                        Modifier
                    </Button>
                    <Button variant={'contained'} onClick={handleDelete} color={darkState ? 'secondary' : 'primary'}>
                        Supprimer
                    </Button>
                </> : 
                <DatePickerComponent darkState={darkState} customStyle={true} color={!darkState && 'primary' } handleDateChange={handleStartDateChange} selectedDate={startDate} text="Nouvelle date de renouvellement *" />
                }
            </DialogContent>
            {modify && <DialogActions>
                <Button onClick={() => setModify(false)} color={darkState ? 'secondary' : 'primary'}>
                    Annuler
                </Button>
                <Button onClick={handleAccept} color={darkState ? 'secondary' : 'primary'}>
                    Confirmer
                </Button>
            </DialogActions>}
        </Dialog>
    );
}   