import { useReducer } from "react"
import { apiFetch } from "../service/apiFetch"

const initialState = []

const FETCHING_DISABLED_SPECIALISTS = 'FETCHING_DISABLED_SPECIALISTS'
const SET_DISABLED_SPECIALISTS = 'SET_DISABLED_SPECIALISTS'
const DELETE_DISABLED_SPECIALISTS = 'DELETE_DISABLED_SPECIALISTS'

export function reducer(state = initialState, action) {
    switch (action.type) {
        case FETCHING_DISABLED_SPECIALISTS:
            return { ...state, loading: true }
        case SET_DISABLED_SPECIALISTS:
            return {...state, disabledSpecialists: action.payload, loading: false}
        case DELETE_DISABLED_SPECIALISTS:
            return {...state, disabledSpecialists: state.disabledSpecialists.filter((i) => {
                return i.uuid !== action.payload
            })}
        default:
            return state
    }
}

export function useDisabledSpecialists() {
    const [state, dispatch] = useReducer(reducer, {    
        disabledSpecialists: null,
        loading: false,
    })

    return {
        disabledSpecialists: state.disabledSpecialists,
        loading: state.loading,
        fetchDisabledSpecialists: async function (route, isSubscribed = () => ({current: true})) {
            if ((state.disabledSpecialists || state.loading || state.totalItems) && !route) {
                return
            }
            dispatch({ type: 'FETCHING_DISABLED_SPECIALISTS' })
            const disabledSpecialists = await apiFetch(route || '/specialists/disabled', {method: 'GET'})

            if (isSubscribed().current && disabledSpecialists) {
                dispatch({type: 'SET_DISABLED_SPECIALISTS', payload: disabledSpecialists})
            }
        },
        deleteDisabledSpecialists: async function (disabledSpecialistId) {
            const deletedDisabledSpecialists = await apiFetch(`/specialists/${disabledSpecialistId}/restore`, {
                method: 'DELETE'
            }, true)
            if (deletedDisabledSpecialists && deletedDisabledSpecialists.uuid) {
                dispatch({type: 'DELETE_DISABLED_SPECIALISTS', payload: disabledSpecialistId})
            }
            return deletedDisabledSpecialists
        },
    }
}