import {
    Grid,
    Typography,
    TextField,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    FormControlLabel,
    Switch,
    Paper,
} from '@mui/material';
import MultipleAddress from '../../components/MultipleAddress';

export default function ProfessionnalInformation({
    error,
    state,
    handleChange,
    darkState,
    taxStatus,
    setTaxStatus,
    adeli,
    setAdeli,
    rpps,
    setRpps,
    taxStatuses,
    siret,
    setSiret,
    urssafAddress,
    setUrssafAddress,
    urssafAddressCity,
    setUrssafAddressCity,
    urssafAddressZipCode,
    setUrssafAddressZipCode,
    switchOffice,
    handleChangeSwitch,
    multipleAddress,
    setMultipleAddress,
    getName,
}) {

    return (
        <Paper sx={{mt: 3, pl: 3}}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <FormControl
                        error={!!error.title}
                        color={darkState ? 'secondary' : "primary"}
                        sx={{
                            width: 300,
                            mb: 3
                        }}
                    >
                        <InputLabel id="select-label">Statut fiscal</InputLabel>
                        <Select
                            labelId="select-label"
                            id="select"
                            value={taxStatus}
                            label="Age"
                            onChange={(e) => setTaxStatus(e.target.value)}
                        >
                            <MenuItem value="">
                                <em>Selectionnez un statut fiscal</em>
                            </MenuItem>
                            {taxStatuses.map((taxStatus) => (
                                <MenuItem key={taxStatus['@id']} value={taxStatus['@id']}>{taxStatus.name}</MenuItem>
                            ))}
                        </Select>
                    </FormControl><br />
                    <TextField
                        value={adeli}
                        onChange={(event) => {
                          setAdeli(event.target.value);
                        }} 
                        color={darkState ? 'secondary' : "primary"}
                        required
                        error={!!error.adeli}
                        helperText={error.adeli}
                        id="adeli"
                        name="adeli"
                        label="N° adeli"
                        sx={{
                            width: 300,
                            mb: 3
                        }}
                    /><br />
                    <TextField
                        value={rpps}
                        onChange={(event) => {
                          setRpps(event.target.value);
                        }} 
                        color={darkState ? 'secondary' : "primary"}
                        required
                        error={!!error.rpps}
                        helperText={error.rpps}
                        id="rpps"
                        name="rpps"
                        label="N° rpps"
                        sx={{
                            width: 300,
                            mb: 3
                        }}
                    /><br />
                    {(taxStatus && getName(taxStatus) !== 'Portage') && <>
                        <TextField
                            value={siret}
                            onChange={(event) => {
                              setSiret(event.target.value);
                            }}
                            color={darkState ? 'secondary' : "primary"}
                            error={!!error.siret}
                            helperText={error.siret}
                            id="siret"
                            name="siret"
                            label="Siret"
                            sx={{
                                width: 300,
                                mb: 3
                            }}
                        /><br />
                    </>}
                   
                        <TextField
                            value={urssafAddress}
                            onChange={(event) => {
                              setUrssafAddress(event.target.value);
                            }}
                            color={darkState ? 'secondary' : "primary"}
                            error={!!error.urssafAddress}
                            helperText={error.urssafAddress}
                            id="urssafAddress"
                            name="urssafAddress"
                            label="Adresse URSSAF"
                            sx={{
                                width: 300,
                                mb: 3
                            }}
                        /><br />
                    
                    
                        <TextField
                            value={urssafAddressZipCode}
                            onChange={(event) => {
                              setUrssafAddressZipCode(event.target.value);
                            }}
                            color={darkState ? 'secondary' : "primary"}
                            error={!!error.urssafAddressZipCode}
                            helperText={error.urssafAddressZipCode}
                            id="urssafAddressZipCode"
                            name="urssafAddressZipCode"
                            label="Code postal adresse URSSAF"
                            sx={{
                                width: 300,
                                mb: 3
                            }}
                        /><br />
                    
                    
                        <TextField
                            value={urssafAddressCity}
                            onChange={(event) => {
                              setUrssafAddressCity(event.target.value);
                            }}
                            color={darkState ? 'secondary' : "primary"}
                            error={!!error.urssafAddressCity}
                            helperText={error.urssafAddressCity}
                            id="urssafAddressCity"
                            name="urssafAddressCity"
                            label="Ville adresse URSSAF"
                            sx={{
                                width: 300,
                                mb: 3
                            }}
                        />
                    
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={4} >
                            <Typography style={{margin: 0, color: darkState ? '#bbb' : '#676'}}>Cabinet : </Typography>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop: 17}}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={switchOffice}
                                        onChange={handleChangeSwitch}
                                        name="switchOffice"
                                        id="switchOffice"
                                        color={ darkState ? 'secondary' :"primary"}
                                    />
                                }
                                style={{margin: 0, color: darkState ? '#bbb' : '#676', marginBottom: 20}}
                            /><br />
                        </Grid>
                    </Grid>
                    {switchOffice && <MultipleAddress
                        darkState={darkState}
                        multipleAddress={multipleAddress}
                        setMultipleAddress={setMultipleAddress}
                    />}
                    <Grid container spacing={3}>
                        <Grid item xs={4} >
                            <Typography style={{margin: 0, color: '#ffae00'}}>IPRP : </Typography>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop: 17}}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={state.iprp}
                                        onChange={handleChange}
                                        name="iprp"
                                        id="iprp"
                                        color={ darkState ? 'secondary' :"primary"}
                                    />
                                }
                                style={{margin: 0, color: darkState ? '#bbb' : '#676', marginBottom: 20}}
                            /><br />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={4} >
                            <Typography style={{margin: 0, color: darkState ? '#bbb' : '#676'}}>Coach WellMonday : </Typography>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop: 17}}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={state.coachWellmonday}
                                        onChange={handleChange}
                                        name="coachWellmonday"
                                        id="coachWellmonday"
                                        color={ darkState ? 'secondary' :"primary"}
                                    />
                                }
                                style={{margin: 0, color: darkState ? '#bbb' : '#676', marginBottom: 20}}
                            /><br />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={4} >
                            <Typography style={{margin: 0, color: darkState ? '#bbb' : '#676'}}>PSSM : </Typography>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop: 17}}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={state.pssm}
                                        onChange={handleChange}
                                        name="pssm"
                                        id="pssm"
                                        color={ darkState ? 'secondary' :"primary"}
                                    />
                                }
                                style={{margin: 0, color: darkState ? '#bbb' : '#676', marginBottom: 20}}
                            /><br />
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={4} >
                            <Typography style={{margin: 0, color: darkState ? '#bbb' : '#676'}}>Visible mobile : </Typography>
                        </Grid>
                        <Grid item xs={6} style={{paddingTop: 17}}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={state.allowSurroundingAreaVisibility}
                                        onChange={handleChange}
                                        name="allowSurroundingAreaVisibility"
                                        id="allowSurroundingAreaVisibility"
                                        color={ darkState ? 'secondary' :"primary"}
                                    />
                                }
                                style={{margin: 0, color: darkState ? '#bbb' : '#676', marginBottom: 20}}
                            /><br />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}