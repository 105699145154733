import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Slide,
    Tooltip,
    IconButton,
    Select,
    FormControl,
    MenuItem,
    InputLabel,
    Grid,
    TextField,
    Zoom,
    Typography,
    FormHelperText 
} from '@mui/material';
import AutoCompleteAddress from '../../../components/AutoCompleteAddress';
import { DatePickerComponent } from '../../../components/DateTimePickerComponent';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import DeleteIcon from "@mui/icons-material/Delete"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModifyPopup({
    open,
    handleClose,
    darkState,
    handleAccept,
    error,
    intervention,
    phoneResponsive,
    startDates,
    handleChangeTime,
    state,
    setNewAddress,
    setNewCoordonnate,
    handleAddDate,
    handleStartDateChange,
    handleRemoveDate,
    loading,
    setRemuneration,
    setComment,
    remuneration,
    comment,
}) {

    return (
        <Dialog
            open={!!open}
            TransitionComponent={Transition}
            keepMounted
            fullWidth={true}
            maxWidth={'lg'}
            onClose={handleClose}
            aria-labelledby="modify-popup-title"
            aria-describedby="modify-popup-description"
        >
            <DialogTitle id="modify-popup-title">Modifier</DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <div style={{marginTop: 20}}>
                            <AutoCompleteAddress
                                error={!!error.address}
                                darkState={darkState}
                                text={error.address}
                                defaultValue={intervention.address.address}

                                id="address"
                                name="address"
                                label="Adresse de l'intervention"
                                setNewAddress={setNewAddress}
                                setNewCoordonnate={setNewCoordonnate}
                                required={true}
                                style={{width: 400}}
                            />
                            <TextField
                                id="comment"
                                name="comment"
                                label="Commentaire"
                                multiline
                                onChange={(e) => setComment(e.target.value)}
                                value={comment}
                                rows={5}
                                color={darkState ? 'secondary' : 'primary'}
                                style={phoneResponsive ? 
                                    {
                                        width: 195,
                                        marginBottom: 10,
                                        marginTop: 20
                                    } : {
                                        width: 400,
                                        marginBottom: 30,
                                        marginTop: 20
                                    }
                                }
                                error={!!error.comment}
                                helperText={error.comment}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div style={{marginTop: 20}}>
                            {startDates.map((startDate, i) => {
                                return <Grid container spacing={3} key={i}>
                                    <Grid item xs={12} sm={6} sx={{mb: 3}}>
                                        <DatePickerComponent
                                            error={!!error.availableDates}
                                            darkState={darkState}
                                            color={!darkState && 'primary' }
                                            selectedDate={startDate}
                                            handleDateChange={(e) => handleStartDateChange(e, i)}
                                            text="Date de début *" 
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                        <IconButton
                                            edge="end"
                                            color='inherit'
                                            aria-label="delete"
                                            onClick={() => handleRemoveDate(startDate)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            })}
                            <Tooltip TransitionComponent={Zoom} title={`Ajouter une date`}>
                                <Fab
                                    color={darkState ? 'secondary' : 'primary'}
                                    aria-label="ajouter une date"
                                    onClick={handleAddDate}
                                    disabled={startDates.length === 3}
                                    style={{width: 35, height: 20}}
                                >
                                    <AddIcon />
                                </Fab>
                            </Tooltip>
                            <FormHelperText style={{color: 'red'}}>{error.availableDates}</FormHelperText>
                            <div style={{display: 'flex', marginTop: 20}}>
                                <Typography style={{color: darkState ? '#BBBEC5' : '#757575', marginRight: 20, marginTop: 15}}>Heure de début * : </Typography>
                                <FormControl color={darkState ? 'secondary' : 'primary'} error={!!error.startTime} sx={{width: 100, mr: 3}}>
                                    <InputLabel id="hour-label">Heure</InputLabel>
                                    <Select
                                        labelId="hour-label"
                                        id="hour"
                                        name="startHour"
                                        value={state.startHour}
                                        onChange={handleChangeTime}
                                    >
                                        <MenuItem value="">
                                            <em>Sélectionner une heure</em>
                                        </MenuItem>
                                        <MenuItem value={'01'}>01</MenuItem>
                                        <MenuItem value={'02'}>02</MenuItem>
                                        <MenuItem value={'03'}>03</MenuItem>
                                        <MenuItem value={'04'}>04</MenuItem>
                                        <MenuItem value={'05'}>05</MenuItem>
                                        <MenuItem value={'06'}>06</MenuItem>
                                        <MenuItem value={'07'}>07</MenuItem>
                                        <MenuItem value={'08'}>08</MenuItem>
                                        <MenuItem value={'09'}>09</MenuItem>
                                        <MenuItem value={'10'}>10</MenuItem>
                                        <MenuItem value={'11'}>11</MenuItem>
                                        <MenuItem value={'12'}>12</MenuItem>
                                        <MenuItem value={'13'}>13</MenuItem>
                                        <MenuItem value={'14'}>14</MenuItem>
                                        <MenuItem value={'15'}>15</MenuItem>
                                        <MenuItem value={'16'}>16</MenuItem>
                                        <MenuItem value={'17'}>17</MenuItem>
                                        <MenuItem value={'18'}>18</MenuItem>
                                        <MenuItem value={'19'}>19</MenuItem>
                                        <MenuItem value={'20'}>20</MenuItem>
                                        <MenuItem value={'21'}>21</MenuItem>
                                        <MenuItem value={'22'}>22</MenuItem>
                                        <MenuItem value={'23'}>23</MenuItem>
                                        <MenuItem value={'24'}>24</MenuItem>
                                    </Select>
                                    <FormHelperText>{error.startTime}</FormHelperText>
                                </FormControl>
                                <FormControl color={darkState ? 'secondary' : 'primary'} error={!!error.startTime} sx={{width: 100}}>
                                    <InputLabel id="minute-label">minutes</InputLabel>
                                    <Select
                                        labelId="minute-label"
                                        id="minute"
                                        name="startMinute"
                                        value={state.startMinute}
                                        onChange={handleChangeTime}
                                    >
                                        <MenuItem value="">
                                            <em>Sélectionner les minutes</em>
                                        </MenuItem>
                                        <MenuItem value={'00'}>00</MenuItem>
                                        <MenuItem value={'30'}>30</MenuItem>
                                    </Select>
                                    <FormHelperText>{error.startTime}</FormHelperText>
                                </FormControl><br />
                            </div>
                            <div style={{display: 'flex', marginTop: 20}}>
                                <Typography style={{color: darkState ? '#BBBEC5' : '#757575', marginRight: 45, marginTop: 15}}>Heure de fin * : </Typography>
                                <FormControl color={darkState ? 'secondary' : 'primary'} error={!!error.endTime} sx={{width: 100, mr: 3}}>
                                    <InputLabel id="hour-label">Heure</InputLabel>
                                    <Select
                                        labelId="hour-label"
                                        name="endHour"
                                        id="hour"
                                        value={state.endHour}
                                        onChange={handleChangeTime}
                                    >
                                        <MenuItem value="">
                                            <em>Sélectionner une heure</em>
                                        </MenuItem>
                                        <MenuItem value={'01'}>01</MenuItem>
                                        <MenuItem value={'02'}>02</MenuItem>
                                        <MenuItem value={'03'}>03</MenuItem>
                                        <MenuItem value={'04'}>04</MenuItem>
                                        <MenuItem value={'05'}>05</MenuItem>
                                        <MenuItem value={'06'}>06</MenuItem>
                                        <MenuItem value={'07'}>07</MenuItem>
                                        <MenuItem value={'08'}>08</MenuItem>
                                        <MenuItem value={'09'}>09</MenuItem>
                                        <MenuItem value={'10'}>10</MenuItem>
                                        <MenuItem value={'11'}>11</MenuItem>
                                        <MenuItem value={'12'}>12</MenuItem>
                                        <MenuItem value={'13'}>13</MenuItem>
                                        <MenuItem value={'14'}>14</MenuItem>
                                        <MenuItem value={'15'}>15</MenuItem>
                                        <MenuItem value={'16'}>16</MenuItem>
                                        <MenuItem value={'17'}>17</MenuItem>
                                        <MenuItem value={'18'}>18</MenuItem>
                                        <MenuItem value={'19'}>19</MenuItem>
                                        <MenuItem value={'20'}>20</MenuItem>
                                        <MenuItem value={'21'}>21</MenuItem>
                                        <MenuItem value={'22'}>22</MenuItem>
                                        <MenuItem value={'23'}>23</MenuItem>
                                        <MenuItem value={'24'}>24</MenuItem>
                                    </Select>
                                    <FormHelperText>{error.endTime}</FormHelperText>
                                </FormControl>
                                <FormControl color={darkState ? 'secondary' : 'primary'} error={!!error.endTime} sx={{width: 100}}>
                                    <InputLabel id="minute-label">minutes</InputLabel>
                                    <Select
                                        labelId="minute-label"
                                        name="endMinute"
                                        id="minute"
                                        value={state.endMinute}
                                        onChange={handleChangeTime}
                                    >
                                        <MenuItem value="">
                                            <em>Sélectionner les minutes</em>
                                        </MenuItem>
                                        <MenuItem value={'00'}>00</MenuItem>
                                        <MenuItem value={'30'}>30</MenuItem>
                                    </Select>
                                    <FormHelperText>{error.endTime}</FormHelperText>
                                </FormControl>
                            </div>
                            <TextField
                                sx={{
                                    width: 400,
                                    mt: 2
                                }}
                                color={darkState ? 'secondary' : 'primary'}
                                type='number'
                                required
                                label='Rémunération'
                                id='remuneration'
                                name='remuneration'
                                error={!!error.remuneration}
                                helperText={error.remuneration}
                                value={remuneration}
                                onChange={(e)=> setRemuneration(e.target.value)}
                            />
                        </div>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAccept} disabled={loading} color={darkState ? 'secondary' : 'primary'}>
                    Accepter
                </Button>
                <Button onClick={handleClose} disabled={loading} color={darkState ? 'secondary' : 'primary'}>
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    );
}