import React, { useEffect, useState } from 'react'
import {
    Grid,
    Paper,
    Typography,
    TextField,
    Button,
    LinearProgress,
    ButtonGroup,
    Zoom,
    Divider,
    InputAdornment,
    IconButton,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from '@mui/material';
import { apiFetch } from '../../service/apiFetch';
import { useNavigate, useParams } from 'react-router-dom';
import { progressBarWidth, TransitionLeft } from '../../helpers/utils';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { handleFormError } from '../../service/security/formError';
import ProgressBar from '../../components/ProgressBar';
import UploadPdf from '../../components/UploadPdf';
import UploadImage from '../../components/UploadItem';

export default function EditAccount({ setText, setTransition, setSeverity, setOpenSnackbar, darkState, zoom }) {
    const { id } = useParams()
    const [admin, setAdmin] = useState(false)
    const [loading, setLoading] = useState(false)
    const [confirmPassError, setConfirmPassError] = useState(false)
    const [error, setError] = useState(false)
    const [showOldPassword, setShowOldPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [style, setStyle] = useState(false)
    const [photo, setPhoto] = useState(false);
    const [portrait, setPortrait] = useState(false);
    const [pcFunction, setPcFunction] = useState('')
    const [pcDivision, setPcDivision] = useState('')
    let navigate = useNavigate()

    useEffect(() => {
        (async () => {
            const userFetch = await apiFetch(`/users/me`)

            if (userFetch) {
                setPcDivision(userFetch.division)
                setPcFunction(userFetch.function)
                setAdmin(userFetch)
            } else {
                setText((userFetch && userFetch['hydra:description']) || 'Erreur lors de la création')
                setTransition(() => TransitionLeft)
                setSeverity('error')
                setOpenSnackbar(true)
            }
        })()
    }, [id, setOpenSnackbar, setTransition, setSeverity, setText])

    const handleChangePassword = async (e) => {
        e.preventDefault()
        setLoading(true)
        setConfirmPassError(false)
        setError(false)

        let data = new FormData(e.target)
        const oldPass = data.get('oldPassword')
        const newPass = data.get('newPassword')
        const confirmPass = data.get('confirmNewPassword')
        data = { oldPassword: oldPass, password: newPass }

        if (!newPass || !confirmPass || confirmPass !== newPass) {
            setLoading(false)
            return setConfirmPassError('La confirmation du mot de passe doit être identique au nouveau mot de passe')
        }

        const updatedAdmin = await apiFetch(`/users/${admin.uuid}/change-password`, {
            method: 'PUT',
            body: JSON.stringify(data)
        })

        if (updatedAdmin && updatedAdmin.uuid) {
            setText('Le mot de passe a été modifié')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setTimeout(() => {
                navigate('/dashboard')
            }, 3000)
        } else if (updatedAdmin.violations) {
            const errorField = handleFormError(updatedAdmin, ['password', 'oldPassword'])
            setError(errorField)
            setText('Erreur lors de la modification')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        } else {
            setText((updatedAdmin && updatedAdmin['hydra:description']) || 'Erreur lors de la modification')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setLoading(false)
    }

    const handleEdit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError(false)

        let createPhoto = null
        if (photo) {
            let dataPhoto = new FormData()
            dataPhoto.append('file', photo && photo[0])
            createPhoto = await apiFetch('/photo_objects', {
                method: 'POST',
                body: dataPhoto
            }, true)
        }
        if (createPhoto && createPhoto['hydra:description']) {
            setText(createPhoto['hydra:description'])
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
            setLoading(false)
            return null
        }

        let createPortrait = null
        if (portrait) {
            let dataPortrait = new FormData()
            dataPortrait.append('file', portrait && portrait[0])
            createPortrait = await apiFetch('/user_portrait_objects', {
                method: 'POST',
                body: dataPortrait
            }, true)
        }
        if (createPortrait && createPortrait['hydra:description']) {
            setText(createPortrait['hydra:description'])
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
            setLoading(false)
            return null
        }

        let data = {
            function: pcFunction,
            division: pcDivision,
        }

        if (photo) {
            data.photo = createPhoto['@id']
        }

        if (portrait) {
            data.portrait = createPortrait['@id']
        }

        const updatedUser = await apiFetch(`/users/${admin.uuid}`, {
            method: 'PUT',
            body: JSON.stringify(data)
        })

        if (updatedUser && updatedUser.uuid) {
            setText('L\'utilisateur a été modifié')
            setTransition(() => TransitionLeft)
            setSeverity('success')
            setOpenSnackbar(true)
            setTimeout(() => {
                navigate('/users')
            }, 1000)
        }  else if (updatedUser.violations) {
            const errorField = handleFormError(updatedUser,
                [
                    'division',
                    'function'
                ]
            )
            setError(errorField)
            setText('Erreur lors de la modification')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        } else {
            setText((updatedUser && updatedUser['hydra:description']) || 'Erreur lors de la modification')
            setTransition(() => TransitionLeft)
            setSeverity('error')
            setOpenSnackbar(true)
        }
        setLoading(false)
    }

    const handleBack = () => {
        navigate('/dashboard')
    }

    const handleClickOldPassword = () => {
        setShowOldPassword(!showOldPassword)
    }

    const handleClickNewPassword = () => {
        setShowNewPassword(!showNewPassword)
    }

    const handleClickConfirmNewPassword = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }

    const handleChangeProgressBar = (e) => {
        progressBarWidth(e, setStyle)
    }

    const handleChangeFunction = (e) => {
        setPcFunction(e.target.value)
    }

    const handleChangeDivision = (e) => {
        setPcDivision(e.target.value)
    }

    return (
        <Zoom in={zoom}>
            <div style={{overflowX: 'hidden'}}>
                <Paper>
                    <Typography
                        variant='h5'
                        color={darkState ? 'secondary' : "primary"}
                        sx={{
                            ml: 3,
                            pt: 3,
                        }}
                    >
                        Votre profil
                    </Typography>
                    <Divider style={{marginTop: 20}} />
                    <Grid container spacing={3} style={{margin: 10}}>
                        <Grid item xs={6}>
                            <Typography variant='body1'><span>Nom : </span>{admin.lastName}</Typography><br />
                            <Typography variant='body1'><span>Prénom : </span>{admin.firstName}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant='body1'><span>Email : </span>{admin.email}</Typography><br />
                            <Typography variant='body1'><span>Role : </span>{admin.roles && admin.roles[0].slice(5)}</Typography><br />
                        </Grid>
                    </Grid>
                    <form noValidate onSubmit={handleEdit} autoComplete="off">
                        <Grid container spacing={3} style={{margin: 10}}>
                            <Grid item xs={6}>
                                <FormControl color={darkState ? 'secondary' : "primary"} error={!!error.function}>
                                    <InputLabel htmlFor="division-simple">Pôle</InputLabel>
                                    <Select
                                        value={pcDivision}
                                        onChange={handleChangeDivision}
                                        inputProps={{
                                            name: 'division',
                                            id: 'division-simple',
                                        }}
                                        sx={{
                                            width: 300,
                                            mb: 3,
                                        }}
                                    >
                                        <MenuItem aria-label="None" value="" ><em>Sélectionner un pôle</em></MenuItem>
                                        <MenuItem value={'Direction'}>Direction</MenuItem>
                                        <MenuItem value={'Exploitation'}>Exploitation</MenuItem>
                                        <MenuItem value={'Interventions / Audits'}>Interventions / Audits</MenuItem>
                                        <MenuItem value={'Formation'}>Formation</MenuItem>
                                        <MenuItem value={'Commercial'}>Commercial</MenuItem>
                                        <MenuItem value={'Ligne d\'écoute'}>Ligne d'écoute</MenuItem>
                                        <MenuItem value={'Communication'}>Communication</MenuItem>
                                        <MenuItem value={'Réseau psy / recrutement'}>Réseau psy / recrutement</MenuItem>
                                        <MenuItem value={'Projets'}>Projets</MenuItem>
                                        <MenuItem value={'Informatique'}>Informatique</MenuItem>
                                        <MenuItem value={'Appels d\'offre'}>Appels d'offre</MenuItem>
                                        <MenuItem value={'Comptabilité'}>Comptabilité</MenuItem>
                                        <MenuItem value={'Wellmonday'}>Wellmonday</MenuItem>
                                    </Select>
                                </FormControl>
                                <div style={{marginTop: 30}}>
                                    <UploadImage setPhoto={setPhoto} title={'Ajouter une photo'} />
                                </div>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl color={darkState ? 'secondary' : "primary"} error={!!error.function}>
                                    <InputLabel htmlFor="function-simple">Fonction</InputLabel>
                                    <Select
                                        value={pcFunction}
                                        onChange={handleChangeFunction}
                                        inputProps={{
                                            name: 'function',
                                            id: 'function-simple',
                                        }}
                                        sx={{
                                            width: 300,
                                            mb: 3,
                                        }}
                                    >
                                        <MenuItem aria-label="None" value="" ><em>Sélectionner une fonction</em></MenuItem>
                                        <MenuItem value={'Directrice'}>Directrice</MenuItem>
                                        <MenuItem value={'Directrice Administrative'}>Directrice Administrative</MenuItem>
                                        <MenuItem value={'Responsable Communication et Cheffe de projets'}>
                                            Responsable Communication et Cheffe de projets
                                        </MenuItem>
                                        <MenuItem value={'Responsable Developpement Informatique'}>
                                            Responsable Developpement Informatique
                                        </MenuItem>
                                        <MenuItem value={'Responsable Grands Comptes publics'}>
                                            Responsable Grands Comptes publics
                                        </MenuItem>
                                        <MenuItem value={'Responsable Transition Numérique'}>
                                            Responsable Transition Numérique
                                        </MenuItem>
                                        <MenuItem value={'Responsable Pédagogique'}>Responsable Pédagogique</MenuItem>
                                        <MenuItem value={'Responsable Exploitation'}>Responsable Exploitation</MenuItem>
                                        <MenuItem value={'Développeur Informatique'}>Développeur Informatique</MenuItem>
                                        <MenuItem value={'Développeur Mobile'}>Développeur Mobile</MenuItem>
                                        <MenuItem value={'Chargé(e) de Projets Intervention'}>Chargé(e) de Projets Intervention</MenuItem>
                                        <MenuItem value={'Chargé(e) d\'Affaires'}>Chargé(e) d'Affaires</MenuItem>
                                        <MenuItem value={'Chargé(e) de Projets Ligne d\'écoute'}>
                                            Chargé(e) de Projets Ligne d'écoute
                                        </MenuItem>
                                        <MenuItem value={'Assistante Pédagogique'}>Assistante Pédagogique</MenuItem>
                                        <MenuItem value={'Assistante de Communication'}>Assistante de Communication</MenuItem>
                                        <MenuItem value={'Assistante Projets'}>Assistante Projets</MenuItem>
                                        <MenuItem value={'Assistante administrative MP/AO'}>Assistante administrative MP/AO</MenuItem>
                                        <MenuItem value={'Assistante de Gestion facturation'}>Assistante de Gestion facturation</MenuItem>
                                        <MenuItem value={'Coordinateur Intervention'}>Coordinateur Intervention</MenuItem>
                                        <MenuItem value={'Graphiste'}>Graphiste</MenuItem>
                                        <MenuItem value={'Comptable'}>Comptable</MenuItem>
                                        <MenuItem value={'Chargée du Développement et de l\'Animation du réseau des psychologues'}>
                                            Chargée du Développement et de l'Animation du réseau des psychologues
                                        </MenuItem>
                                        <MenuItem value={'Gestionnaire Coaching Digital'}>Gestionnaire Coaching Digital</MenuItem>
                                    </Select>
                                </FormControl><br />
                                <div style={{marginTop: 30}}>
                                    <UploadPdf
                                        setPdf={setPortrait}
                                        title={'Ajouter un portrait'}
                                    />
                                </div>
                            </Grid>
                            <ButtonGroup
                                color={darkState ? 'secondary' : "primary"}
                                variant="contained"
                                aria-label="profil button group"
                                sx={{
                                    ml: 3,
                                    mt: 3,
                                    mb: 3,
                                }}
                            >
                                <Button
                                    type='submit'
                                    disabled={loading}
                                >
                                    Enregistrer
                                </Button>
                                <Button onClick={handleBack}>
                                    Retour
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </form>
                </Paper>
                <Paper>
                    <Typography
                        variant='h5'
                        color={darkState ? 'secondary' : "primary"}
                        sx={{
                            ml: 3,
                            pt: 3,
                        }}
                    >
                        Modification du mot de passe
                    </Typography>
                    <Divider style={{marginTop: 20}} />
                    {admin && <form noValidate onSubmit={handleChangePassword} autoComplete="off">
                    <TextField 
                        id="oldPassword" 
                        name="oldPassword" 
                        label="Mot de passe actuel"
                        type={showOldPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        color='inherit'
                                        aria-label="toggle password visibility"
                                        onClick={handleClickOldPassword}
                                    >
                                        {showOldPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    
                        required
                        error={!!error.oldPassword} helperText={error.oldPassword}
                        sx={{
                            ml: 3,
                            mt: 3,
                            width: 400
                        }}
                    /><br />
                    <TextField 
                        id="newPassword" 
                        name="newPassword" 
                        label="Nouveau mot de passe"
                        type={showNewPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        color='inherit'
                                        aria-label="toggle password visibility"
                                        onClick={handleClickNewPassword}
                                    >
                                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    
                        required
                        onChange={handleChangeProgressBar}
                        error={!!error.password} helperText={error.password}
                        sx={{
                            ml: 3,
                            mt: 3,
                            width: 400
                        }}
                    />
                    <ProgressBar style={style} />
                    <TextField 
                        id="confirmNewPassword" 
                        name="confirmNewPassword" 
                        label="Confirmer le nouveau mot de passe"
                        type={showConfirmPassword ? "text" : "password"}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        color='inherit'
                                        onClick={handleClickConfirmNewPassword}
                                    >
                                        {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                    
                        required
                        error={!!confirmPassError}
                        helperText={confirmPassError}
                        sx={{
                            ml: 3,
                            mt: 3,
                            width: 400
                        }}
                    /><br />
                    <ButtonGroup
                        color={darkState ? 'secondary' : "primary"}
                        variant="contained"
                        aria-label="contained primary button group"
                        sx={{
                            ml: 3,
                            mt: 3,
                            mb: 3,
                        }}
                    >
                        <Button
                            type='submit'
                            disabled={loading}
                        >
                            Enregistrer
                        </Button>
                        <Button onClick={handleBack}>
                            Retour
                        </Button>
                    </ButtonGroup>
                    </form>}
                </Paper>
                {(loading || !admin) && <LinearProgress color={darkState ? 'secondary' : 'primary'} />}
            </div>
        </Zoom>
    )
}